import { sendRequest, ValueType } from '@tasper/configurator-core';
import { Constant } from '@tasper/configurator-customer-data';
import { CalculatedValue } from './models/CalculatedValue';

const BASE_URL = 'api/calculatedvalue';

export const getCalculatedValuesByClientId = (clientId: number): Promise<CalculatedValue[]> => sendRequest<CalculatedValue[]>({
    method: 'GET',
    url: `${BASE_URL}/list/${clientId}`,
});

export const getAllCalculatedValuesByType = (clientId: number, type: ValueType): Promise<Constant[]> => sendRequest<Constant[]>({
    method: 'GET',
    url: `${BASE_URL}/GetByValueType/${clientId}/${type}`,
});

export const createCalculatedValue = (calculatedValue: CalculatedValue): Promise<CalculatedValue> => sendRequest<CalculatedValue>({
    method: 'POST',
    url: BASE_URL,
    content: JSON.stringify(calculatedValue),
});

export const deleteCalculatedValue = (calculatedValueId: number): Promise<void> => sendRequest<void>({
    method: 'DELETE',
    url: `${BASE_URL}/${calculatedValueId}`,
});
export const changeCalculatedValue = (calculatedValue: Partial<CalculatedValue>): Promise<CalculatedValue> => sendRequest<CalculatedValue>({
    method: 'PUT',
    url: `${BASE_URL}/${calculatedValue.id}`,
    content: JSON.stringify(calculatedValue),
});
