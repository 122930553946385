import React, { FC, Suspense } from 'react';
import { NavLink, To } from 'react-router-dom';
// import { ReactComponent as MadlHatterByTenniel } from "./MadlHatterByTenniel.svg";

import style from './style.module.scss';

const MadlHatterByTenniel = React.lazy(() => import("./MadlHatterByTenniel.svg").then((data) => ({ default: data.ReactComponent })));

const NotFound: FC<{ to?: To, title?: string }> = ({ to = "/", title = 'Back to Home' }) => {
    return (
        <main className={style.main}>
            <div className={style.notfound}>
                <Suspense fallback={'...'}>
                    <MadlHatterByTenniel />
                </Suspense>
                <caption className={style.title}>This request results in a 4😅4</caption>
                <p>
                    The link you followed may have <span className={style.ntext}>expired</span>, or the page may <span className={style.ntext}>only be visible to an audience you're not in..</span><br />
                    When the page <span className={style.ntext}>doesn&apos;t exist</span> or is <span className={style.ntext}>unavailable</span>, you can try again later or <span className={style.ntext}>use the link below to find your way back home.</span><br />
                </p>
                <NavLink to={to}>
                    <span className={style.links}>{title}</span>
                </NavLink>
            </div>
        </main>
    );
};

export default NotFound;
