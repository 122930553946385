import React, { useEffect, useState } from 'react';
import { ProductDefinitionModel } from '../../../api/models/ProductDefinitionModel';
import { getProductDefinitionModelsByProductDefinitionId } from '../../../api/ProductDefinitionModelService';
import RenderCheckBox from './RenderCheckBox';

interface ProductConfigMethodsFormProps {
  productDefintionId: number | undefined;
  productId: number;
}

export default function ProductDefinitionModelForm({ productId, productDefintionId }: ProductConfigMethodsFormProps) {
  const [productDefinitionModels, setProductDefinitionModels] = useState<ProductDefinitionModel[]>([]);

  useEffect(() => {
    if (productDefintionId) {
      getProductDefinitionModelsByProductDefinitionId(productDefintionId).then(data => {
        setProductDefinitionModels(data);
      });
    }
  }, [productId, productDefintionId]);

  return (
    <div className="row">

      {productDefinitionModels.map(m => {
        return (
          <RenderCheckBox key={m.id} productId={productId} propertyType={m.propertyType} mandatory={m.mandatory} code={m.code} entityId={m.id} />
        );
      })}
    </div>
  );
}
