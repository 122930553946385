import { LanguageConfiguration, Monaco } from './types';

export default function configureLanuage(m: Monaco, config: LanguageConfiguration, callbackLoader?: (lanuageId: string, module: any) => void) {
  m.languages.register({
    id: config.id,
    extensions: config.extensions,
  });

  m.languages.onLanguage(config.id, () => {
    config.loader().then(module => {
      m.languages.setMonarchTokensProvider(config.id, module.language);
      m.languages.setLanguageConfiguration(config.id, module.languageConfiguration);

      if (module.createSnippetProvider) m.languages.registerCompletionItemProvider(config.id, module.createSnippetProvider());
      else console.warn(`Language ${config.id} no snippets.`);

      if (callbackLoader) {
        try {
          callbackLoader(config.id, module);
        } catch (e) {
          console.warn(`captured an error while adding laguage ${config.id}`, e);
        }
      }
    });
  });
}
