import React, { useEffect, useState } from 'react';
import { getAllConstants } from '@tasper/configurator-customer-data';
import { Loader } from '@tasper/configurator-core';
import { ProductModelValue } from '../../../../api/models/ProductModelValue';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productModelValue: ProductModelValue;
  clientId: string;
  value?: string;
}

const RenderConstantInput = ({ clientId, value, productModelValue }: RenderProps) => {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllConstants(parseInt(clientId, 10)).then(result => {
      setOptions(result.map(constant => ({
        label: constant.code,
        value: constant.code,
      })));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productModelValue={productModelValue} options={options} />
  );
};

export default RenderConstantInput;
