import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import {
  FormSelectBoxItem, ValueType, Loader, toastOptions,
} from '@tasper/configurator-core';
import { getAllConstantsByType } from '@tasper/configurator-customer-data';
import { createNewAfasConnectionSetting, getAfasConnectionSetting, updateAfasConnectionSetting } from '../../api/AfasConnectionSettingService';
import { AfasConnectionSetting } from '../../api/models/AfasConnectionSetting';
import { IAfasImportPageComponent } from '../types';
import { AfasImport } from '../../api/models/AfasImport';

interface AfasConnectionSettingsProps extends IAfasImportPageComponent {
  afasImport: AfasImport;
  clientId: number;
}

export interface AfasConnectionSettingsFormModel {
  urlConstantId?: FormSelectBoxItem<number>;
  tokenConstantId?: FormSelectBoxItem<number>;
  connectorNameConstantId?: FormSelectBoxItem<number>;
}

export default function AfasConnectionSettingsForm(props: AfasConnectionSettingsProps) {
  const [afasCSId, setAfasCsId] = useState<number | undefined>();
  const [stringConstantsOptions, setStringConstantsOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    control, handleSubmit, reset, formState: { errors },
  } = useForm<AfasConnectionSettingsFormModel>();

  useEffect(() => {
    function updateForm(afasConnectionSettings: AfasConnectionSetting, stringConstantsOptions: FormSelectBoxItem<number>[]) {
      reset({
        urlConstantId: stringConstantsOptions.find(constant => constant.value === afasConnectionSettings.urlConstantId),
        tokenConstantId: stringConstantsOptions.find(constant => constant.value === afasConnectionSettings.tokenConstantId),
        connectorNameConstantId: stringConstantsOptions.find(constant => constant.value === afasConnectionSettings.connectorNameConstantId),
      });
    }

    setIsLoading(true);

    getAllConstantsByType(props.clientId, ValueType.String)
      .then(data => {
        const constantOptions = data.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));

        setStringConstantsOptions(constantOptions);

        if (props.afasImport.afasConnectionSettingId) {
          getAfasConnectionSetting(props.afasImport.afasConnectionSettingId as number)
            .then(data => {
              updateForm(data, constantOptions as FormSelectBoxItem<number>[]);
              setAfasCsId(data.id);
              setIsLoading(false);
            })
            .catch(() => {
              toast.error('An error occured');
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error('An error occured');
      });
  }, [props.afasImport, props.clientId, reset]);

  function convertFormToModel(afasForm: AfasConnectionSettingsFormModel): AfasConnectionSetting {
    const obj: AfasConnectionSetting = {
      afasImportId: props.afasImport.id as number,
      urlConstantId: afasForm.urlConstantId!.value,
      tokenConstantId: afasForm.tokenConstantId!.value,
      connectorNameConstantId: afasForm.connectorNameConstantId!.value,
    };

    if (afasCSId !== undefined) { obj.id = afasCSId; }

    return obj;
  }

  const onSubmit: SubmitHandler<AfasConnectionSettingsFormModel> = function submitForm(afasConnectionSettingsFormModel: AfasConnectionSettingsFormModel) {
    const toastId = toast.loading('Saving...');

    const model = convertFormToModel(afasConnectionSettingsFormModel);
    if (!model.id) {
      // Create
      model.afasImportId = props.afasImport.id as number; // TODO dit kan weg

      createNewAfasConnectionSetting(model)
        .then(data => {
          setAfasCsId(data.id);

          // Send new id to parent
          const updatedAfasImport = props.afasImport;
          updatedAfasImport.afasConnectionSettingId = data.id;
          props.updateAfasImport(updatedAfasImport);

          toast.update(toastId, {
            ...toastOptions, render: 'Saved', type: toast.TYPE.SUCCESS, isLoading: false,
          });
        })
        .catch(() => toast.update(toastId, {
          ...toastOptions, render: 'An error occured while saving.', type: toast.TYPE.ERROR, isLoading: false,
        }));
    } else {
      // Update
      updateAfasConnectionSetting(model)
        .then(() => toast.update(toastId, {
          ...toastOptions, render: 'Saved changes.', type: toast.TYPE.SUCCESS, isLoading: false,
        }))
        .catch(() => toast.update(toastId, {
          ...toastOptions, render: 'An error occured while updating.', type: toast.TYPE.ERROR, isLoading: false,
        }));
    }
  };

  function selectRender(name: any, label: string) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={
            ({ field }) => (
              <Select
                {...field}
                options={stringConstantsOptions}
                placeholder="Choose a constant"
              />
            )
          }
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
        <br />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectRender('urlConstantId', 'Url')}
        {selectRender('tokenConstantId', 'Token')}
        {selectRender('connectorNameConstantId', 'Connector name')}
        <br />
        <button className="btn btn-success">Save</button>
      </form>
    );
}
