import React, { PropsWithChildren, ReactChildren, useState } from 'react'
import styles from './Tree.module.scss';

const withChildren = (Component: string, className: string) => ({ children, ...props }: PropsWithChildren<unknown>) => (
    React.createElement(Component, { className, ...props }, children)
);

const StyledTree = withChildren('div', styles.tree);
const StyledFile = withChildren('div', styles.file);
const StyledFolder = withChildren('div', styles.folder);

const Collapsible: React.FC<PropsWithChildren<{ isOpen: boolean }>> = ({ children, isOpen }) => (
    <div className={[styles.collapsible, isOpen && 'open'].filter(Boolean).join(' ')}>{children}</div>
);

interface FileProps {
    name: string
    icon?: React.ComponentType<{ type: string }>
}
export const File = ({ name, icon: Icon }: FileProps) => {
    let ext = name?.split('.')[1];
    return (
        <StyledFile>
            {Icon && <Icon type={ext} />}
            <span>{name}</span>
        </StyledFile>
    )
}

interface FolderProps {
    name: string
    folderIcon?: React.FunctionComponent<unknown> | React.ComponentClass<unknown>
}
export const Folder: React.FC<React.PropsWithChildren<FolderProps>> = ({ name, children, folderIcon: FolderIcon }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <StyledFolder>
            <div className={styles['folder--label']} onClick={handleToggle}>
                {FolderIcon && <FolderIcon />}
                <span>{name}</span>
            </div>
            <Collapsible isOpen={isOpen}>{children}</Collapsible>
        </StyledFolder>
    );
};

export const Tree: React.FC<PropsWithChildren<unknown>> & { File: typeof File; Folder: typeof Folder } = ({ children }) => (
    <StyledTree>{children}</StyledTree>
);

Tree.File = File;
Tree.Folder = Folder;

export default Tree;