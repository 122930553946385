export enum PropertyType {
  list = 'List',
  basItem = 'BasItem',
  constant = 'Constant',
  calculatedValue = 'CalculatedValue',
  budget = 'Budget',
  value = 'Value',
  productCode = 'ProductCode',
  childProduct = 'ChildProduct',
}

export default PropertyType;
