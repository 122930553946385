import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { Product } from '../api/models/Product';
import { ProductConfigurationDefinition } from '../api/models/ProductConfigurationDefinition';
import { createProduct, getProducts } from '../api/ProductService';
import { getAllProductConfigurationDefinitions } from '../api/ProductConfigurationDefinitionService';
import styles from './index.module.scss';

export default function CreateProductPage() {
  const { clientId } = useParams();
  const [productConfigurationDefinitions, setProductConfigurationDefinitions] = useState<ProductConfigurationDefinition[]>([]);
  const [productCode, setProductCode] = useState('');
  const [parentProducts, setParentProducts] = useState<Product[]>([]);
  const [isParentProduct, setIsParentProduct] = useState(false);
  const [productConfiguration, setProductConfiguration] = useState<ProductConfigurationDefinition | undefined>();
  const [parentId, setParentId] = useState<number | undefined>();
  const [createdId, setCreatedId] = useState<number | undefined>(); // When not undefined, redirect to id.

  const getClientId = () => parseInt(clientId || '', 10);
  useEffect(() => {
    getAllProductConfigurationDefinitions().then(data => {
      setProductConfigurationDefinitions(data);
    });

    getProducts(getClientId()).then(data => {
      setParentProducts(
        data.filter(product => (product.parentId === undefined || product.parentId === null)
          && (product.productConfigDefinitionId === undefined || product.productConfigDefinitionId === null)),
      );
    });
  }, [clientId]);

  function handleConfigurationDefinitionChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value) {
      setProductConfiguration(productConfigurationDefinitions.find(configuration => configuration.id === parseInt(event.target.value, 10)));
    }
  }

  function handleParentProductOnChangeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value) {
      setParentId(parseInt(event.target.value, 10));
    }
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (productCode) {
      createProduct({
        code: productCode, clientId: getClientId(), productConfigDefinitionId: productConfiguration?.id, parentId,
      }).then(data => {
        setCreatedId(data.id);
      });
    }
  }

  return createdId !== undefined
    ? <Navigate to={`${createdId}/config/`} />
    : (
      <div>
        <h1>Nieuw product</h1>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Type configuratie</label>
            <div className="form-group">
              <label htmlFor="product-code-label">Code</label>
              <input id="product-code-label" type="text" className="form-control" onChange={e => setProductCode(e.target.value)} required />
            </div>

            <div className="form-group">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={isParentProduct} onChange={() => setIsParentProduct(!isParentProduct)} />
                <label className="form-check-label">is een gecombineerd product</label>
              </div>

            </div>
            {!isParentProduct && (
              <div>
                <div className="form-group">
                  <label>Configuratie</label>
                  <select aria-labelledby="translation-label" className={`${styles.margin_bottom} form-control`} onChange={e => handleConfigurationDefinitionChange(e)}>
                    <option disabled selected>Kies een optie</option>
                    {
                      productConfigurationDefinitions.map((productConfigurationDefinition, index) => {
                        return (
                          <option value={productConfigurationDefinition.id}>{productConfigurationDefinition.code}</option>
                        );
                      })
                    }
                  </select>
                </div>
                <div className="form-group">
                  <label>Selecteer een product als je hem aan een multi product wil toevoegen</label>
                  <select aria-labelledby="translation-label" className={`${styles.margin_bottom} form-control`} onChange={e => handleParentProductOnChangeChange(e)}>
                    <option disabled selected>Kies een optie als je dit product als sub product wil toevoegen</option>
                    {
                      parentProducts.map(product => {
                        return (
                          <option value={product.id}>{product.code}</option>
                        );
                      })
                    }
                  </select>
                </div>

              </div>
            )}

          </div>

          <div>
            <input className="btn btn-primary mb-2" type="submit" value="Opslaan" />
          </div>
        </form>
      </div>
    );
}
