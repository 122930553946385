import { MonacoEditor } from '@tasper/code-editor';
import React, { useEffect, useState } from 'react';
import { getProductMethodsByProductId, updateProductMethod } from '../../../api/ProductMethodService';
import CheckBox from './parts/CheckBox';
import ItemTabs, { EditingItem } from './parts/ItemTabs';

interface ProductPropertiesMappingFormProps {
  productId: number;
}

export default function ProductMethodsMappingForm({ productId }: ProductPropertiesMappingFormProps) {
  const [codeItems, setCodeItems] = useState<EditingItem[]>([]);
  const [currentItem, setCurrentItem] = useState<EditingItem>({} as EditingItem);

  useEffect(() => {
    let isCanceled = false;
    getProductMethodsByProductId(productId)
      .then(data => {
        if (!isCanceled && data !== undefined) {
          const items = data.map(
            method => ({
              label: method.productConfigDefinitionMethod?.methodName,
              data: method,
              dirty: false,
            } as EditingItem),
          );

          setCodeItems(items);
          setCurrentItem(items[0]);
        }
      });

    return () => {
      isCanceled = true;
    };
  }, [productId]);

  const handleOnBlur = async () => {
    if (!(currentItem?.data) && currentItem.dirty) {
      try {
        await updateProductMethod(currentItem!.data);
        currentItem.dirty = false;
      } catch (error) {
        console.warn(error);
      }
    }
  };

  useEffect(() => {
    if (currentItem?.dirty) {
      updateProductMethod(currentItem!.data);
      currentItem.dirty = false;
    }
  }, [currentItem]);

  function updateValue(text: string | undefined) {
    if (checked) {
      if (currentItem.data?.rawCode !== text) {
        currentItem.data.rawCode = text;
        currentItem.dirty = true;
      }
    } else if (currentItem.data?.rawWorkflowData !== text) {
      currentItem.data.rawWorkflowData = text;
      currentItem.dirty = true;
    }
  }

  const [checked, setChecked] = React.useState(false);
  return (
    <ItemTabs items={codeItems} onItemSelected={item => setCurrentItem(item)}>
      <CheckBox label={checked ? 'Code template' : 'Workflow'} value={false} onChange={setChecked} />
      <div style={{ height: '25rem' }}>
        <MonacoEditor
          value={checked ? currentItem?.data?.rawCode : currentItem?.data?.rawWorkflowData ?? ''}
          onBlur={handleOnBlur}
          language={checked ? 'csharp' : 'calc'}
          onValueChanged={text => updateValue(text)}
        />
      </div>
    </ItemTabs>
  );
}
