import React, { useEffect, useState } from 'react';
import { getAllItems } from '@tasper/configurator-customer-data';
import { Loader } from '@tasper/configurator-core';
import { ProductModelValue } from '../../../../api/models/ProductModelValue';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productModelValue: ProductModelValue;
  clientId: string;
  value?: string;
}

export default function RenderBasItemInput({ productModelValue, clientId, value = '' }: RenderProps) {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllItems(parseInt(clientId, 10)).then(result => {
      setOptions(result.map(item => ({
        label: item.code,
        value: item.code,
      })));
    })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productModelValue={productModelValue} options={options} />
  );
}
