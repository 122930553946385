import React, { useEffect, useState } from 'react';
import { getAllItems, Item } from '@tasper/configurator-customer-data';
import { Loader, TabTableWithNewRow } from '@tasper/configurator-core';
import { getAllAfasMappingByImportId } from '../../api/AfasMappingService';
import { AfasImport } from '../../api/models/AfasImport';
import { AfasImportType } from '../../api/models/AfasImportType';
import AfasImportRow from './parts/AfasImportRow';
import { AfasMapping } from './types';

interface AfasMappingTableProps {
  clientId: number;
  afasImport: AfasImport;
  defaultDateFormat?: string;
  defaultBooleanFormat?: string;
}

function renderColumnName({ afasImportType }: AfasImport) {
  switch (afasImportType) {
    case AfasImportType.MedewerkerGegevens:
      return 'Afas element name';

    case AfasImportType.LoonComponenten:
      return 'Wage code';

    default:
      return afasImportType;
  }
}

export default function AfasMappingTable(props: AfasMappingTableProps) {
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllItems(props.clientId)
      .then(data => {
        const options = data.map(item => ({
          label: item.code,
          value: item.id,
        }));

        setItems(data);
        setItemOptions(options);
        setIsLoading(false);
      });
  }, [props.clientId]);

  const renderRow = (rowModel: AfasMapping, addNewRowEvent: () => void) => {
    return (
      <AfasImportRow
        handleSave={addNewRowEvent}
        mapping={rowModel}
        // clientId={props.clientId}
        afasImportId={props.afasImport.id as number}
        afasImportType={props.afasImport.afasImportType}
        itemsOptions={itemOptions}
        items={items}
        defaultDateFormat={props.defaultDateFormat}
        defaultBooleanFormat={props.defaultBooleanFormat}
      />
    );
  };

  const getData = (): Promise<AfasMapping[]> => {
    const afasImportId: number = props.afasImport.id as number;

    return new Promise((resolve, reject) => {
      getAllAfasMappingByImportId(afasImportId)
        .then(data => {
          resolve(
            data.map(element => {
              const mappingRow: AfasMapping = {
                id: element.id,
                itemId: element.itemId,
                optional: element.optional,
                defaultValue: element.defaultValue,
                valueFormat: element.valueFormat,
              };

              switch (props.afasImport.afasImportType) {
                case AfasImportType.LoonComponenten:
                  mappingRow.code = element.loonCode;
                  break;

                case AfasImportType.MedewerkerGegevens:
                  mappingRow.code = element.afasElementName;
                  break;

                default:
                  break;
              }

              return mappingRow;
            }),
          );
        })
        .catch(e => reject(e));
    });
  };

  return isLoading
    ? <Loader />
    : (
      <>
        <div className="row">
          <div className="col-2">{renderColumnName(props.afasImport)}</div>
          <div className="col-2">Itemcode</div>
          <div className="col-3">Type</div>
          <div className="col-2">Default value</div>
          <div className="col-1" />
          <div className="col-1" />
        </div>
        <TabTableWithNewRow<AfasMapping>
          emptyRow={{ isNew: true }}
          renderRow={renderRow}
          getData={getData}
        />
      </>
    );
}
