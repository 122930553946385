import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
// import { CodeTemplates } from '@tasper/code-templates';
import { CustomerOverview, CreateAndEditCustomer } from '@tasper/configurator-customers';
import { Layout } from './Components/Layout';
import { Dashboard, Configuration } from './Pages';
import { Loader, NotFound } from '@tasper/configurator-core';

const CodeTemplates = React.lazy(() => import('@tasper/code-templates'));

function pageContentElement(children: React.ReactNode) {
    return <div className="content">{children}</div>;
}
function containElement(children: React.ReactNode) {
    return pageContentElement(<div className="container content">{children}</div>);
}

const routes = [
    { path: '/', element: pageContentElement(<Dashboard />) },
    { path: '/clients', element: containElement(<CustomerOverview />) },
    { path: '/clients/create', element: containElement(<CreateAndEditCustomer />) },
    { path: '/clients/update/:id', element: containElement(<CreateAndEditCustomer />) },
    { path: '/configuratie/:clientId/*', element: <Configuration /> },
    { path: '/templates/*', element: <CodeTemplates /> },
];

function App() {
    /*
    const [style, setStyle] = useState('');
    useEffect(() => {
        switch (style) {
            case 'dark':
            case 'default':
                document.body.className = style;
                break;

            default:
                document.body.className = 'default';
                break;
        }
    }, [style]);
    */
    return (
        <Layout>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {routes.map(r => <Route key={r.path} {...r} />)}
                    <Route
                        path="*"
                        element={<NotFound />}
                    />
                </Routes>
            </Suspense>
        </Layout>
    );
}

export default App;
