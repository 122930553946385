import React, { useEffect, useState } from 'react';
import { getBudgetsByClientId } from '@tasper/configurator-budgets';
import { Loader } from '@tasper/configurator-core';
import { ProductProperty } from '../../../../api/models/ProductProperty';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productProperty: ProductProperty;
  clientId: string;
  value?: string;
}

const RenderBudgetInput = ({ productProperty, clientId, value }: RenderProps) => {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getBudgetsByClientId(clientId).then(result => {
      setOptions(result.map(budget => ({
        label: budget.code,
        value: budget.code,
      })));
    })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productProperty={productProperty} options={options} />
  );
};

export default RenderBudgetInput;
