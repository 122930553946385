import { sendRequest } from '@tasper/configurator-core';
import { ProductProperty } from './models/ProductProperty';

const BASE_URL = 'api/ProductProperty';

export const createProductProperties = (productConfigurationDefinitionId: number, productProperties: ProductProperty[]): Promise<ProductProperty> => {
  return sendRequest<ProductProperty>({
    method: 'POST',
    url: `${BASE_URL}/savebulk/${productConfigurationDefinitionId}`,
    content: JSON.stringify(productProperties),
  });
};

export const getProductPropertiesByProductId = (productId: number): Promise<ProductProperty[]> => {
  return sendRequest<ProductProperty[]>({
    method: 'GET',
    url: `${BASE_URL}/GetProductPropertiesByProductIdWithProductConfigDefinitonProperties/${productId}`,
  });
};
export const updateProductProperty = (productProperty: ProductProperty) => sendRequest({
  method: 'PUT',
  url: `${BASE_URL}/${productProperty.id}`,
  content: JSON.stringify(productProperty),
});

export const createProductProperty = (productProperty: ProductProperty): Promise<ProductProperty> => sendRequest<ProductProperty>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(productProperty),
});

export const deleteProductProperty = (productPropertyId: number): Promise<ProductProperty> => sendRequest<ProductProperty>({
  method: 'DELETE',
  url: `${BASE_URL}/${productPropertyId}`,
});

export const getProductPropertyByProductIdAndProductConfigDefinitionId = (
  productId: number,
  productConfigDefinitionPropertyId: number,
): Promise<ProductProperty> => sendRequest<ProductProperty>({
  method: 'GET',
  url: `${BASE_URL}/GetByProductIdAndProductConfigurationDefinitionPropertyId/${productId}/${productConfigDefinitionPropertyId}`,
});
