import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  ValueType, EditStatus, EditStatusIcon, ValueTypeDropdown,
} from '@tasper/configurator-core';

import { createNewItem, changeItem, deleteItem } from '../../../../api/ItemService';
import { Item } from '../types';

interface ItemRowProps {
  item: Item;
  onSave: () => void;
  clientId: number;
}

export default function ItemRow({ item, clientId, onSave }: ItemRowProps) {
  const [id, setId] = useState(item.id);
  const [itemName, setItemName] = useState(item.name || '');
  const [type, setType] = useState<ValueType | undefined>(item.type || undefined);
  const [isNew, setIsNew] = useState(item.isNew);
  const [editStatus, setEditStatus] = useState(EditStatus.none);
  const [deleted, setDeleted] = useState(false); // Hide component when user deleted

  function handleChangeItemName(e: React.ChangeEvent<HTMLInputElement>) {
    setEditStatus(EditStatus.pending);
    setItemName(e.currentTarget.value);
  }

  const handleChangeType = (value: ValueType | undefined) => {
    setEditStatus(EditStatus.pending);
    setType(value);
  };

  const handleSave = () => {
    if (itemName !== '' && type !== undefined) {
      if (isNew) {
        onSave();

        createNewItem({ clientId, code: itemName, valueType: type })
          .then(data => {
            setIsNew(false);
            setId(data.id);
            setIsNew(false);
            setEditStatus(EditStatus.success);
          })
          .catch(() => {
            toast.error(`An error occured while creating item ${itemName}`);
            setEditStatus(EditStatus.error);
          });
      } else {
        changeItem({
          id, code: itemName, valueType: type, clientId,
        })
          .then(() => {
            setEditStatus(EditStatus.success);
          })
          .catch(() => {
            toast.error(`An error occured while updting item ${itemName}`);
            setEditStatus(EditStatus.error);
          });
      }
    }
  };

  const handleDelete = () => {
    if (id !== undefined) {
      if (window.confirm('Are you sure you want to delete this BAS item?')) {
        deleteItem(id)
          .then(() => {
            toast.success(`Item ${itemName} is successfully deleted.`);
            setDeleted(true); // Hide component
          })
          .catch(() => {
            toast.error(`An error occured while deleting ${itemName}`);
          });
      }
    }
  };

  return !deleted
    ? (
      <form>
        <div className="row">
          <div className="col-5">
            <input
              placeholder="Name"
              value={itemName}
              onChange={handleChangeItemName}
              onBlur={handleSave}
              required
              className="form-control"
            />
          </div>
          <div className="col-5">
            <ValueTypeDropdown
              initialValue={type?.toString()}
              onChange={handleChangeType}
              onBlur={handleSave}
            />
          </div>
          <div className="col-1">
            <EditStatusIcon status={editStatus} />
          </div>
          <div className="col-1">
            { /* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/click-events-have-key-events.md */
              id !== undefined && <i className="nav_icon bi bi-trash" onClick={handleDelete} onKeyDown={handleDelete} tabIndex={0} role="button" aria-label="trash" />
            }
          </div>
        </div>
      </form>
    )
    : null;
}
