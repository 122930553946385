import React, { useEffect, useState } from 'react';
import { getCalculatedValuesByClientId } from '@tasper/configurator-calculated-values';
import { Loader } from '@tasper/configurator-core';
import { ProductModelValue } from '../../../../api/models/ProductModelValue';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productModelValue: ProductModelValue;
  clientId: string;
  value: string | undefined;
}

export default function RenderCalculatedValueInput({ clientId, productModelValue, value }: RenderProps) {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCalculatedValuesByClientId(parseInt(clientId, 10)).then(result => {
      setOptions(result.map(calculatedValue => ({
        label: calculatedValue.code,
        value: calculatedValue.code,
      })));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productModelValue={productModelValue} options={options} />
  );
}
