import React from 'react';
import {ReactComponent as LoaderSVG} from './Loader.svg';
import style from './style.module.scss';

const Loader = () => (
  <div className={style.root}>
    {/* <div className={style.svg} dangerouslySetInnerHTML={{ __html: loaderSVG }} /> */}
    <LoaderSVG className={style.svg} />
  </div>
);

export default Loader;
