import React, { useEffect, useState } from 'react';
import { getAllConstants } from '@tasper/configurator-customer-data';
import { Loader } from '@tasper/configurator-core';
import { ProductProperty } from '../../../../api/models/ProductProperty';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productProperty: ProductProperty;
  clientId: string;
  value?: string;
}

export default function RenderConstantInput({ clientId, value, productProperty }: RenderProps) {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllConstants(parseInt(clientId, 10)).then(result => {
      setOptions(result.map(constant => ({
        label: constant.code,
        value: constant.code,
      })));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productProperty={productProperty} options={options} />
  );
}
