import React, { useEffect, useState } from 'react';
import { Item } from '@tasper/configurator-customer-data';
import {
  ValueType, EditStatus, EditStatusIcon, ValueTypeInput,
} from '@tasper/configurator-core';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { AfasImportType } from '../../../api/models/AfasImportType';
import { AfasMapping } from '../types';
import { AfasMapping as AfasMappingApi } from '../../../api/models/AfasMapping';
import { createNewAfasMapping, deleteAfasMapping, updateAfasMapping } from '../../../api/AfasMappingService';

interface AfasImportRowProps {
  mapping: AfasMapping;
  handleSave: () => void;
  // clientId: number;
  afasImportId: number;
  afasImportType: AfasImportType;
  itemsOptions: any[];
  items: Item[];
  defaultDateFormat: string | undefined;
  defaultBooleanFormat: string | undefined;
}

export default function AfasImportRow(props: AfasImportRowProps) {
  const [id, setId] = useState(props.mapping.id);
  const [code, setCode] = useState(props.mapping.code);
  const [itemId, setItemId] = useState(props.mapping.itemId);
  const [defaultValue, setDefaultValue] = useState(props.mapping.defaultValue);
  const [valueType, setValueType] = useState<ValueType | undefined>(undefined); // Based on selected item, to determine type for format and defaultValue
  const [valueFormat, setValueFormat] = useState(props.mapping.valueFormat);
  const [optional, setOptional] = useState(props.mapping.optional);

  const [editStatus, setEditStatus] = useState(EditStatus.none);
  const [isNew, setIsNew] = useState(props.mapping.isNew);
  const [deleted, setDeleted] = useState(false); // Hide component when mapping deleted

  useEffect(() => {
	  if (itemId !== undefined) changeValueType(itemId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChangeCode(e: React.ChangeEvent<HTMLInputElement>) {
	  setCode(e.target.value);
  }

  function handleChangeItemId(value: number) {
	  setItemId(value);
	  changeValueType(value);
  }

  function handleChangeValueFormat(e: React.ChangeEvent<HTMLInputElement>) {
	  setValueFormat(e.target.value);
  }

  // handler :  https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const handleChangeOptional = (e: React.ChangeEvent<HTMLInputElement>) => {
	  setOptional(e.target.checked);
  };

  // handler :  https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const handleChangeDefaultValue = (e: React.ChangeEvent<HTMLInputElement>) => {
	  setDefaultValue(e.target.value);
  };

  // handler :  https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const handleChangeDefaultValueCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
	  setDefaultValue(e.target.checked ? 'true' : 'false');
  };

  function changeValueType(itemId: number) {
	  const foundItem = props.items.find(item => item.id === itemId);
	  setValueType(foundItem?.valueType);
  }

  const handleSave = () => {
	  // Required fields check
	  if (code !== undefined && itemId !== undefined) {
      const mapping: AfasMappingApi = {
		  afasImportId: props.afasImportId,
		  itemId,
		  optional,
		  defaultValue,
		  valueFormat,
      };

      // Map code to correct element, based on afas import type
      switch (props.afasImportType) {
		  case AfasImportType.LoonComponenten:
          mapping.loonCode = code;
          break;

		  case AfasImportType.MedewerkerGegevens:
          mapping.afasElementName = code;
          break;

		  default:
          break;
      }

      if (isNew) {
		  createNewAfasMapping(mapping)
          .then(data => {
			  setEditStatus(EditStatus.success);
			  setIsNew(false);
			  setId(data.id);
			  props.handleSave();
          })
          .catch(() => setEditStatus(EditStatus.error));
      } else {
		  mapping.id = id;
		  updateAfasMapping(mapping)
          .then(() => {
			  setEditStatus(EditStatus.success);
          })
          .catch(() => setEditStatus(EditStatus.error));
      }
	  } else {
      setEditStatus(EditStatus.pending);
	  }
  };

  const handleDelete = () => {
	  if (id !== undefined) {
      if (window.confirm('Are you sure you want to delete this import row?')) {
		  deleteAfasMapping(id)
          .then(() => {
			  toast.success('Deleted');
			  setDeleted(true);
          })
          .catch(() => {
			  toast.error('An error occured while deleting');
          });
      }
	  }
  };

  return !deleted
	  ? (
  <form>
    <div className="row">
      <div className="col-2">
        <input type="text" className="form-control" onChange={handleChangeCode} value={code} onBlur={handleSave} />
      </div>
      <div className="col-2">
        <Select
          options={props.itemsOptions}
          onChange={(option: any) => handleChangeItemId(option.value)}
          value={props.itemsOptions.find(item => item.value === itemId)}
          defaultValue={props.itemsOptions.find(item => item.value === itemId)}
          onBlur={handleSave}
        />
      </div>
      <div className="col-3">
        <div className="row">
          <div className="col-4">
            {valueType}
          </div>
          <div className="col-8">
            {
					valueType === ValueType.DateTime
					&& (
					  <div className="row">
  <div className="col-6">Format:</div>
  <div className="col-6">
    <input className="form-control" onChange={handleChangeValueFormat} value={valueFormat} placeholder={props.defaultDateFormat} onBlur={handleSave} />
  </div>
					  </div>
					)

				  }
            {
					valueType === ValueType.Boolean
					&& (
					  <div className="row">
  <div className="col-6">True waarde:</div>
  <div className="col-6">
    <input className="form-control" onChange={handleChangeValueFormat} value={valueFormat} placeholder={props.defaultBooleanFormat} onBlur={handleSave} />
  </div>
					  </div>
					)
				  }
          </div>
        </div>
      </div>
      <div className="col-2">
        <ValueTypeInput
          valueType={valueType}
          onBlur={handleSave}
          onChange={handleChangeDefaultValue}
          onChangeCheckbox={handleChangeDefaultValueCheckbox}
          value={defaultValue!}
          className="form-control"
          disabled={!optional}
        />
      </div>
      <div className="col-1">
        <label>
          <input type="checkbox" onChange={handleChangeOptional} checked={optional} onBlur={handleSave} />
          Optional
        </label>
      </div>
      <div className="col-1">
        <div className="row">
          <div className="col-6">
            <EditStatusIcon status={editStatus} />
          </div>
          <div className="col-6">
            {
					// eslint-disable-next-line jsx-a11y/control-has-associated-label
					id !== undefined && <i role="button" tabIndex={0} title="trash" className="nav_icon bi bi-trash" onClick={handleDelete} onKeyPress={handleDelete} />
				  }
          </div>
        </div>
      </div>
    </div>
  </form>
	  )
	  : null;
}
