import { sendRequest } from '@tasper/configurator-core';
import makePath from '@tasper/configurator-core/utils/urls';
import { Workflow } from './models/Workflow';

export const byCode = (clientId: number, code: string): Promise<Workflow> => code?.length > 0
    ? sendRequest<Workflow>({
        method: 'GET',
        url: makePath(`api/workflows/:clientId/code/:code`, { clientId, code }),
    })
    : Promise.reject('Code is empty');

export default {
    byCode,
}