import React from 'react';
import { useParams } from 'react-router-dom';
import ItemTable from './parts/ItemTable';

export default function ItemsPage() {
  const { clientId = '' } = useParams();
  return (
    <>
      <h1>Bas items</h1>
      <ItemTable clientId={parseInt(clientId, 10)} />
    </>
  );
}
