import { ProductConfigProperty } from '@tasper/configurator-products/api/models/ProductConfigProperty';
import React, { useEffect, useState } from 'react';
import RenderCheckBox from './RenderCheckBox';

interface ProductConfigPropertiesProps {
  properties: ProductConfigProperty[];
  productConfigurationId: number;
  productId: number;
}

export default function ProductConfigPropertiesForm({ productConfigurationId, productId, properties }: ProductConfigPropertiesProps) {
  const [productConfigProperties, setProductConfigProperties] = useState<ProductConfigProperty[]>([]);

  useEffect(() => {
    if (properties === undefined) {
      // TODO ophalen ProductConfigProperties
    }

    setProductConfigProperties(properties);
  }, [productId, properties]);

  return (
    <div className="row">
      {productConfigProperties.map(property => {
        return (
          <RenderCheckBox key={property.id} productId={productId} mandatory={property.mandatory} entityId={property.id} code={property.propertyCode} />
        );
      })}
    </div>
  );
}
