import { sendRequest } from '@tasper/configurator-core';
import { Customer } from './models/Customer';

const BASE_URL = 'api/client';

export const createClient = (client: Customer): Promise<Customer> => sendRequest<Customer>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(client),
});

export const getClients = (): Promise<Customer[]> => sendRequest<Customer[]>({
  method: 'GET',
  url: `${BASE_URL}/list`,
});

export const deleteClient = (clientId: number) => sendRequest({
  method: 'DELETE',
  url: `${BASE_URL}/${clientId}`,
});
export const getClient = (clientId: string): Promise<Customer> => sendRequest<Customer>({
  method: 'GET',
  url: `${BASE_URL}/${clientId}`,
});
export const updateClient = (client: Customer) => sendRequest({
  method: 'PUT',
  url: `${BASE_URL}/${client.id}`,
  content: JSON.stringify(client),
});
