import React, { useState, useEffect } from 'react';
import { Loader } from '@tasper/configurator-core';

interface TabTableWithNewRowProps<T> {
    renderRow: (rowModel: T, addNewRowEvent: () => void) => React.ReactNode | null;
    emptyRow: T;
    getData: () => Promise<T[]>;
}

function TabTableWithNewRow<T>({ renderRow, emptyRow, getData }: TabTableWithNewRowProps<T>) {
    const [rows, setRows] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getData()
            .then(data => setRows(data.concat([emptyRow, emptyRow])))
            .finally(() => setIsLoading(false));
    }, []);

    function addNewRowEvent() {
        setRows(rows.concat([emptyRow]));
    }

    return (
        <div>
            {
                isLoading
                    ? <Loader />
                    : rows.map(
                        (row, index) =>
                            <React.Fragment key={index}>{
                                renderRow(row, addNewRowEvent)
                            }</React.Fragment>)
            }
        </div>
    );
}

export default React.memo(TabTableWithNewRow) as typeof TabTableWithNewRow;
