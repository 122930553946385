import { sendRequest } from '@tasper/configurator-core';
import { MenuItem } from './models/MenuItem';
import { MenuCategory } from './models/MenuCategory';

const BASE_URL = 'api/MenuCategory';

// TODO comments weghalen als de API end-points er zijn

export const getMenuByClientId = (clientId: number): Promise<MenuCategory[]> => sendRequest<MenuCategory[]>({
  method: 'GET',
  url: `${BASE_URL}/list/${clientId}`,
});

export const createMenu = (menuCategory: MenuCategory) : Promise<MenuCategory> => sendRequest<MenuCategory>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(menuCategory),
});

export const createMenuItem = (item: MenuItem) : Promise<MenuItem> => {
  return new Promise<MenuItem>(resolve => {
    resolve(item);
  });

  // sendRequest<MenuItem>({
  //  method: "POST",
  //  url: `${BASE_URL}/${id}`,
  //  content: JSON.stringify(item)
  // });
};

export const deleteMenuItem = (menuItemId: number) : Promise<number> => {
  return new Promise(resolve => {
    resolve(menuItemId);
  });

  // sendRequest<void>({
  //  method: "DELETE",
  //  url: `${BASE_URL}/${menuItemId}`
  // });
};

export const deleteMenuCategory = (menuCategoryId: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${menuCategoryId}`,
});
