import { Loader } from '@tasper/configurator-core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getImportById } from '../api/ImportService';
import ImportType from '../api/models/ImportType';
import { Import } from '../api/models/Import';
import AfasImportPage from './parts/Afas';
import CsvImportPage from './parts/Csv';
import ImportSettings from './parts/ImportSettings';

export default function EditImport() {
  const { clientId = '', importId = '' } = useParams();
  const [importModel, setImportModel] = useState<Import | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [showImportSettings, setShowImportSettings] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getImportById(parseInt(importId, 10))
      .then(data => {
        if (parseInt(clientId, 10) === data.clientId) {
          setImportModel(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [importId, clientId]);

  function renderTitle(importName: string, importType: string) {
    return (
      <div>
        <h1>
          {importType}
          {' '}
          import
          <small className="text-muted">
            {' '}
            {importName}
            {' '}
          </small>
          {importModel?.primair && <span className="badge badge-pill bg-dark">Primairy</span>}
        </h1>

        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => setShowImportSettings(!showImportSettings)}>
          {
            showImportSettings ? 'Back' : 'Import settings'
          }
        </button>
        <hr />
      </div>
    );
  }

  function renderImport(withImport: boolean) {
    switch (importModel?.importType) {
      case ImportType.CSV:
        return (
          <>
            {renderTitle(importModel.code, ImportType.CSV)}
            {withImport && <CsvImportPage importModel={importModel} />}
          </>
        );
      case ImportType.AFAS:
        return (
          <>
            {renderTitle(importModel.code, ImportType.AFAS)}
            {withImport && <AfasImportPage importModel={importModel} />}
          </>
        );
      default:
        return (
          <div className="alert alert-danger">This import type isn&#39;t supported yet.</div>
        );
    }
  }

  return isLoading
    ? <Loader />
    : importModel === undefined
      ? <div className="alert alert-danger">Import not found!</div>
      : (
        <>
          {renderImport(!showImportSettings)}
          {showImportSettings
            && <ImportSettings importChangedEvent={setImportModel} importModel={importModel} />}
        </>
      );
}
