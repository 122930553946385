import { makePath } from '@tasper/configurator-core/utils/urls';
import { sendRequest } from '@tasper/configurator-core';
import { Workflow } from '../models';

import config from './config.json';

export const all = (): Promise<Workflow[]> => sendRequest<Workflow[]>({
    method: 'GET',
    url: `${config.baseUrl}${config.endpoints.workflows}`,
}).then(data => data || []);

export const byCode = (code: string): Promise<Workflow> => sendRequest<Workflow>({
    method: 'GET',
    url: `${config.baseUrl}${config.endpoints.workflows}/${code}`,
});

export const generateCode = (clientId: number, input: string) => sendRequest({
    method: 'POST',
    url: makePath(`${config.baseUrl}${config.endpoints.workflows_generate}/:clientId`, { clientId }),
    content: input
});

export const submit = (input: Workflow) => sendRequest({
    method: 'POST',
    url: `${config.baseUrl}${config.endpoints.workflows}`,
    content: input
});

export const destroy = (id: number): Promise<Workflow> => sendRequest<Workflow>({
    method: 'DELETE',
    url: makePath(`${config.baseUrl}${config.endpoints.workflows}/:id`, { id }),
});


export default {
    all,
    byCode,
    generateCode,
    submit,
    destroy
}