
function invariant(cond: any, message: string): asserts cond {
    if (!cond) throw new Error(message);
}

/**
 * Example usage:
 * const byCode = (clientId: number, code: string): Promise<Workflow> => sendRequest<Workflow>({
 *     method: 'GET',
 *     url: makePath(`api/workflows/:clientId/code/:code`, { clientId, code }),
 * });
 * @param path 
 * @param params 
 * @returns 
 */
export function makePath(path: string, params: Record<string, any>) {
    return path
        .replace(/:(\w+)/g, ((_, key) =>{
            invariant(params[key] != null, `Missing ":${key}" param`);
            return params[key];
        }));
}

export default makePath;