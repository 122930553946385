import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { AfasImport } from '../../api/models/AfasImport';
import { AfasPrimarySetting } from '../../api/models/AfasPrimarySetting';
import { createNewAfasPrimarySetting, updateAfasPrimarySetting, getAfasPrimarySetting } from '../../api/AfasPrimarySettingSerivce';
import { IAfasImportPageComponent } from '../types';

interface APSProps extends IAfasImportPageComponent {
  afasImport: AfasImport;
}

export default function AfasPrimarySettingForm(props: APSProps) {
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm<AfasPrimarySetting>();
  const onSubmit: SubmitHandler<AfasPrimarySetting> = submitForm;
  const [afasPSId, setAfasPSId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.afasImport.afasPrimarySettingId) {
      setIsLoading(true);

      getAfasPrimarySetting(props.afasImport.afasPrimarySettingId as number)
        .then(data => {
          reset(data);
          setAfasPSId(data.id);
          setIsLoading(false);
        })
        .catch(() => toast.error('An error occured'));
    }
  }, [props.afasImport.afasLoonComponentMappingId, props.afasImport, reset]);

  function submitForm(afasPS: AfasPrimarySetting) {
    const toastId = toast.loading('Saving...');

    if (!afasPSId) {
      afasPS.afasImportId = props.afasImport.id as number;

      // Create
      createNewAfasPrimarySetting(afasPS)
        .then(data => {
          setAfasPSId(data.id);

          // Send new id to parent
          const updatedAfasImport = props.afasImport;
          updatedAfasImport.afasPrimarySettingId = data.id;
          props.updateAfasImport(updatedAfasImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    } else {
      // Update
      updateAfasPrimarySetting(afasPS)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    }
  }

  function renderTextInput(name: any, label: string) {
    return (
      <div className="form-group">
        <label>
          {label}
          {' '}
          element
        </label>
        <input
          className="form-control"
          type="text"
          required
          {...register(name)}
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderTextInput('personeelsnummerElement', 'Staff number')}
        {renderTextInput('emailElement', 'Email')}
        {renderTextInput('usernameElement', 'Username')}
        {renderTextInput('naamElement', 'Name')}
        {renderTextInput('didelement', 'Datum in dienst (DID)')}
        {renderTextInput('dudelement', 'Datum uit dienst (DUD)')}
        <button className="btn btn-success">Save</button>
      </form>
    );
}
