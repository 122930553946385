import { ProductConfigMethod } from '@tasper/configurator-products/api/models/ProductConfigMethod';
import React, { useEffect, useState } from 'react';
import RenderCheckBox from './RenderCheckBox';

interface ProductConfigMethodsFormProps {
  methods: ProductConfigMethod[];
  productConfigurationId: number;
  productId: number;
}

export default function ProductConfigMethodsForm({ methods, productConfigurationId, productId }: ProductConfigMethodsFormProps) {
  const [productConfigmethods, setproductConfigmethods] = useState<ProductConfigMethod[]>([]);

  useEffect(() => {
    if (methods === undefined) {
      // Vullen als lijst leeg is!
    }

    setproductConfigmethods(methods);
  }, [productId, methods]);

  return (
    <div className="row">
      {productConfigmethods.map(productConfigMethod => {
        return (
          <RenderCheckBox
            key={productConfigMethod.id}
            productId={productId}
            mandatory={productConfigMethod.mandatory}
            entityId={productConfigMethod.id}
            code={productConfigMethod.methodName}
          />
        );
      })}
    </div>
  );
}
