import { sendRequest } from '@tasper/configurator-core';
import { AfasPrimarySetting } from './models/AfasPrimarySetting';

const BASE_URL = 'api/AfasPrimarySetting';

export const createNewAfasPrimarySetting = (afasPrimarySetting: AfasPrimarySetting) : Promise<AfasPrimarySetting> => sendRequest<AfasPrimarySetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(afasPrimarySetting),
});

export const updateAfasPrimarySetting = (afasPrimarySetting: AfasPrimarySetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${afasPrimarySetting.id}`,
  content: JSON.stringify(afasPrimarySetting),
});

export const getAfasPrimarySetting = (afasPrimarySettingId: number) : Promise<AfasPrimarySetting> => sendRequest<AfasPrimarySetting>({
  method: 'GET',
  url: `${BASE_URL}/${afasPrimarySettingId}`,
});
