import { sendRequest } from '@tasper/configurator-core';
import { ProductModelValue } from './models/ProductModelValue';

const BASE_URL = 'api/ProductModelValue';

export const getProductModelValueByProductIdAndProductDefinitionId = (productId: number, productDefinitionId: number) : Promise<ProductModelValue> => {
  return sendRequest<ProductModelValue>({
    method: 'GET',
    url: `${BASE_URL}/GetByProductIdAndProductConfigMethodId/${productId}/${productDefinitionId}`,
  });
};

export const createProductModelValue = (productModelValue: ProductModelValue) : Promise<ProductModelValue> => sendRequest<ProductModelValue>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(productModelValue),
});

export const updateProductModelValue = (productModelValue: ProductModelValue) => sendRequest({
  method: 'PUT',
  url: `${BASE_URL}/${productModelValue.id}`,
  content: JSON.stringify(productModelValue),
});

export const deleteProductModelValue = (productModelValueId: number) : Promise<ProductModelValue> => sendRequest<ProductModelValue>({
  method: 'DELETE',
  url: `${BASE_URL}/${productModelValueId}`,
});

export const getProductModelValueByProductId = (productId: number) : Promise<ProductModelValue[]> => sendRequest<ProductModelValue[]>({
  method: 'GET',
  url: `${BASE_URL}/GetByProductId/${productId}`,
});
