import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.scss';

interface MenuProps {
  items: NavItem[];
  titleLogo: string;
}

interface NavItem {
  title: string;
  route: string;
}

export default function Menu({ items, titleLogo }: MenuProps) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary static-top">
      <NavLink to="/" className={`${styles.navbar_brand} navbar-brand`}>
        <i className="bi bi-layers nav_logo-icon" />
        {' '}
        {titleLogo}
      </NavLink>
      <ul className="navbar-nav">
        {
          items.map((element, index) => {
            return (
              <li className="nav-item" key={index}>
                <NavLink to={element.route} className="nav-link">{element.title}</NavLink>
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
}
