import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListOverview from './ListOverview';
import CreateAndEditList from './CreateAndEditList';

const ListRouter = () => {
  return (
    <Routes>
      <Route path="" element={<ListOverview />} />
      <Route path="create" element={<CreateAndEditList />} />
    </Routes>
  );
};

export default ListRouter;
