import React, { useState } from 'react';
import { ProductProperty } from '../../../../api/models/ProductProperty';
import { updateProductProperty } from '../../../../api/ProductPropertyService';

interface RenderValueInputProps {
  productProperty: ProductProperty;
  currentValue?: string;
}
export default function RenderValueInput(props: RenderValueInputProps) {
  const [value, setValue] = useState(props.currentValue);

  function handleOnBlur() {
    const property = props.productProperty;
    property.value = value;
    updateProductProperty(property).then(() => {

    });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }
  return (
    <div className="row">
      <div className="col-5">
        <input className="form-control" value={value} onBlur={handleOnBlur} onChange={handleChange} />
      </div>
    </div>
  );
}
