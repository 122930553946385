import React, { useEffect, useState } from 'react';
import { Loader, FormSelectBoxItem } from '@tasper/configurator-core';
import { getAllConstants, Constant } from '@tasper/configurator-customer-data';
import { ListTypeInput } from './ListTypeInput';
import { BaseListTypeInputProps } from './BaseListTypeInputProps';

export default function ConstantListTypeInput(props: BaseListTypeInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [constants, setConstants] = useState<Constant[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getAllConstants(Number(props.clientId)).then(data => {
      setConstants(data);
    });

    setIsLoading(false);
  }, [props.clientId]);

  function getData(): FormSelectBoxItem<string>[] {
    return constants.map(constant => { return { label: constant.code, value: constant.code }; });
  }

  return (
    isLoading
      ? <Loader />
      : <ListTypeInput clientId={props.clientId} addToListItems={props.addToListItems} label="constanten toevoegen aan de lijst" selectBoxItems={getData()} buttonText="ander constante toevoegen" />
  );
}
