import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { languages } from 'monaco-editor/esm/vs/editor/editor.api';

type ModuleType =
  | typeof import('./cs-template')
  | typeof import('./calc')
  | typeof import('./rules')
  | never;

export type Monaco = typeof monaco;
export type LanguageConfiguration = languages.ILanguageExtensionPoint & { loader: () => Promise<ModuleType> };

export const csTemplate: LanguageConfiguration = {
  id: 'cs-template',
  extensions: ['.cs-template'],
  aliases: ['CS-TEMPLATE'],
  mimetypes: ['text/cs-template'],
  loader: () => import('./cs-template'),
};

export const calc: LanguageConfiguration = {
  id: 'calc',
  extensions: ['.calc'],
  aliases: ['CALCC'],
  mimetypes: ['text/calc'],
  loader: () => import('./calc'),
};

export const rules: LanguageConfiguration = {
  id: 'rules',
  extensions: ['.rule'],
  aliases: ['RULE'],
  mimetypes: ['text/rules'],
  loader: () => import('./rules'),
};
