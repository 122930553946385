import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { getCalculatedValuesByClientId } from '../../../api/CalculatedValueService';
import 'react-toastify/dist/ReactToastify.css';
import { CalculatedValueItem } from './types';
import CalculatedValueRow from './parts/CalculatedValueRow';
import { TabTableWithNewRow, ValueType } from '@tasper/configurator-core';
import Modal from '@tasper/configurator-core/Modal';
import { byCode } from '../../../api/workflows';
import { Workflow } from '@tasper/configurator-calculated-values/api/models/Workflow';
import { MonacoEditor } from '@tasper/code-editor';
import useApi from '@tasper/configurator-core/hooks/useApi';
import { Autocomplete } from '@tasper/code-templates/src/components/Autocomplete';
import workflows from '@tasper/code-templates/src/api/workflows';
import workflowReducer, { copyFrom, EditorState, newWorkflow, setCode, setWorkflow, setWorkflowScript } from './CalculatedValueTable.state';


interface CalculatedValueTableProps {
    clientId: number;
}

const useWorkflowRule = (clientId: number) => {
    const [isLoading, setIsloading] = useState(false);
    const [data, setData] = useState<Workflow>();
    const [error, setError] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (code.length > 0) {
            setIsloading(true);
            byCode(clientId, code)
                .then(data => setData(data))
                .catch(error => setError(error))
                .finally(() => setIsloading(false));
        }
    }, [clientId, code]);

    const refresh = (code: string) => {
        setCode(code);
    };

    return {
        data,
        error,
        isLoading,
        refresh
    };
};

type arg = Parameters<typeof byCode>;
type ret = ReturnType<typeof byCode>;

export default function CalculatedValueTable({ clientId }: CalculatedValueTableProps) {
    //const { data, error, isLoading, refresh: getByCode } = useWorkflowRule(clientId);
    const workflowApi = useApi(() => workflows.all());
    const { data, error, isLoading, fetch: getByCode } = useApi(byCode);
    const [selectedItem, dispach] = useReducer(workflowReducer, {} as EditorState);
    const [isOpen, setIsOpen] = useState(false);

    const getData = useCallback((): Promise<CalculatedValueItem[]> => {
        workflowApi.fetch();
        return new Promise((resolve, reject) => {
            getCalculatedValuesByClientId(clientId)
                .then(data => resolve(data.map(element => ({
                    id: element.id,
                    name: element.code,
                    type: element.returnVariableValueType,
                    workflowCode: element.workflowCode
                }))))
                .catch(error => reject(error));
        });
    }, [clientId]);

    const openWorkflow = useCallback((item: CalculatedValueItem) => {
        dispach(newWorkflow(item.id, clientId, item.workflowCode || '', item.type || ValueType.Decimal));
        setIsOpen(true);

        if (item.workflowCode || !item.isNew) {
            getByCode(clientId, item.workflowCode || '');
        }
    }, []);

    useEffect(() => {
        if (error) {
            console.warn(error);
        } else if (data) {
            dispach(setWorkflow({
                ...data,
                calculatedValueId: selectedItem.calculatedValueId || 0
            }));
        }
    }, [data]);

    const renderRow = (rowModel: CalculatedValueItem, addNewRowEvent: () => void) => {
        return <CalculatedValueRow
            onSave={addNewRowEvent}
            calculatedValue={rowModel}
            clientId={clientId}
            openWorkflow={openWorkflow} />;
    };

    const useWorkflowAsTemplate = (code: string) => {
        const entry = workflowApi.data?.find(f => f.code === code);
        if (entry) {
            dispach(copyFrom(entry.code, entry.rawWorkflowData));
        }
    };

    const handleModalClose = async (action: string) => {
        if (action === 'save') {
            await workflows.submit({
                calculatedValueId: selectedItem.calculatedValueId,
                ...selectedItem.model
            } as any);

            setIsOpen(state => !state);
        } else {
            setIsOpen(state => !state);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-5">Calculated value code</div>
                <div className="col-5">Type</div>
            </div>
            <TabTableWithNewRow
                emptyRow={{ isNew: true }}
                renderRow={renderRow}
                getData={getData}
            />
            <Modal open={isOpen} onClose={handleModalClose}>
                {
                    isLoading
                        ? (<h1>Loading...</h1>)
                        : (<>
                            <form>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-badge-cc"></i></span>
                                    <Autocomplete items={workflowApi.data}
                                        className={['form-control', 'border', 'border-info', 'rounded-0'].filter(Boolean).join(' ')}
                                        required
                                        name="mainTemplateCode"
                                        onSelectItem={value => useWorkflowAsTemplate(value)}
                                        style={{ fontStyle: 'italic' }}
                                        template={(item) => <table style={{ width: '100%' }}><tr><td width={'50%'}>{item.code}</td><td>{item.client.klantnaam}</td></tr></table>}
                                        displayField='code'
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-hash"></i></span>
                                    <input
                                        type='text'
                                        className="form-control rounded-0"
                                        placeholder='Workflow Code'
                                        value={selectedItem.model?.code}
                                        onChange={(evt) => dispach(setCode(evt.target.value))}
                                        aria-describedby="basic-addon1" />
                                </div>
                            </form>
                            {
                                selectedItem && (
                                    <div style={{ height: 'calc(100% - 38px - .5em', overflow: 'hidden' }}>
                                        <MonacoEditor
                                            width='70vw'
                                            language='calc'
                                            value={selectedItem.model?.rawWorkflowData || ''}
                                            onValueChanged={(text) => dispach(setWorkflowScript(text || ''))} />
                                    </div>
                                    // <code>
                                    //     <pre dangerouslySetInnerHTML={{ __html: JSON.stringify(selectedItem, null, 2) }}></pre>
                                    // </code>
                                )
                            }
                        </>)
                }
            </Modal>
        </>
    );
}
