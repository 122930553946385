import React, { useCallback, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import {
    ValueType, EditStatus, EditStatusIcon, ValueTypeDropdown,
} from '@tasper/configurator-core';

import { createCalculatedValue, changeCalculatedValue, deleteCalculatedValue } from '../../../../api/CalculatedValueService';
import { CalculatedValueItem } from '../types';
import { usePromise } from '@tasper/configurator-core/hooks';

interface CalculatedValueRowProps {
    calculatedValue: CalculatedValueItem;
    onSave: () => void;
    clientId: number;
    openWorkflow?: (item: CalculatedValueItem) => void
}
/*
const reducer = (state: any & { [name: string]: any }, action: { type: string, [name: string]: any }) => {
    switch (action.type) {
        case 'save':

            break;

        default:
            return state;
    }
};
*/
export default function CalculatedValueRow({ calculatedValue, clientId, onSave, openWorkflow }: CalculatedValueRowProps) {
    const [rowData, setRowData] = useState(calculatedValue);

    const [id, setId] = useState(calculatedValue.id);
    const [calculatedValueName, setcalculatedValueName] = useState(calculatedValue.name || '');
    const [type, setType] = useState<ValueType | undefined>(calculatedValue.type || undefined);
    const [isNew, setIsNew] = useState(calculatedValue.isNew);
    const [editStatus, setEditStatus] = useState(EditStatus.none);
    const [deleted, setDeleted] = useState(false); // Hide component when user deleted

    function handleChangeCalculatedValueName(e: React.ChangeEvent<HTMLInputElement>) {
        setEditStatus(EditStatus.pending);
        setcalculatedValueName(e.currentTarget.value);
    }

    const handleChangeType = (value: ValueType | undefined) => {
        setEditStatus(EditStatus.pending);
        setType(value);
    };

    const handleSave = async () => {
        if (calculatedValueName !== '' && type !== undefined) {
            if (isNew) {
                const [data, error] = await usePromise(() => createCalculatedValue({
                    clientId,
                    code: calculatedValueName,
                    returnVariableValueType: type,
                    workflowCode: '',
                }));

                if (data) {
                    setId(data.id);
                    setIsNew(false);
                    setEditStatus(EditStatus.success);
                    onSave();
                    setRowData(data);
                } else if (error) {
                    toast.error(`An error occured while creating calculated value ${calculatedValueName}`);
                    setEditStatus(EditStatus.error);
                }

            } else {
                const [, error] = await usePromise(() => changeCalculatedValue({
                    id,
                    code: calculatedValueName,
                    returnVariableValueType: type,
                    clientId,
                    workflowCode: rowData.workflowCode
                }));

                if (error) {
                    toast.error(`An error occured while updating calculated value ${calculatedValueName}`);
                    setEditStatus(EditStatus.error);
                } else {
                    setEditStatus(EditStatus.success);
                }
            }
        }
    };

    const handleDelete = () => {
        if (id && window.confirm('Are you sure you want to delete this calculated value?')) {
            deleteCalculatedValue(id)
                .then(() => {
                    toast.success(`Calculated value ${calculatedValueName} is successfully deleted.`);
                    setDeleted(true); // Hide component
                })
                .catch(() => {
                    toast.error(`An error occured while deleting calcualted value ${calculatedValueName}`);
                });
        }
    };

    const openWorkflowClicked: React.MouseEventHandler<HTMLButtonElement> = useCallback((evt) => {
        evt.preventDefault();
        if (openWorkflow) openWorkflow(rowData);
    }, [openWorkflow, rowData]);

    return !deleted
        ? (
            <form>
                <div className="row">
                    <div className="col-10">
                        <div className="hstack gap-3">
                            <input
                                placeholder="Name"
                                value={calculatedValueName}
                                onChange={handleChangeCalculatedValueName}
                                onBlur={handleSave}
                                required
                                className="form-control me-auto"
                            />

                            <ValueTypeDropdown
                                className='me-auto'
                                initialValue={type?.toString()}
                                onChange={handleChangeType}
                                onBlur={handleSave}
                            />

                            <EditStatusIcon status={editStatus} />
                        </div>
                    </div>
                    <div className="col-1">
                        {
                            id ? (
                                <div className='hstack gap-3 justify-content-end'>
                                    <button type='button' className={[
                                        'btn',
                                        'btn-sm',
                                        (calculatedValue.workflowCode && calculatedValue.workflowCode.length > 0)
                                            ? 'btn-outline-primary'
                                            : 'btn-outline-secondary'
                                    ].filter(Boolean).join(' ')} onClick={openWorkflowClicked}>
                                        <i className="col bi bi-file-earmark-code" title={`Workflow: ${calculatedValue.workflowCode}`}></i>
                                    </button>
                                    <button type='button' className='btn btn-sm btn-outline-danger' {...{ disabled: id === undefined }} onClick={handleDelete}>
                                        <i className="col nav_icon bi bi-trash" />
                                    </button>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </form>
        )
        : null;
}
