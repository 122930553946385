import { sendRequest } from '@tasper/configurator-core';
import { CsvPgpSetting } from './models/CsvPgpSetting';

const BASE_URL = 'api/CsvPgpSetting';

export const createNewCsvPgpSetting = (csvPgpSetting: CsvPgpSetting) : Promise<CsvPgpSetting> => sendRequest<CsvPgpSetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(csvPgpSetting),
});

export const updateCsvPgpSetting = (csvPgpSetting: CsvPgpSetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${csvPgpSetting.id}`,
  content: JSON.stringify(csvPgpSetting),
});

export const getCsvPgpSetting = (csvPgpSettingId: number) : Promise<CsvPgpSetting> => sendRequest<CsvPgpSetting>({
  method: 'GET',
  url: `${BASE_URL}/${csvPgpSettingId}`,
});

export const deleteCsvPgpSetting = (csvPgpSettingId: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${csvPgpSettingId}`,
});
