import React, { useState } from 'react';
import { Import } from '@tasper/configurator-imports/api/models/Import';
import AfasConnectionSettingsForm from './parts/AfasConnectionSettingsForm';
import AfasLoonComponentMappingForm from './parts/AfasLoonComponentenMapping';
import AfasSettingForm from './parts/AfasSettingForm';
import AfasConfigurationForm from './parts/AfasConfigurationForm';
import AfasPrimarySettingForm from './parts/AfasPrimarySettingForm';
import AfasMappingTable from './parts/AfasMappingTable';
import { AfasImport } from './api/models/AfasImport';
import { AfasImportType } from './api/models/AfasImportType';

interface AfasImportProps {
  importModel: Import;
}

enum AfasImportTabs {
  ConnectionSettings,
  LoonComponentMapping,
  PrimarySettings,
  Settings,
  Configuration,
  Mapping,
}

export default function AfasImportPage({ importModel }: AfasImportProps) {
  const [currentTab, setCurrentTab] = useState(AfasImportTabs.ConnectionSettings);
  const [afasImport, setAfasImport] = useState(importModel.afasImport as AfasImport);

  function handleTabClick(tab: AfasImportTabs) {
    setCurrentTab(tab);
  }

  // refactor : https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const childChanged = (model: AfasImport) => {
    setAfasImport(model);
  };

  function renderSubpage() {
    if (importModel.afasImport) {
      switch (currentTab) {
        case AfasImportTabs.ConnectionSettings:
          return <AfasConnectionSettingsForm afasImport={afasImport} clientId={importModel.clientId} updateAfasImport={childChanged} />;

        case AfasImportTabs.PrimarySettings:
          return <AfasPrimarySettingForm afasImport={afasImport} updateAfasImport={childChanged} />;

        case AfasImportTabs.LoonComponentMapping:
          return <AfasLoonComponentMappingForm afasImport={afasImport} updateAfasImport={childChanged} />;

        case AfasImportTabs.Settings:
          return <AfasSettingForm afasImport={afasImport} updateAfasImport={childChanged} />;

        case AfasImportTabs.Configuration:
          return (
            <AfasConfigurationForm
              afasImport={afasImport}
              clientId={importModel.clientId}
              updateAfasImport={childChanged}
              isPrimairImport={importModel.primair}
            />
          );

        case AfasImportTabs.Mapping:
          return (
            <AfasMappingTable
              afasImport={afasImport}
              clientId={importModel.clientId}
              defaultBooleanFormat={importModel.standardBooleanFormat}
              defaultDateFormat={importModel.standardDateFormat}
            />
          );
        default:
          return null;
      }
    }

    return null;
  }

  function renderNavItem(tab: AfasImportTabs, label: string) {
    return (
      <li className="nav-item">
        <button
          type="button"
          className={`nav-link${currentTab === tab ? ' active' : ''}`}
          onClick={() => handleTabClick(tab)}
        >
          {label}
        </button>
      </li>
    );
  }

  return (
    <>
      <h4>{importModel.afasImport?.afasImportType}</h4>
      <ul className="nav nav-tabs">
        {renderNavItem(AfasImportTabs.ConnectionSettings, 'Connection')}
        {
          importModel.afasImport && (
            <>
              {
                importModel.afasImport.afasImportType === AfasImportType.LoonComponenten
                && renderNavItem(AfasImportTabs.LoonComponentMapping, 'Wage components mapping')
              }
              {
                importModel.primair
                && renderNavItem(AfasImportTabs.PrimarySettings, 'Employee mapping')
              }
            </>
          )
        }
        {renderNavItem(AfasImportTabs.Settings, 'Setting')}
        {renderNavItem(AfasImportTabs.Configuration, 'Configuration')}
        {renderNavItem(AfasImportTabs.Mapping, 'Mapping')}
      </ul>
      <br />
      {
        renderSubpage()
      }
    </>
  );
}
