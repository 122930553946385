import React, {
    InputHTMLAttributes,
    memo,
    MouseEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import styles from "./Autocomplete.module.scss";
import { isArrayOfStrings } from "./utils";

interface AutocompleteProps<TItem> extends InputHTMLAttributes<HTMLInputElement> {
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined
    value?: string | undefined
    onSelectItem: (item: string) => void

    items: TItem[] | undefined
    template?: (item: TItem) => React.ReactElement
    displayField?: string | number | symbol
}

function Autocomplete<TItem>(
    { items = [], onChange, onKeyDown, value, onSelectItem, template, displayField, ...rest }: AutocompleteProps<TItem>,
    ref?: React.Ref<HTMLInputElement>
) {
    const listRef = useRef<HTMLOListElement>(null!);
    const [state, setState] = useState({
        activeSuggestion: 0,
        showSuggestions: false,
        inputText: value || ''
    });

    const field = !displayField
        ? ((name: string) => name)
        : ((value: { [name: string | number | symbol]: any }) => value[displayField] as string);

    const suggestions = useMemo(() => {
        return items.filter(
            (suggestion) =>
                field(suggestion as any).toLowerCase().indexOf(state.inputText!.toLowerCase()) > -1
        );

    }, [items, state.inputText]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current
                .querySelector(`.${styles["suggestion-active"]}`)
                ?.scrollIntoView({
                    block: "end",
                    inline: "nearest"
                });
        }
    }, [listRef, state.activeSuggestion]);

    const onkeydown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (evt) => {
            const { activeSuggestion } = state;
            switch (evt.key) {
                case "Enter": {
                    if (onKeyDown) onKeyDown(evt);
                    if (evt.isPropagationStopped()) return;

                    const value = state.showSuggestions && state.activeSuggestion >= 0
                        ? field(suggestions[state.activeSuggestion] as any) as string
                        : state.inputText || "";

                    setState((current) => ({
                        ...current,
                        activeSuggestion: 0,
                        showSuggestions: false,
                        inputText: value
                    }));
                    onSelectItem(value);
                    evt.preventDefault();
                    break;
                }

                case "ArrowUp":
                    if (activeSuggestion === 0) {
                        return;
                    }
                    setState((current) => ({
                        ...current,
                        activeSuggestion: activeSuggestion - 1
                    }));
                    evt.preventDefault();
                    break;

                case "ArrowDown":
                    if (activeSuggestion - 1 === suggestions.length) {
                        return;
                    }
                    setState((current) => ({
                        ...current,
                        activeSuggestion: activeSuggestion + 1
                    }));
                    evt.preventDefault();
                    break;

                default:
                    //if (onKeyDown) onKeyDown(evt);
                    break;
            }
        },
        [onKeyDown, state, suggestions, onSelectItem]
    );

    const onchange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (evt) => {
            setState({
                activeSuggestion: 0,
                showSuggestions: true,
                inputText: evt.currentTarget.value
            });

            if (onChange) onChange(evt);
        },
        [onChange]
    );

    const onclick: MouseEventHandler<HTMLLIElement> = (evt) => {
        setState({
            activeSuggestion: 0,
            showSuggestions: false,
            inputText: evt.currentTarget.title || ""
        });

        //onSelectItem(evt.currentTarget.textContent || "");
        onSelectItem(evt.currentTarget.title || "");

        evt.stopPropagation();
    };

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const clickOutside = (evt: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(evt.target as Node)
            ) {
                onSelectItem(state.inputText || "");
                setState((current) => ({
                    ...current,
                    showSuggestions: false
                }));
            }
        };

        window.addEventListener("mousedown", clickOutside);
        return () => {
            window.removeEventListener("mousedown", clickOutside);
        };
    }, [containerRef, onSelectItem, state.inputText]);

    const onblur: React.FocusEventHandler<HTMLInputElement> = (e) => {
        // if (e.currentTarget === e.target) {
        //   console.log("unfocused self");
        // } else {
        //   console.log("unfocused child", e.target);
        // }
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            // Not triggered when swapping focus between children
            // console.log("focus left self");
            // const value = state.showSuggestions && state.activeSuggestion >= 0 
            //     ? suggestions[state.activeSuggestion] 
            //     : state.inputText || "";
            const value = state.inputText || "";

            onSelectItem(value);
            setState((current) => ({
                ...current,
                showSuggestions: false
            }));
        }
    };

    return (
        <div className={styles["suggest"]} ref={containerRef} onBlur={onblur}>
            <input
                value={state.inputText}
                onChange={onchange}
                onKeyDown={onkeydown}
                onFocus={() => setState(c => ({ ...c, showSuggestions: true }))}
                ref={ref}
                {...rest}
                type="text"
                autoComplete="off"
            />
            <div style={{ flex: 'none', position: 'relative' }}>
                {state.showSuggestions && (
                    <ol className={styles["suggestions"]} ref={listRef}>
                        {suggestions.map((displayValue, index) => (
                            <li
                                className={[
                                    index === state.activeSuggestion &&
                                    styles["suggestion-active"]
                                ]
                                    .filter(Boolean)
                                    .join(" ")}
                                key={index}
                                title={field(displayValue as any)}
                                onMouseDown={onclick}
                            >
                                <a
                                    style={{
                                        display: "block"
                                    }}
                                >
                                    {template ? template(displayValue) : displayValue}
                                </a>
                            </li>
                        ))}
                    </ol>
                )}
            </div>
        </div>
    );
}

const AutocompleteWithRef = React.forwardRef(Autocomplete) as <TItem>(
    props: AutocompleteProps<TItem> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof Autocomplete>;

export default AutocompleteWithRef;
