import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getAllConstantsByType } from '@tasper/configurator-customer-data';
import { ValueType, FormSelectBoxItem, Loader } from '@tasper/configurator-core';
import { CsvSftpSetting } from '../../../api/models/CsvSftpSetting';
import { createNewCsvSftpSetting, getCsvSftpSetting, updateCsvSftpSetting } from '../../../api/CsvSftpSettingService';

interface CsvSftpSettingsProps {
  clientId: number;
  csvSftpSettingsId?: number | null;
  csvImportId: number;
  createdSftpSettingsEvent: (csvSftpSettingsId: number) => void;
}

interface CsvSftpSettingsFormModel {
  filePathConstantId?: FormSelectBoxItem<number>;
  sftpImportHostConstantId?: FormSelectBoxItem<number>;
  sftpImportPortConstantId?: FormSelectBoxItem<number>;
  sftpImportUsernameConstantId?: FormSelectBoxItem<number>;
  sftpImportPasswordConstantId?: FormSelectBoxItem<number>;
  importFilenameConstantId?: FormSelectBoxItem<number>;
}

export default function CsvSftpSettings(props: CsvSftpSettingsProps) {
  const {
    control, handleSubmit, reset, formState: { errors },
  } = useForm<CsvSftpSettingsFormModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [csvSFTPId, setCsvSFTPId] = useState<number | undefined>();
  const [stringConstantOptions, setStringConstantOptions] = useState<any[]>([]);
  const [intConstantsOptions, setIntConstantsOptions] = useState<any[]>([]);

  useEffect(() => {
    function updateForm(csvSftpSettings: CsvSftpSetting, stringConstantOptions: FormSelectBoxItem<number>[], intConstantOptions: FormSelectBoxItem<number>[]) {
      reset({
        filePathConstantId: stringConstantOptions.find(constant => constant.value === csvSftpSettings.filePathConstantId),
        sftpImportHostConstantId: stringConstantOptions.find(constant => constant.value === csvSftpSettings.sftpImportHostConstantId),
        sftpImportPortConstantId: intConstantOptions.find(constant => constant.value === csvSftpSettings.sftpImportPortConstantId),
        sftpImportUsernameConstantId: stringConstantOptions.find(constant => constant.value === csvSftpSettings.sftpImportUsernameConstantId),
        sftpImportPasswordConstantId: stringConstantOptions.find(constant => constant.value === csvSftpSettings.sftpImportPasswordConstantId),
        importFilenameConstantId: stringConstantOptions.find(constant => constant.value === csvSftpSettings.importFileNameConstantId),
      });
    }

    const promiseStrings = getAllConstantsByType(props.clientId, ValueType.String);
    const promiseInts = getAllConstantsByType(props.clientId, ValueType.Int);

    Promise.all([promiseStrings, promiseInts])
      .then(data => {
        const stringData = data[0];
        const intData = data[1];

        const stringConstantOptionsList = stringData.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));

        const intConstantOptionsList = intData.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));

        setStringConstantOptions(stringConstantOptionsList);
        setIntConstantsOptions(intConstantOptionsList);

        if (props.csvSftpSettingsId) {
          getCsvSftpSetting(props.csvSftpSettingsId)
            .then(data => {
              updateForm(data, stringConstantOptionsList as FormSelectBoxItem<number>[], intConstantOptionsList as FormSelectBoxItem<number>[]);
              setCsvSFTPId(data.id);
              setIsLoading(false);
            })
            .catch(() => {
              toast.error('An error occured');
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error('An error occured');
      });
  }, [props.clientId, props.csvSftpSettingsId, reset]);

  function convertFormToModel(form: CsvSftpSettingsFormModel): CsvSftpSetting {
    const obj: CsvSftpSetting = {
      csvImportId: props.csvImportId as number,
      filePathConstantId: form.filePathConstantId!.value,
      sftpImportHostConstantId: form.sftpImportHostConstantId!.value,
      sftpImportPortConstantId: form.sftpImportPortConstantId!.value,
      sftpImportUsernameConstantId: form.sftpImportUsernameConstantId!.value,
      sftpImportPasswordConstantId: form.sftpImportPasswordConstantId!.value,
      importFileNameConstantId: form.importFilenameConstantId!.value,
    };

    if (csvSFTPId !== undefined) obj.id = csvSFTPId;

    return obj;
  }

  const onSubmit: SubmitHandler<CsvSftpSettingsFormModel> = function submitForm(csvSftpSettingsFormModel: CsvSftpSettingsFormModel) {
    const toastId = toast.loading('Opslaan...');
    const model = convertFormToModel(csvSftpSettingsFormModel);

    if (!model.id) {
      // Create

      createNewCsvSftpSetting(model)
        .then(data => {
          setCsvSFTPId(data.id);

          // Send id to parent
          if (data.id) props.createdSftpSettingsEvent(data.id);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    } else {
      // Update
      updateCsvSftpSetting(model)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating', type: 'error', isLoading: false }));
    }
  };

  function selectRender(name: keyof CsvSftpSettingsFormModel, label: string, constantOptions: FormSelectBoxItem<number>[]) {
    return (
      <div className="form-group">
        <label>
          {label}
          {' '}
          constante
        </label>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={
            ({ field }) => (
              <Select
                {...field}
                options={constantOptions}
                placeholder="Choose a constant"
              />
            )
          }
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required"
          name={name}
        />
        <br />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectRender('filePathConstantId', 'File path', stringConstantOptions)}
        {selectRender('sftpImportHostConstantId', 'Host', stringConstantOptions)}
        {selectRender('sftpImportPortConstantId', 'Port', intConstantsOptions)}
        {selectRender('sftpImportUsernameConstantId', 'Username', stringConstantOptions)}
        {selectRender('sftpImportPasswordConstantId', 'Password', stringConstantOptions)}
        {selectRender('importFilenameConstantId', 'Filename', stringConstantOptions)}
        <button type="submit" className="btn btn-success">Save</button>
      </form>
    );
}
