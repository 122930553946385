import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { Loader } from '@tasper/configurator-core';
import { getListsByClientId, deleteListForConstant, deleteListItem } from '../api/ListService';
import { ListForConstant } from '../api/models/ListForConstant';

export function ListOverview() {
  const { clientId = '' } = useParams();
  const [listForConstants, setlistForConstants] = useState<ListForConstant[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getListsByClientId(clientId).then(result => {
      setlistForConstants(result);
      setIsLoading(false);
    });
  }, [clientId]);

  function deleteListForConstantEvent(listForConstantId: number) {
    if (window.confirm('Are you sure you want to delete this list and possible list items?')) {
      deleteListForConstant(listForConstantId).then(() => { // TODO request niet meer mocken
        const newList = listForConstants.filter(listForConstant => listForConstant.id !== listForConstantId);
        setlistForConstants(newList);
      });
    }
  }

  function deleteListItemEvent(listItemId: number, listForConstantId: number) {
    if (window.confirm('Are you sure you want to delete this list item?')) {
      deleteListItem(listItemId).then(() => { // TODO request niet meer mocken
        const listForConstant = listForConstants.find(x => x.id === listForConstantId);

        if (listForConstant) {
          const tempLists = listForConstants.filter(list => list.id !== listForConstant?.id);
          listForConstant.listItems = listForConstant.listItems.filter(listItem => listItem.id !== listItemId);

          tempLists.push(listForConstant);
          setlistForConstants(tempLists);
        }
      });
    }
  }

  return (
    <div>
      {isLoading
        ? <div><Loader /></div>
        : (
          <div className="row">
            <NavLink to={`/configuratie/${clientId}/lists/create/`}>Create new list</NavLink>
            <div>
              <ol className="tree-structure">
                {listForConstants.map((listForConstant, key) => {
                  return (
                    <li key={key}>

                      <Link to="/">
                        <span className="num">{listForConstant.name}</span>
                      </Link>
                      <button type="button" onClick={() => deleteListForConstantEvent(listForConstant.id)}>Delete</button>
                      <ol>
                        {listForConstant.listItems.map((listItem, x) => {
                          return (
                            <li key={x}>
                              <Link to="/">
                                <span className="num">{listItem.code}</span>
                              </Link>
                              <button type="button" onClick={() => deleteListItemEvent(listItem.id, listForConstant.id)}>Delete</button>
                            </li>

                          );
                        })}
                      </ol>
                    </li>
                  );
                })}
              </ol>
            </div>

          </div>
        )}
    </div>
  );
}

export default ListOverview;
