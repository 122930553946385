import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './HorizontalMenu.module.scss';

interface HorizontalMenuProps {
  title: string;
  items: HorizontalMenuItem[];
}

interface HorizontalMenuItem {
  title: string;
  route: string;
}

export default function HorizontalMenu({ title, items }: HorizontalMenuProps) {
  return (
    <div className={`${styles.root} bg-light`}>
      <h2>{title}</h2>

      <nav className="nav flex-column">
        {
          items.map((element, index) => {
            return <NavLink to={element.route} className="nav-link" key={index}>{element.title}</NavLink>;
          })
        }
      </nav>
    </div>
  );
}
