import React, { useState } from 'react';
import { ValueType } from '@tasper/configurator-core';

interface ValueTypeDropdownProps {
  onChange?: (value: ValueType | undefined) => void;
  onBlur?: () => void;
  initialValue?: string;
  className?: string | undefined;
}
const dummyHandler: (value: ValueType | undefined) => void = () => { };

export default function ValueTypeDropdown({ onBlur, onChange = dummyHandler, initialValue, className }: ValueTypeDropdownProps) {
  const [valueType, setValueType] = useState(initialValue || '');
  const [disabled, setDisabled] = useState(initialValue !== '' && initialValue !== undefined);

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Backspace') {
      setValueType('');
      setDisabled(false);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled) {
      let newValue: ValueType;

      switch (e.currentTarget.value.toLowerCase()) {
        case 's':
          newValue = ValueType.String;
          break;
        case 'da':
          newValue = ValueType.DateTime;
          break;
        case 'i':
          newValue = ValueType.Int;
          break;
        case 'b':
          newValue = ValueType.Boolean;
          break;
        case 'de':
          newValue = ValueType.Decimal;
          break;
        case 'd':
          // Accept "d" for "da" and "de" inputs, return to prevent onChange trigger
          setValueType('d');
          onChange(undefined);
          return;
        default:
          // Default return, not permitted
          onChange(undefined);
          return;
      }

      setValueType(ValueType[newValue]);
      setDisabled(true);
      onChange(newValue);
    }
  }

  return (
    <input
      className={['form-control', className].filter(Boolean).join(' ')}
      value={valueType}
      type="text"
      onKeyDown={onKeyDown}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder="Type"
      id="typeDropdownInput"
    />
  );
}
