import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { AfasImport } from '../../api/models/AfasImport';
import { AfasSetting } from '../../api/models/AfasSetting';
import { getAfasSetting, createNewAfasSetting, updateAfasSetting } from '../../api/AfasSettingService';
import { IAfasImportPageComponent } from '../types';

interface ASProps extends IAfasImportPageComponent {
  afasImport: AfasImport;
}

export default function AfasSettingForm(props: ASProps) {
  const { handleSubmit, register, reset } = useForm<AfasSetting>();
  const onSubmit: SubmitHandler<AfasSetting> = submitForm;
  const [afasSId, setAfasSId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.afasImport.afasSettingId) {
      setIsLoading(true);

      getAfasSetting(props.afasImport.afasSettingId as number)
        .then(data => {
          reset(data);
          setAfasSId(data.id);
          setIsLoading(false);
        })
        .catch(() => toast.error('An error occured.'));
    }
  }, [props.afasImport, reset]);

  function submitForm(afasS: AfasSetting) {
    const toastId = toast.loading('Saving...');

    if (!afasSId) {
      // Create
      afasS.afasImportId = props.afasImport.id as number;

      createNewAfasSetting(afasS)
        .then(data => {
          setAfasSId(data.id);

          // Send new id to parent
          const updatedAfasImport = props.afasImport;
          updatedAfasImport.afasConfigurationId = data.id;
          props.updateAfasImport(updatedAfasImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving.', type: 'error', isLoading: false }));
    } else {
      // Update
      updateAfasSetting(afasS)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while creating.', type: 'error', isLoading: false }));
    }
  }

  function renderCheckBox(name: any, label: string) {
    return (
      <div className="form-check form-switch">
        <input aria-labelledby="primair" className="form-check-input" type="checkbox" {...register(name)} />
        <label className="form-check-label" id="primair">
          {' '}
          {label}
        </label>
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderCheckBox('useStartEffective', 'Use start effective')}
        {renderCheckBox('useEndEffective', 'Use end effective')}
        {renderCheckBox('ignoreEndEffectiveInCurrentPeriod', 'Ignore end effective in current period')}
        <button className="btn btn-success">Save</button>
      </form>
    );
}
