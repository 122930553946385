import React from 'react';
import ReactDOM from 'react-dom';

export interface ModalProps {
    open: boolean
    children?: React.ReactNode
    onClose: (action: string) => void
}
export const Modal = ({ open, children, onClose }: ModalProps) => {
    if (!open) return null;

    const handleClose: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
        const button = evt.target as HTMLButtonElement;
        if (onClose) onClose(button.name);
    };

    return ReactDOM.createPortal(<>
        <div style={{
            display: 'flex',
            position: 'fixed',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: 1000,
            top: 0, bottom: 0, left: 0, right: 0,
            backgroundColor: 'rgba(0,0,0,.7)',
            padding: '5%'
        }}>
            <div style={{
                flex: 'none',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgb(255,255,255)',
                maxWidth: '90vw',
                maxHeight: '90vh',
                padding: '.2em'
            }}>
                <header style={{ fontWeight: 500, backgroundColor: 'rgba(0,0,0,.03)', padding: '.3em', flex: 'none' }}>Workflow</header>
                <div style={{ padding: '1em', overflow: 'hidden', maxWidth: '100%', maxHeight: '100%' }}>
                    {children}
                </div>
                <div style={{ display: 'flex', flex: 'none', gap: '.3em', justifyContent: 'end', padding: '.5em .3em .3em', backgroundColor: 'rgba(0,0,0,.03)' }}>
                    <button type='button' name='cancel' className='btn btn-sm btn-secondary' onClick={handleClose}>Cancel</button>
                    <button type='button' name='save' className='btn btn-sm btn-primary' onClick={handleClose}>Save</button>
                </div>
            </div>
        </div>
    </>, document.body);
};

export default Modal;