import React, { useEffect, useState } from 'react';
import { CalculatedValue, getCalculatedValuesByClientId } from '@tasper/configurator-calculated-values';
import { FormSelectBoxItem, Loader } from '@tasper/configurator-core';
import { ListTypeInput } from './ListTypeInput';
import { BaseListTypeInputProps } from './BaseListTypeInputProps';

export default function CalculatedValueListTypeInput({ clientId, addToListItems }: BaseListTypeInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [calculatedValues, setCalculatedValues] = useState<CalculatedValue[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getCalculatedValuesByClientId(parseInt(clientId, 10)).then(data => {
      setCalculatedValues(data);
    });

    setIsLoading(false);
  }, [clientId]);

  function getData(): FormSelectBoxItem<string>[] {
    return calculatedValues.map(calculatedValue => { return { label: calculatedValue.code, value: calculatedValue.code }; });
  }

  return (
    isLoading
      ? <Loader />
      : (
        <ListTypeInput
          clientId={clientId}
          addToListItems={addToListItems}
          label="berekende waarde toevoegen aan de lijst"
          selectBoxItems={getData()}
          buttonText="ander berekende waarde toevoegen"
        />
      )
  );
}
