import { sendRequest } from '@tasper/configurator-core';
import { ProductMethod } from './models/ProductMethod';

const BASE_URL = 'api/ProductMethod';

export const createProductMethodes = (productConfigurationDefinitionId: number, productProperties: ProductMethod[]): Promise<ProductMethod> => {
  return sendRequest<ProductMethod>({
    method: 'POST',
    url: `${BASE_URL}/savebulk/${productConfigurationDefinitionId}`,
    content: JSON.stringify(productProperties),
  });
};

export const getProductMethodsByProductId = (productId: number): Promise<ProductMethod[]> => {
  return sendRequest<ProductMethod[]>({
    method: 'GET',
    url: `${BASE_URL}/GetByProductId/${productId}`,
  });
};
export const updateProductMethod = (productMethod: ProductMethod) => sendRequest({
  method: 'PUT',
  url: `${BASE_URL}/${productMethod.id}`,
  content: JSON.stringify(productMethod),
});

export const createProductMethod = (productMethod: ProductMethod): Promise<ProductMethod> => sendRequest<ProductMethod>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(productMethod),
});

export const deleteProductMethod = (productMethodId: number): Promise<ProductMethod> => sendRequest<ProductMethod>({
  method: 'DELETE',
  url: `${BASE_URL}/${productMethodId}`,
});

// eslint-disable-next-line max-len
export const getProductPropertyByProductIdAndProductConfigDefinitionMethodId = (productId: number, productConfigDefinitionMethodId: number): Promise<ProductMethod> => sendRequest<ProductMethod>(
  {
    method: 'GET',
    url: `${BASE_URL}/GetByProductIdAndProductConfigMethodId/${productId}/${productConfigDefinitionMethodId}`,
  },
);
