import React, { useEffect, useState } from 'react';
import { Item, getAllItems } from '@tasper/configurator-customer-data';
import { Loader, TabTableWithNewRow } from '@tasper/configurator-core';
import { getAllCsvMappingByImportId } from '../../api/CsvMappingService';
import { CsvMapping } from './types';
import CSVImportRow from './parts/CSVImportRow';

interface CSVImportTableProps {
  clientId: number;
  csvImportId: number;
  defaultDateFormat?: string;
  defaultBooleanFormat?: string;
}

export default function CsvImportTable(props: CSVImportTableProps) {
  const [itemOptions, setItemOptions] = useState<any[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllItems(props.clientId)
      .then(data => {
        const options = data.map(item => ({
          label: item.code,
          value: item.id,
        }));

        setItems(data);
        setItemOptions(options);
        setIsLoading(false);
      });
  }, [props.clientId]);

  const renderRow = (rowModel: CsvMapping, addNewRowEvent: () => void) => (
    <CSVImportRow
      onSave={addNewRowEvent}
      mapping={rowModel}
      clientId={props.clientId}
      csvImportId={props.csvImportId}
      itemsOptions={itemOptions}
      items={items}
      defaultDateFormat={props.defaultDateFormat}
      defaultBooleanFormat={props.defaultBooleanFormat}
    />
  );

  const getData = (): Promise<CsvMapping[]> => {
    return new Promise((resolve, reject) => {
      getAllCsvMappingByImportId(props.csvImportId)
        .then(data => {
          resolve(
            data.map(element => {
              return {
                id: element.id,
                columnNr: element.csvColumn,
                itemId: element.itemId,
                optional: element.optional,
                defaultValue: element.defaultValue,
                valueFormat: element.valueFormat,
              };
            }),
          );
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  return isLoading
    ? <Loader />
    : (
      <>
        <div className="row">
          <div className="col-1">Column</div>
          <div className="col-2">Itemcode</div>
          <div className="col-4">Type</div>
          <div className="col-2">Default value</div>
          <div className="col-1" />
          <div className="col-1" />
        </div>
        <TabTableWithNewRow<CsvMapping>
          emptyRow={{ isNew: true }}
          renderRow={renderRow}
          getData={getData}
        />
      </>
    );
}
