import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ImportOverview from './ImportOverview';
import EditImport from './EditImport';
import CreateImport from './CreateImport';

const ImportRouter = () => {
  return (
    <Routes>
      <Route path="" element={<ImportOverview />} />
      <Route path=":importId/config" element={<EditImport />} />
      <Route path="create" element={<CreateImport />} />
    </Routes>
  );
};

export default ImportRouter;
