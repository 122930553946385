import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getAllConstantsByType } from '@tasper/configurator-customer-data';
import { FormSelectBoxItem, ValueType, Loader } from '@tasper/configurator-core';
import { CsvPgpSetting } from '../../../api/models/CsvPgpSetting';
import { createNewCsvPgpSetting, getCsvPgpSetting, updateCsvPgpSetting } from '../../../api/CsvPgpSettingService';

interface CsvPgpSettingsProps {
  clientId: number;
  csvPgpSettingsId?: number | null;
  csvImportId: number;
  createdPgpSettingsEvent: (csvPgpSettingsId: number) => void;
}

interface CsvPgpSettingsFormModel {
  pgpPrivateKeyConstantId?: FormSelectBoxItem<number>;
  pgpPasswordConstantId?: FormSelectBoxItem<number>;
  pgpSkipConstantId?: FormSelectBoxItem<number>;
}

interface UpdateFormArgs {
  csvPgpSettings: CsvPgpSetting;
  stringConstantOptions: FormSelectBoxItem<number>[];
  booleanConstantsOptions: FormSelectBoxItem<number>[];
}

export default function CsvPgpSettings(props: CsvPgpSettingsProps) {
  const {
    control, handleSubmit, reset, formState: { errors },
  } = useForm<CsvPgpSettingsFormModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [csvPSId, setCsvPSId] = useState<number | undefined>();
  const [stringConstantOptions, setStringConstantOptions] = useState<any[]>([]);
  const [booleanConstantsOptions, setBooleanConstantOptions] = useState<any[]>([]);

  useEffect(() => {
    function updateForm({ csvPgpSettings, stringConstantOptions, booleanConstantsOptions }: UpdateFormArgs) {
      reset({
        pgpPrivateKeyConstantId: stringConstantOptions.find(constant => constant.value === csvPgpSettings.pgpPrivateKeyConstantId),
        pgpPasswordConstantId: stringConstantOptions.find(constant => constant.value === csvPgpSettings.pgpPasswordConstantId),
        pgpSkipConstantId: booleanConstantsOptions.find(constant => constant.value === csvPgpSettings.pgpSkipConstantId),
      });
    }

    const promiseStrings = getAllConstantsByType(props.clientId, ValueType.String);
    const promiseBools = getAllConstantsByType(props.clientId, ValueType.Boolean);

    Promise.all([promiseStrings, promiseBools])
      .then(data => {
        const stringData = data[0];
        const boolData = data[1];

        const stringConstantOptionsList = stringData.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));

        const boolConstantOptionsList = boolData.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));

        setStringConstantOptions(stringConstantOptionsList);
        setBooleanConstantOptions(boolConstantOptionsList);

        if (props.csvPgpSettingsId) {
          getCsvPgpSetting(props.csvPgpSettingsId)
            .then(csvPgpSettings => {
              updateForm({
                csvPgpSettings,
                stringConstantOptions: stringConstantOptionsList as FormSelectBoxItem<number>[],
                booleanConstantsOptions: boolConstantOptionsList as FormSelectBoxItem<number>[],
              });
              setCsvPSId(csvPgpSettings.id);
              setIsLoading(false);
            })
            .catch(() => {
              toast.error('An error occured');
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error('An error occured');
      });
  }, [props.clientId, props.csvPgpSettingsId, reset]);

  function convertFormToModel(form: CsvPgpSettingsFormModel): CsvPgpSetting {
    const obj: CsvPgpSetting = {
      csvImportId: props.csvImportId as number,
      pgpPasswordConstantId: form.pgpPasswordConstantId!.value,
      pgpPrivateKeyConstantId: form.pgpPrivateKeyConstantId!.value,
      pgpSkipConstantId: form.pgpSkipConstantId!.value,
    };

    if (csvPSId !== undefined) obj.id = csvPSId;

    return obj;
  }

  const onSubmit: SubmitHandler<CsvPgpSettingsFormModel> = function submitForm(csvPgpSettingsModel: CsvPgpSettingsFormModel) {
    const toastId = toast.loading('Saving...');
    const model = convertFormToModel(csvPgpSettingsModel);

    if (!model.id) {
      // Create

      createNewCsvPgpSetting(model)
        .then(data => {
          setCsvPSId(data.id);

          // Send id to parent
          if (data.id) props.createdPgpSettingsEvent(data.id);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    } else {
      // Update
      updateCsvPgpSetting(model)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating', type: 'error', isLoading: false }));
    }
  };

  function selectRender(name: keyof CsvPgpSettingsFormModel, label: string, constantOptions: FormSelectBoxItem<number>[]) {
    return (
      <div className="form-group">
        <label>
          {label}
          {' '}
          constante
        </label>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={
            ({ field }) => (
              <Select
                {...field}
                options={constantOptions}
                placeholder="Choose a constant"
              />
            )
          }
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
        <br />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectRender('pgpPrivateKeyConstantId', 'Private Key', stringConstantOptions)}
        {selectRender('pgpPasswordConstantId', 'Password', stringConstantOptions)}
        {selectRender('pgpSkipConstantId', 'Skip', booleanConstantsOptions)}
        <button type="submit" className="btn btn-success">Save</button>
      </form>
    );
}
