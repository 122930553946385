export enum ListType {
  Unkown = '',
  Budgets = 'Budgets',
  Products = 'Products',
  BasItems = 'BasItems',
  Constants = 'Constants',
  CalculatedValues = 'CalculatedValues',
}

export default ListType;
