import { FormGroup } from '@tasper/configurator-core';
import React, { useEffect, useState } from 'react';
import { getProductPropertiesByProductId } from '../../../api/ProductPropertyService';
import { ProductProperty } from '../../../api/models/ProductProperty';
import { PropertyType } from '../../../types';
import RenderBasItemInput from './parts/RenderBasItemInput';
import RenderBudgetInput from './parts/RenderBugdetInput';
import RenderCalculatedValueInput from './parts/RenderCalculatedValueInput';
import RenderConstantInput from './parts/RenderConstantInput';
import RenderListsInput from './parts/RenderListsInput';
import RenderValueInput from './parts/RenderValueInput';

interface ProductPropertiesMappingFormProps {
  productId: number;
  productCode: string;
  clientId: string;
}

export default function ProductPropertiesMappingForm({ clientId, productCode, productId }: ProductPropertiesMappingFormProps) {
  const [productProperties, setProductProperties] = useState<ProductProperty[]>([]);
  useEffect(() => {
    getProductPropertiesByProductId(productId).then(data => {
      if (data !== undefined) {
        setProductProperties(data);
      }
    });
  }, [productId]);

  function renderInput(productProperty: ProductProperty, propertyType?: PropertyType, value?: string) {
    switch (propertyType) {
      case PropertyType.list:
        return (<RenderListsInput productProperty={productProperty} clientId={clientId} value={value} />);

      case PropertyType.productCode:
        return (<div className="row"><div className="col-5"><input className="form-control" value={productCode} disabled /></div></div>);
      case PropertyType.childProduct:
        return (<div className="row"><div className="col-5"><input className="form-control" value={productCode} disabled /></div></div>);
      case PropertyType.budget:
        return (<RenderBudgetInput productProperty={productProperty} clientId={clientId} value={value} />);
      case PropertyType.basItem:
        return (<RenderBasItemInput productProperty={productProperty} clientId={clientId} value={value} />);
      case PropertyType.constant:
        return (<RenderConstantInput productProperty={productProperty} clientId={clientId} value={value} />);
      case PropertyType.calculatedValue:
        return (<RenderCalculatedValueInput productProperty={productProperty} clientId={clientId} value={value} />);
      case PropertyType.value:
        return (<RenderValueInput productProperty={productProperty} currentValue={value} />);
      default:
        return (
          <div>
            Geen type gevonden met naam:
            {' '}
            {propertyType}
          </div>
        );
    }
  }

  return (
    <div>
      {productProperties.map(property => {
        return (
          <div key={property.id}>
            <FormGroup>
              <label>{property.productConfigDefinitionProperty?.propertyCode}</label>
              {renderInput(property, property.productConfigDefinitionProperty?.propertyType, property.value)}
            </FormGroup>
          </div>
        );
      })}
    </div>
  );
}
