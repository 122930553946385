import { ToastOptions } from 'react-toastify';

export interface FormSelectBoxItem<T>{
  value: T;
  label: string;
}

export const toastOptions: ToastOptions = {
  autoClose: 3000,
  position: 'bottom-right',
  pauseOnHover: true,
  hideProgressBar: false,
};

/*
 * const getUserData = async (uid: string): Promise<{ name: string, email: string }> =>
 *     Promise.resolve<any>({ #placeholder#  });
 * 
 * type T1 = AsyncReturnType<typeof getUserData>
*/
export type AsyncReturnType<T extends (...args: any) => Promise<any>> =
    T extends (...args: any) => Promise<infer R> ? R : any
