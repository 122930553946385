import { FormGroup } from '@tasper/configurator-core';
import React, { useState, useEffect } from 'react';
import { getProductModelValueByProductIdAndProductDefinitionId, createProductModelValue, deleteProductModelValue } from '../../../api/ProductModelValueService';
import { ProductModelValue } from '../../../api/models/ProductModelValue';
import { PropertyType } from '../../../types';

interface RenderCheckboxProps {
  mandatory: boolean;
  entityId: number;
  code: string;
  propertyType: PropertyType;
  productId: number;
}

// TODO merge with other RenderCheckBox
export default function RenderCheckBox({
  code, entityId, mandatory, productId, propertyType,
}: RenderCheckboxProps) {
  const [checked, setChecked] = useState(mandatory);

  const [productModelValue, setProductModelValue] = useState<ProductModelValue | undefined>();
  const [disabled, setDisabled] = useState(mandatory);

  useEffect(() => {
    if (propertyType === PropertyType.childProduct) {
      setDisabled(true);
    }

    if (!mandatory) {
      getProductModelValueByProductIdAndProductDefinitionId(productId, entityId).then(productMethod => {
        if (productMethod) {
          setProductModelValue(productMethod);
          setChecked(true);
        }
      });
    }
  }, [entityId, mandatory, propertyType]);

  function handleChange(value: boolean) {
    if (value && !productModelValue) {
      setChecked(value);
      createProductModelValue({ productId, productDefinitionModelId: entityId }).then(data => {
        setProductModelValue(data);
      });
    }

    if (!value && productModelValue?.id) {
      setChecked(value);
      deleteProductModelValue(productModelValue.id).then(() => {
        setProductModelValue(undefined);
      });
    }
  }

  return (
    <FormGroup>
      <label>{code}</label>
      <input type="checkbox" disabled={disabled} className="form-check-input" checked={checked} onChange={() => handleChange(!checked)} />
    </FormGroup>
  );
}
