import { ValueType, sendRequest } from '@tasper/configurator-core';
import { Constant } from './models/Constant';

const BASE_URL = 'api/constant';

export const getAllConstants = (clientId : number) : Promise<Constant[]> => sendRequest<Constant[]>({
  method: 'GET',
  url: `${BASE_URL}/List/${clientId}`,
});

export const getAllConstantsByType = (clientId: number, type: ValueType) : Promise<Constant[]> => sendRequest<Constant[]>({
  method: 'GET',
  url: `${BASE_URL}/GetByValueType/${clientId}/${type}`,
});

export const createNewConstant = (constant: Constant) : Promise<Constant> => sendRequest<Constant>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(constant),
});

export const changeConstant = (constant : Constant) : Promise<Constant> => sendRequest<Constant>({
  method: 'PUT',
  url: `${BASE_URL}/${constant.id}`,
  content: JSON.stringify(constant),
});

export const deleteConstant = (id: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${id}`,
});
