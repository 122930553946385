import { sendRequest } from '@tasper/configurator-core';
import { AfasMapping } from './models/AfasMapping';

const BASE_URL = 'api/AfasMapping';

export const getAllAfasMappingByImportId = (afasImportId: number) : Promise<AfasMapping[]> => sendRequest<AfasMapping[]>({
  method: 'GET',
  url: `${BASE_URL}/GetByAfasImportId/${afasImportId}`,
});

export const createNewAfasMapping = (mapping: AfasMapping) : Promise<AfasMapping> => sendRequest<AfasMapping>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(mapping),
});

export const updateAfasMapping = (mapping: AfasMapping) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${mapping.id}`,
  content: JSON.stringify(mapping),
});

export const deleteAfasMapping = (id: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${id}`,
});
