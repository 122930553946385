import React from 'react';
import { ValueType } from '../api/models/ValueType';

interface ValueTypeInputProps {
  valueType?: ValueType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCheckbox?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  value: string;
  className?: string;
  classNameCheck?: string;
  disabled?: boolean;
}

export default function ValueTypeInput({
  onBlur, onChange, onChangeCheckbox, value, className, classNameCheck, disabled, valueType,
}: ValueTypeInputProps) {
  function disabledInput() {
    return <input disabled className={className} />;
  }

  if (disabled) {
    return disabledInput();
  }

  function handleOnBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.checkValidity()) onBlur !== undefined && onBlur();
    else e.target.reportValidity();
  }

  switch (valueType) {
    case ValueType.String:
      return <input type="text" onBlur={handleOnBlur} onChange={onChange} value={value} className={className} />;
    case ValueType.DateTime:
      return <input type="date" onBlur={handleOnBlur} onChange={onChange} value={value} className={className} />;
    case ValueType.Int:
      return <input type="number" step="1" onBlur={handleOnBlur} onChange={onChange} value={value} className={className} />;
    case ValueType.Decimal:
      return <input type="number" step="any" onBlur={handleOnBlur} onChange={onChange} value={value} className={className} />;
    case ValueType.Boolean:
      return (
        <div className={classNameCheck}>
          <input className="form-check-input" type="checkbox" onBlur={handleOnBlur} onChange={onChangeCheckbox} defaultChecked={value === 'true'} />
          <label className="form-check-label"> True</label>
        </div>
      );
    default:
      return disabledInput();
  }
}
