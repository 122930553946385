import React from 'react';
import { useParams } from 'react-router-dom';
import CalculatedValueTable from './parts/CalculatedValueTable';

export default function CalculatedValueOverview() {
  const { clientId = '' } = useParams();

  return (
    <>
      <h1>Calculated values</h1>
      <CalculatedValueTable clientId={parseInt(clientId)} />
    </>
  );
}
