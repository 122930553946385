import React from 'react';
import { ToastContainer } from 'react-toastify';
import { toastOptions } from '@tasper/configurator-core';
import Menu from './Menu/Menu';
import './Layout.scss';

export default function Layout({ children }: React.PropsWithChildren<unknown>) {
  return (
    <>
      <Menu
        titleLogo="Tasper Configuration"
        items={[
          { title: 'Customers', route: '/clients' },
          { title: 'Templates', route: '/templates' },
        ]}
      />
      {children}
      <ToastContainer
        {...toastOptions}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
}
