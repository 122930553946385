import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BudgetOverview from './BudgetOverview';
import CreateAndEditBudget from './CreateAndEditBudget';

const BudgetRouter = () => {
  return (
    <Routes>
      <Route path="" element={<BudgetOverview />} />
      <Route path="create" element={<CreateAndEditBudget />} />
      <Route path="createchild/:budgetId" element={<CreateAndEditBudget />} />
    </Routes>
  );
}; // TODO duplicate route?

export default BudgetRouter;
