import { sendRequest } from '@tasper/configurator-core';
import { Product } from './models/Product';

const BASE_URL = 'api/Product';

export const getProductById = (productId: number): Promise<Product> => sendRequest<Product>({
  method: 'GET',
  url: `${BASE_URL}/${productId}`,
});

export const getProducts = (clientId: number): Promise<Product[]> => sendRequest<Product[]>({
  method: 'GET',
  url: `${BASE_URL}/list/${clientId}`,
});

export const getProductsByClientId = (clientId: number): Promise<Product[]> => sendRequest<Product[]>({
  method: 'GET',
  url: `${BASE_URL}/list/${clientId}`,
});

export const createProduct = (productModel : Partial<Product>) : Promise<Product> => {
  return sendRequest<Product>({
    method: 'POST',
    url: `${BASE_URL}/CreateByCreateProductModel`,
    content: JSON.stringify(productModel),
  });
};
