import { FormGroup } from '@tasper/configurator-core';
import React, { useEffect, useState } from 'react';
import { getProductModelValueByProductId } from '../../../api/ProductModelValueService';
import { ProductModelValue } from '../../../api/models/ProductModelValue';
import { PropertyType } from '../../../types';
import RenderBasItemInput from './parts/RenderBasItemInput';
import RenderBudgetInput from './parts/RenderBugdetInput';
import RenderCalculatedValueInput from './parts/RenderCalculatedValueInput';
import RenderConstantInput from './parts/RenderConstantInput';
import RenderListsInput from './parts/RenderListsInput';
import RenderValueInput from './parts/RenderValueInput';

interface ProductPropertiesMappingFormProps {
  productId: number;
  productCode: string;
  clientId: string;
  parentCode?: string;
}

export default function ProductDefinitionValueMappingForm({
  productCode, clientId, productId, parentCode,
}: ProductPropertiesMappingFormProps) {
  const [productModelValues, setProductModelValues] = useState<ProductModelValue[]>([]);
  useEffect(() => {
    getProductModelValueByProductId(productId).then(data => {
      if (data !== undefined) {
        setProductModelValues(data);
      }
    });
  }, [productId]);

  function renderInput(productModelValue: ProductModelValue, propertyType?: PropertyType, value?: string) {
    switch (propertyType) {
      case PropertyType.list:
        return (<RenderListsInput productModelValue={productModelValue} clientId={clientId} value={value} />);
      case PropertyType.productCode:
        return (<div className="row"><div className="col-5"><input className="form-control" value={productCode} disabled /></div></div>);
      case PropertyType.childProduct:
        return (<div className="row"><div className="col-5"><input className="form-control" value={parentCode} disabled /></div></div>);
      case PropertyType.budget:
        return (<RenderBudgetInput productModelValue={productModelValue} clientId={clientId} value={value} />);
      case PropertyType.basItem:
        return (<RenderBasItemInput productModelValue={productModelValue} clientId={clientId} value={value} />);
      case PropertyType.constant:
        return (<RenderConstantInput productModelValue={productModelValue} clientId={clientId} value={value} />);
      case PropertyType.calculatedValue:
        return (<RenderCalculatedValueInput productModelValue={productModelValue} clientId={clientId} value={value} />);
      case PropertyType.value:
        return (<RenderValueInput productModelValue={productModelValue} currentValue={value} />);
      default:
        return (
          <div>
            Geen type gevonden met nummer:
            {' '}
            {propertyType}
          </div>
        );
    }
  }

  return (
    <div>
      {
        productModelValues.map((productModelValue, index) => {
          return (
            <div key={index}>
              <FormGroup>
                <label>{productModelValue.productDefinitionModel?.code}</label>
                {renderInput(productModelValue, productModelValue.productDefinitionModel?.propertyType, productModelValue.value)}
              </FormGroup>
            </div>
          );
        })
      }
    </div>
  );
}
