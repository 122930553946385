import React, { useEffect, useState } from 'react';
import { ProductMethod } from '../../../../api/models/ProductMethod';
import TabElement from './TabElement';

export interface EditingItem {
  label: string;
  data: ProductMethod;
  dirty: boolean;
}

export default function ItemTabs({ onItemSelected, items, children }: { onItemSelected: (item: EditingItem) => void; items: EditingItem[]; children: any }) {
  const [activeTab, setActiveTab] = useState<string>('');

  function onClickTabItem(item: EditingItem) {
    return (tab: any) => {
      setActiveTab(tab);
      if (onItemSelected) {
        onItemSelected(item);
      } else {
        console.warn('onItemSelected is not set.');
      }
    };
  }

  useEffect(() => {
    if (!activeTab && !!items) {
      setActiveTab(items[0]?.label);
    }
  }, [items, activeTab]);

  return (
    <div className="tabs">
      <ol className="tab-list">
        {items.map((item: EditingItem) => {
          const { label } = item;
          return (
            <TabElement
              activeTab={activeTab}
              key={label}
              label={label}
              // itemState={item.dirty ? 'dirty' : 'unchanged'}
              onClick={onClickTabItem(item)}
            />
          );
        })}
      </ol>
      <div className="tab-content">
        {items ? children : (<div>No content</div>)}
      </div>
    </div>
  );
}
