import React, { useEffect, useState } from 'react';
import { FormSelectBoxItem, Loader } from '@tasper/configurator-core';
import { getAllItems, Item } from '@tasper/configurator-customer-data';
import { ListTypeInput } from './ListTypeInput';
import { BaseListTypeInputProps } from './BaseListTypeInputProps';

export default function ItemListTypeInput({ addToListItems, clientId }: BaseListTypeInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getAllItems(Number(clientId)).then(data => {
      setItems(data);
    });

    setIsLoading(false);
  }, [clientId]);

  function getData(): FormSelectBoxItem<string>[] {
    return items.map(item => { return { label: item.code, value: item.code }; });
  }

  return (
    isLoading
      ? <Loader />
      : <ListTypeInput clientId={clientId} addToListItems={addToListItems} label="items toevoegen aan de lijst" selectBoxItems={getData()} buttonText="ander item toevoegen" />
  );
}
