import { sendRequest } from '@tasper/configurator-core';
import { CsvprimarySetting } from './models/CsvprimarySetting';

const BASE_URL = 'api/CsvprimarySetting';

export const createNewCsvPrimarySetting = (csvprimarySetting: CsvprimarySetting) : Promise<CsvprimarySetting> => sendRequest<CsvprimarySetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(csvprimarySetting),
});

export const updateCsvPrimarySetting = (csvprimarySetting: CsvprimarySetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${csvprimarySetting.id}`,
  content: JSON.stringify(csvprimarySetting),
});

export const getCsvPrimarySetting = (csvprimarySettingId: number) : Promise<CsvprimarySetting> => sendRequest<CsvprimarySetting>({
  method: 'GET',
  url: `${BASE_URL}/${csvprimarySettingId}`,
});
