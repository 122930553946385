import React, { useState } from 'react';
import { ProductModelValue } from '../../../../api/models/ProductModelValue';
import { updateProductModelValue } from '../../../../api/ProductModelValueService';

interface RenderValueInputProps {
  productModelValue: ProductModelValue;
  currentValue: string | undefined;
}
export default function RenderValueInput({ productModelValue, currentValue }: RenderValueInputProps) {
  const [value, setValue] = useState(currentValue);

  function handleOnBlur() {
    updateProductModelValue(Object.assign(productModelValue, { value })).then(() => {
    });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }
  return (
    <div className="row">
      <div className="col-5">
        <input className="form-control" value={value} onBlur={handleOnBlur} onChange={handleChange} />
      </div>
    </div>
  );
}
