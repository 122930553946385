import React, { Fragment, useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { Product, getProductsByClientId } from '@tasper/configurator-products';
import { MenuItem } from '../api/models/MenuItem';
import styles from './index.module.scss';
import { createMenuItem } from '../api/MenuService';

export function MenuItemPage() {
  const { clientId = '', categoryId } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm<MenuItem>({
    defaultValues: {
      categoryId: Number(categoryId),
    },
  });
  const history = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getProductsByClientId(parseInt(clientId, 10)).then(data => {
      setProducts(data);
    });
    setIsLoading(false);
  }, [clientId]);

  const onSubmit: SubmitHandler<MenuItem> = function submitForm(menuItem: MenuItem) {
    createMenuItem(menuItem)
      .then(() => {
        <Navigate to={`/configuratie/${clientId}/menu/`} />;
        toast.success('menu item is opgeslagen!');
      })
      .catch(() => {
        toast.error('menu item is opgeslagen!');
      });
  };

  return (
    <div>
      {
        isLoading ? (
          <div>
            <Loader />
            {' '}
          </div>
        )
          : (
            <div>

              <form className={styles.max_width} onSubmit={handleSubmit(onSubmit)}>

                <div>
                  <div className="row form-group">
                    <div className="col-sm-6">
                      <label id="product-code-label">Product</label>
                      <select aria-labelledby="translation-label" className="form-control" {...register('productId', { required: true })}>
                        {products.map((product, index) => {
                          return (
                            <Fragment key={index}>
                              <option value={product.id}>{product.code}</option>
                            </Fragment>
                          );
                        })}
                      </select>
                    </div>
                    {errors.productId && <span>Het product is verplicht!</span>}
                  </div>
                </div>
                <div>
                  <button type="button" className="btn btn-primary mb-2 float-end" onClick={() => history(1)}>Ga Terug</button>
                  <input className="btn btn-primary mb-2" type="submit" value="Opslaan" />
                </div>
              </form>
            </div>
          )
      }
    </div>
  );
}

export default MenuItemPage;
