import React from 'react';
/*
import { Tree } from '@tasper/configurator-core';

const FolderIcon = () => (
    <i className="bi bi-folder"></i>
);

const FileIcon = ({ type }: { type: string }) => (
    <i className="bi bi-file" title={type}></i>
);
*/
export function Dashboard() {
    return (
        <div>
            <span>Welcome to the dashboard.</span>
            {/*
            <h3>Generated file(s)</h3>
            <Tree>
                <Tree.Folder name="src" folderIcon={FolderIcon}>
                    <Tree.Folder name="Components" folderIcon={FolderIcon}>
                        <Tree.File name="Modal.js" icon={FileIcon} />
                        <Tree.File name="Modal.css" icon={FileIcon} />
                    </Tree.Folder>
                    <Tree.File name="index.js" icon={FileIcon} />
                    <Tree.File name="index.html" icon={FileIcon} />
                </Tree.Folder>
                <Tree.File name="package.json" icon={FileIcon} />
            </Tree>
             */}
        </div>
    );
}

export default Dashboard;
