import { useState, useEffect } from "react";

export type ApiMembers<R, Arg, Args extends Arg[]> = {
    data: R | undefined
    error: Error | undefined
    isLoading: boolean
    fetch: (...args: [...Args]) => void
    refresh: () => void
};

// type Head<Arg, T extends Arg[]> =
//     T extends [Arg, ...any[]]
//     ? T[0]
//     : never;

// type FunctionInfer<F> =
//     F extends (...args: infer A) => infer R
//     ? [A, Promise<R>]
//     : never;

// type ArrayInfer<T> =
//     T extends (infer U)[]
//     ? U
//     : never;

type ApiOptions<R, Arg, Args extends Arg[]> =
    | {
        fetchStraightway?: false,
        dataHandler?: (data: R) => void
    }
    | {
        fetchStraightway: true
        initialArgs: Args
        dataHandler?: (data: R) => void
    }
/*
export type ApiMembers<R, Arg, Args extends Arg[]> = [
    data: R | undefined,
    error: Error | undefined,
    isLoading: boolean,
    fetch: (...args: [...Args]) => void,
    refresh: () => void,
]
*/
export default function useApi<R, Arg, Args extends Arg[]>(apiCall: (...args: [...Args]) => Promise<R>, options?: ApiOptions<R, Arg, Args>): ApiMembers<R, Arg, Args> {
    const [isLoading, setIsloading] = useState(false);
    const [data, setData] = useState<R>();
    const [error, setError] = useState<Error>();
    const [requestArgs, setRequestArgs] = useState<[...Args] | undefined>();
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setError(undefined);
        if (requestArgs) {
            setIsloading(true);
            apiCall(...requestArgs)
                .then(data => options?.dataHandler ? options.dataHandler(data) : setData(data))
                .catch((error: Error) => setError(error))
                .finally(() => setIsloading(false));
        }
    }, [trigger, requestArgs]);

    /* todo: 
     *   currying and partial apply arguments based on initialArgs
     *   current solution is to pass a partially applied function as apiCall argument 
     */
    const fetch = (...args: [...Args]) => setRequestArgs(args);

    const refresh = () => setTrigger(state => !state);

    return {
        data,
        error,
        isLoading,
        fetch,
        refresh
    };
}
