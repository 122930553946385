import { sendRequest } from '@tasper/configurator-core';
import { ListItem } from './models/ListItem';
import { ListForConstant } from './models/ListForConstant';

const BASE_URL = 'api/ListForConstant';

// TODO comments weghalen als de API end-points er zijn

export const getListsByClientId = (clientId: string): Promise<ListForConstant[]> => sendRequest<ListForConstant[]>({
  method: 'GET',
  url: `${BASE_URL}/List/${clientId}`,
});

export const createListForConstant = (item: ListForConstant): Promise<ListForConstant> => sendRequest<ListForConstant>({
  method: 'POST',
  url: `${BASE_URL}`,
  content: JSON.stringify(item),
});

export const createListItem = (item: ListItem): Promise<ListItem> => {
  return new Promise<ListItem>(resolve => {
    resolve(item);
  });

  // sendRequest<MenuItem>({
  //  method: "POST",
  //  url: `${BASE_URL}/${id}`,
  //  content: JSON.stringify(item)
  // });
};

export const deleteListItem = (listItemId: number): Promise<number> => {
  return new Promise(resolve => {
    resolve(listItemId);
  });

  // sendRequest<void>({
  //  method: "DELETE",
  //  url: `${BASE_URL}/${listItemId}`
  // });
};

export const deleteListForConstant = (listForConstantId: number): Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${listForConstantId}`,
});
