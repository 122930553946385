import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { Loader } from '@tasper/configurator-core';
import { getMenuByClientId, deleteMenuCategory } from '../api/MenuService';
import { MenuCategory } from '../api/models/MenuCategory';

export function MenuOverview() {
  const { clientId = '' } = useParams();
  const [menuCategories, setMenuCategories] = useState<MenuCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getMenuByClientId(parseInt(clientId, 10)).then(result => {
      setMenuCategories(result);
      setIsLoading(false);
    });
  }, [clientId]);

  function deleteMenuEvent(menuCategoryId: number) {
    if (window.confirm('Are you sure you want to delete this category and possible menu items?')) {
      deleteMenuCategory(menuCategoryId).then(() => { // TODO request niet meer mocken
        const newList = menuCategories.filter(menuCategory => menuCategory.id !== menuCategoryId);
        setMenuCategories(newList);
      });
    }
  }

  // Deze moet later wordern toegegevoed als de entpoints ook bestaan!
  // function deleteMenuItemEvent(menuCategoryId: number, menuItemId: number){
  //     if(window.confirm("Weet je zeker dat je dit menu item wil verwijderen?")){
  //         deleteMenuItem(menuCategoryId).then(function () {//TODO request niet meer mocken
  //             let menuCategory = menuCategories.find((menuCategory) => menuCategory.id === menuCategoryId);

  //             if(menuCategory){
  //                 let tempMenuCategories = menuCategories.filter((category) => category.id !== menuCategory?.id);
  //                 menuCategory.menuItems = menuCategory.menuItems.filter((menuItem) => menuItem.id !== menuItemId);

  //                 tempMenuCategories.push(menuCategory);
  //                 setMenuCategories(tempMenuCategories);
  //             }
  //         });
  //     }
  // }

  return (
    <div>
      {isLoading
        ? <div><Loader /></div>
        : (
          <div className="row">
            <NavLink to={`/configuratie/${clientId}/menu/create/`}>Create menu</NavLink>
            <div>
              <ol className="tree-structure">
                {
                  menuCategories.map((menuCategory, key) => {
                    return (
                      <li key={key}>
                        <Link to="/">
                          <span className="num">{menuCategory.code}</span>
                          (
                          {menuCategory.nameNL}
                          ,
                          {' '}
                          {menuCategory.nameEN}
                          )
                        </Link>
                        <button type="button" onClick={() => deleteMenuEvent(menuCategory.id)}>Delete</button>
                        <ol>
                          {
                            menuCategory.menuItems.map((menuItem, x) => {
                              return (
                                <li key={x}>
                                  <Link to="/">
                                    <span className="num">{menuItem.product?.code}</span>
                                  </Link>
                                </li>
                              );
                            })
                          }
                        </ol>
                      </li>
                    );
                  })
                }
              </ol>
            </div>

          </div>
        )}
    </div>
  );
}

export default MenuOverview;
