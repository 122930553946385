import React, { MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@tasper/configurator-core';
import { getClients, deleteClient } from '../api/CustomerService';
import { Customer } from '../api/models/Customer';
import styles from '../../../../src/Tables.module.scss';
import style from './index.module.scss';
import useApi from '@tasper/configurator-core/hooks/useApi';

export default function ClientOverview() {
    const [clients, setClients] = useState([] as Customer[]);

    const { isLoading, fetch } = useApi(getClients, {
        dataHandler: setClients,
    });

    useEffect(() => fetch(), []);

    function handleDelete(evt: MouseEvent<HTMLDivElement>) {
        if (!(evt.target instanceof HTMLButtonElement)) {
            return;
        }
        const button = evt.target as HTMLButtonElement;
        const clientId: number = +button.value;

        if (button.name !== 'delete' || !clientId) return;

        if (window.confirm(`Are you sure you want to delete this customer? ${clientId}`)) {
            deleteClient(clientId).then(() => {
                const newList = clients.filter(client => client.id !== clientId);
                setClients(newList);
            });
        }
    }

    return isLoading
        ? <Loader />
        : (
            <>
                <div><Link className="btn btn-success" to="/clients/create">New Customer</Link></div>
                <div className="container overflow-hidden">
                    <div className={`${styles.row} row`}>
                        <div className={`col-3 ${styles.table_head_col}`}>Customer name</div>
                        <div className={`col-3 ${styles.table_head_col}`}>Project name</div>
                        <div className={`col-3 ${styles.table_head_col}`}>Namespace</div>
                        <div className={`col-3 ${styles.table_head_col}`} />
                    </div>
                </div>
                <div className={`${style['table-body']}`} onClick={handleDelete}>
                    <div className="container">
                        {clients.map((client, i) => {
                            return (
                                <div className={`${styles.row} row`} key={i}>
                                    <div className={`col-3 ${styles.table_col}`}>{client.klantnaam}</div>
                                    <div className={`col-3 ${styles.table_col}`}>{client.projectnaam}</div>
                                    <div className={`col-3 ${styles.table_col}`}>{client.namespace}</div>
                                    <div className={`col-3 ${styles.table_col}`}>
                                        <div className='hstack gap-2 justify-content-end'>
                                            <Link className="btn btn-sm btn-primary" to={`/configuratie/${client.id}`}>Configuration</Link>
                                            <Link className="btn btn-sm btn-primary" to={`/clients/update/${client.id}`}>Edit</Link>
                                            <button type="button" className="btn btn-sm btn-danger" name="delete" value={client.id}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
}
