import { sendRequest } from '@tasper/configurator-core';
import { ProductConfigurationDefinition } from './models/ProductConfigurationDefinition';

const BASE_URL = 'api/ProductConfigDefinition/';

export const getAllProductConfigurationDefinitions = (): Promise<ProductConfigurationDefinition[]> => sendRequest<ProductConfigurationDefinition[]>({
  method: 'GET',
  url: `${BASE_URL}/List/`,
});

export default undefined;
