import { sendRequest } from '@tasper/configurator-core';
import { CsvSetting } from './models/CsvSetting';

const BASE_URL = 'api/CsvSetting';

export const createNewCsvSetting = (csvSetting: CsvSetting) : Promise<CsvSetting> => sendRequest<CsvSetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(csvSetting),
});

export const updateCsvSetting = (csvSetting: CsvSetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${csvSetting.id}`,
  content: JSON.stringify(csvSetting),
});

export const getCsvSetting = (csvSettingId: number) : Promise<CsvSetting> => sendRequest<CsvSetting>({
  method: 'GET',
  url: `${BASE_URL}/${csvSettingId}`,
});
