import { sendRequest } from '@tasper/configurator-core';
import { AfasSetting } from './models/AfasSetting';

const BASE_URL = 'api/AfasSetting';

export const createNewAfasSetting = (afasSetting: AfasSetting) : Promise<AfasSetting> => sendRequest<AfasSetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(afasSetting),
});

export const updateAfasSetting = (afasSetting: AfasSetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${afasSetting.id}`,
  content: JSON.stringify(afasSetting),
});

export const getAfasSetting = (afasSettingId: number) : Promise<AfasSetting> => sendRequest<AfasSetting>({
  method: 'GET',
  url: `${BASE_URL}/${afasSettingId}`,
});
