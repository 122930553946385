import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { createNewCsvPrimarySetting, getCsvPrimarySetting, updateCsvPrimarySetting } from '../../api/CsvprimarySettingService';
import { ICsvImportPageComponent } from '../types';
import { CsvprimarySetting } from '../../api/models/CsvprimarySetting';
import { Csvimport } from '../../api/models/Csvimport';

interface CsvPrimarySettingProps extends ICsvImportPageComponent {
  csvImport: Csvimport;
  updateCsvImport: (csvImport: Csvimport) => void;
}

export default function CsvPrimarySettingForm(props: CsvPrimarySettingProps) {
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm<CsvprimarySetting>();
  const [csvSId, setCsvSId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.csvImport.csvprimarySettingId) {
      setIsLoading(true);

      getCsvPrimarySetting(props.csvImport.csvprimarySettingId as number)
        .then(data => {
          reset(data);
          setCsvSId(data.id);
          setIsLoading(false);
        })
        .catch(() => toast.error('An error occured'));
    }
  }, [props.csvImport, reset]);

  function renderNumericInput(name: keyof CsvprimarySetting, label: string) {
    return (
      <div className="form-group">
        <label>
          {label}
          {' '}
          column number
        </label>
        <input
          className="form-control"
          type="number"
          min="0"
          step="1"
          required
          {...register(name)}
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required"
          name={name}
        />
      </div>
    );
  }

  const onSubmit: SubmitHandler<CsvprimarySetting> = function submitForm(csvForm: CsvprimarySetting) {
    const toastId = toast.loading('Opslaan...');

    if (!csvSId) {
      // Create
      csvForm.csvImportId = props.csvImport.id as number;

      createNewCsvPrimarySetting(csvForm)
        .then(data => {
          setCsvSId(data.id);

          // Send new id to parent
          const updatedCsvImport = props.csvImport;
          updatedCsvImport.csvprimarySettingId = data.id;
          props.updateCsvImport(updatedCsvImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    } else {
      // Update
      updateCsvPrimarySetting(csvForm)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating', type: 'error', isLoading: false }));
    }
  };

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderNumericInput('personeelsnummerColumn', 'Staff number')}
        {renderNumericInput('emailColumn', 'Email')}
        {renderNumericInput('usernameColumn', 'Username')}
        {renderNumericInput('nameColumn', 'Name')}
        {renderNumericInput('didColumn', 'DID')}
        {renderNumericInput('dudColumn', 'DUD')}
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    );
}
