import React, { useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Budget } from '../api/models/Budget';
import styles from './index.module.scss';
import { createBudget } from '../api/BudgetService';

export default function CreateAndEditBudget() {
  const { clientId, budgetId } = useParams();
  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm<Budget>({
    defaultValues: {
      budgetTranslations: [{ text: '', cultureId: 1, descriptionText: '' }], // TODO cultures staan nog niet vast!
    },
  });

  const [redirectBack, setRedirectBack] = useState(false);

  const history = useNavigate();

  const { fields, append } = useFieldArray({
    control,
    name: 'budgetTranslations',
  });

  const onSubmit: SubmitHandler<Budget> = function submitForm(budgetObject: Budget) {
    createBudget(Object.assign(budgetObject, {
      clientId: Number(clientId),
      parentId: budgetId ? Number(budgetId) : budgetObject.parentId,
    })).then(() => {
      toast.success('Saved list');
      setRedirectBack(true);
    });
  };

  return (
    redirectBack ? <div><Navigate to={`/configuratie/${clientId}/budgets/`} /></div>
      : (
        <div>
          <form className={styles.max_width} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label id="klantnaam-label">Budget Code</label>
              <input
                aria-labelledby="klantnaam-label"
                className="form-control"
                {...register('code', {
                  required: true,
                })}
              />
              {errors.code && <span>Please fill in the budget code.</span>}
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="standard-savings-label" className="form-check-input" type="checkbox" {...register('standardSaving')} />
              <label id="standard-savings-label" className="form-check-label">Default Save</label>
            </div>
            <div className="form-check form-switch">
              <input aria-labelledby="standard-payout-label" className="form-check-input" type="checkbox" {...register('standardPayout')} />
              <label id="standard-payout-label" className="form-check-label">Default Pay Out</label>
            </div>
            <div className="form-check form-switch">
              <input aria-labelledby="savings-label" className="form-check-input" type="checkbox" {...register('savings')} />
              <label id="savings-label" className="form-check-label">Is a Save Budget</label>
            </div>
            {
              fields.map((field, index) => {
                return (
                  <div>
                    <div key={field.id} className="row form-group">
                      <div className="col-sm-6">
                        <label id="translation-label">Translation (EN)</label>
                        <input
                          aria-labelledby="translation-label"
                          className="form-control"
                          defaultValue="test"
                          {...register(`budgetTranslations.${index}.text`, {
                            required: true,
                          })}
                        />
                        {errors.code && <span>Please fill in the translation text.</span>}
                      </div>
                      <div className="col-sm-6">
                        <label id="description-label">Description</label>
                        <input
                          className="form-control"
                          {...register(`budgetTranslations.${index}.descriptionText`, {
                            required: true,
                          })}
                        />
                        {errors.code && <span>Please fill in the description.</span>}
                      </div>
                    </div>
                  </div>
                );
              })
            }

            <button
              type="button"
              onClick={() => append({
                text: '',
                descriptionText: '',
                cultureId: 2, // TODO cultures staan nog niet vast!
              })}
            >
              Add English Translation
            </button>
            <div>
              <button type="button" className="btn btn-primary mb-2 float-end" onClick={() => history(-1)}>Back</button>
              <input className="btn btn-primary mb-2" type="submit" value="Save" />
            </div>
          </form>
        </div>
      )
  );
}
