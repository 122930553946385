import { sendRequest } from '@tasper/configurator-core';
import { CsvSftpSetting } from './models/CsvSftpSetting';

const BASE_URL = 'api/CsvSftpSetting';

export const createNewCsvSftpSetting = (csvSftpSetting: CsvSftpSetting) : Promise<CsvSftpSetting> => sendRequest<CsvSftpSetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(csvSftpSetting),
});

export const updateCsvSftpSetting = (csvSftpSetting: CsvSftpSetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${csvSftpSetting.id}`,
  content: JSON.stringify(csvSftpSetting),
});

export const getCsvSftpSetting = (csvSftpSettingId: number) : Promise<CsvSftpSetting> => sendRequest<CsvSftpSetting>({
  method: 'GET',
  url: `${BASE_URL}/${csvSftpSettingId}`,
});

export const deleteCsvSftpSetting = (csvSftpSettingId: number) : Promise<CsvSftpSetting> => sendRequest<CsvSftpSetting>({
  method: 'DELETE',
  url: `${BASE_URL}/${csvSftpSettingId}`,
});
