import axios, { AxiosError } from 'axios';

// TODO base url config voor alle services
const API_URL = '/';
const getToken = () => '';

const AxiosSecured = axios.create({
    baseURL: API_URL,
});

AxiosSecured.interceptors.request.use((request) => {
    request.headers = {
        'X-CSRF': '1', /* 
    * This (all custom 'X' headers) will force cors validation, 
    * server (BFF) will only have to check for this header to make te request valid and cors can do the rest. 
    */
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };

    if (request.url?.includes('step-definitions')) {
        request.headers['X-USER-ID'] = "No-User";
    }

    const token = getToken();
    if (token) {
        request.headers.Authorization = `Bearer ${getToken()}`;
    }

    return request;
});

AxiosSecured.interceptors.response.use(
    undefined,
    err => {
        if (err.response.status === 400) {
            const error = Object.assign(
                new Error(`HTTP-Status: ${err.response.status}`),
                {
                    status: '400',
                    type: 'validation',
                    fields: err.response.data
                }
            );

            return Promise.reject(error);
        }

        return Promise.reject(err);
    }
)

type HttpMethod =
    | 'get'
    | 'GET'
    | 'post'
    | 'POST'
    | 'delete'
    | 'DELETE'
    | 'put'
    | 'PUT';

type HttpRequest = {
    method: HttpMethod;
    url: string;
    contentType?: string;
    content?: string | any;
};

export default async function sendRequest<TResponse>({
    url, content, contentType, method,
}: HttpRequest): Promise<TResponse> {
    return await AxiosSecured.request<TResponse>({
        url,
        method,
        data: content
    }).then(response => response.data);
}
