import { sendRequest } from '@tasper/configurator-core';
import { AfasConnectionSetting } from './models/AfasConnectionSetting';

const BASE_URL = 'api/AfasConnectionSetting';

// eslint-disable-next-line max-len
export const createNewAfasConnectionSetting = (afasConnectionSetting: AfasConnectionSetting) : Promise<AfasConnectionSetting> => sendRequest<AfasConnectionSetting>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(afasConnectionSetting),
});

export const updateAfasConnectionSetting = (afasConnectionSetting: AfasConnectionSetting) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${afasConnectionSetting.id}`,
  content: JSON.stringify(afasConnectionSetting),
});

export const getAfasConnectionSetting = (afasConnectionSettingId: number) : Promise<AfasConnectionSetting> => sendRequest<AfasConnectionSetting>({
  method: 'GET',
  url: `${BASE_URL}/${afasConnectionSettingId}`,
});
