import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Csvimport } from '../../api/models/Csvimport';
import { deleteCsvSftpSetting } from '../../api/CsvSftpSettingService';
import { deleteCsvPgpSetting } from '../../api/CsvPgpSettingService';
import { ICsvImportPageComponent } from '../types';
import CsvPgpSettings from './parts/CsvPgpSettings';
import CsvSftpSettings from './parts/CsvSftpSettings';

interface CsvConnectionSettingsFormProps extends ICsvImportPageComponent {
  csvImport: Csvimport;
  clientId: number;
}

export default function CsvConnectionSettingsForm(props: CsvConnectionSettingsFormProps) {
  const [hasPgp, setHasPgp] = useState(!!props.csvImport.csvPgpSettingId);
  const [hasSftp, setHasSftp] = useState(!!props.csvImport.csvSftpSettingId);
  const [csvImport, setCsvImport] = useState(props.csvImport);

  const createdPgpSettingsEvent = (pgpSettingsId: number) => {
    const updateCsvImport = csvImport;
    updateCsvImport.csvPgpSettingId = pgpSettingsId;
    setCsvImport(updateCsvImport);
    props.updateCsvImport(updateCsvImport);
  };

  const createdSftpSettingsEvent = (pgpSettingsId: number) => {
    const updateCsvImport = csvImport;
    updateCsvImport.csvSftpSettingId = pgpSettingsId;
    setCsvImport(updateCsvImport);
    props.updateCsvImport(updateCsvImport);
  };

  function handleChangeSftpSwitch(event: React.ChangeEvent<HTMLInputElement>) {
    setHasSftp(event.target.checked);

    if (!event.target.checked && csvImport.csvSftpSettingId) {
      // Delete when unchecked and exists

      deleteCsvSftpSetting(csvImport.csvSftpSettingId)
        .then(() => {
          const updateCsvImport = csvImport;
          updateCsvImport.csvSftpSettingId = undefined;
          setCsvImport(updateCsvImport);
          props.updateCsvImport(updateCsvImport);
        })
        .catch(() => toast.error('An error occured'));
    }
  }

  function handleChangePgpSwitch(event: React.ChangeEvent<HTMLInputElement>) {
    setHasPgp(event.target.checked);

    if (!event.target.checked && csvImport.csvPgpSettingId) {
      // Delete when unchecked and exists

      deleteCsvPgpSetting(csvImport.csvPgpSettingId)
        .then(() => {
          const updateCsvImport = csvImport;
          updateCsvImport.csvPgpSettingId = undefined;
          setCsvImport(updateCsvImport);
          props.updateCsvImport(updateCsvImport);
        })
        .catch(() => toast.error('An error occured'));
    }
  }

  return (
    <>
      <div className="form-check form-switch">
        <input aria-labelledby="primair" className="form-check-input" type="checkbox" onChange={handleChangePgpSwitch} checked={hasPgp} />
        <label className="form-check-label" id="primair">PGP</label>
      </div>
      <div className="form-check form-switch">
        <input aria-labelledby="primair" className="form-check-input" type="checkbox" onChange={handleChangeSftpSwitch} checked={hasSftp} />
        <label className="form-check-label" id="primair">SFTP</label>
      </div>
      {
        hasPgp && (
          <>
            <hr />
            <h3>PGP settings</h3>
            <CsvPgpSettings
              clientId={props.clientId}
              csvPgpSettingsId={csvImport.csvPgpSettingId}
              csvImportId={csvImport.id as number}
              createdPgpSettingsEvent={createdPgpSettingsEvent}
            />
          </>
        )
      }
      {
        hasSftp && (
          <>
            <hr />
            <h3>SFTP settings</h3>
            <CsvSftpSettings
              clientId={props.clientId}
              csvSftpSettingsId={csvImport.csvSftpSettingId}
              csvImportId={csvImport.id as number}
              createdSftpSettingsEvent={createdSftpSettingsEvent}
            />
          </>
        )
      }
    </>
  );
}
