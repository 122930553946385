import React from 'react';
import { TabTableWithNewRow } from '@tasper/configurator-core';
import 'react-toastify/dist/ReactToastify.css';
import { Item, ItemTableProps } from './types';
import { getAllItems } from '../../../api/ItemService';
import ItemRow from './parts/ItemRow';

export default function ItemTable({ clientId }: ItemTableProps) {
  const renderRow = (rowModel: Item, addNewRowEvent: () => void) => {
    return <ItemRow onSave={addNewRowEvent} item={rowModel} clientId={clientId} />;
  };

  const getData = (): Promise<Item[]> => {
    return new Promise((resolve, reject) => {
      getAllItems(clientId)
        .then(data => {
          resolve(
            data.map(element => {
              return {
                id: element.id,
                name: element.code,
                type: element.valueType,
              };
            }),
          );
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-5">Itemcode</div>
        <div className="col-5">Type</div>
      </div>
      <TabTableWithNewRow<Item>
        emptyRow={{ isNew: true }}
        renderRow={renderRow}
        getData={getData}
      />
    </>
  );
}
