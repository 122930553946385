import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { ICsvImportPageComponent } from '../types';
import { Csvimport } from '../../api/models/Csvimport';
import { createNewCsvSetting, getCsvSetting, updateCsvSetting } from '../../api/CsvSettingService';
import { CsvSetting } from '../../api/models/CsvSetting';

interface CsvSettingProps extends ICsvImportPageComponent {
  csvImport: Csvimport;
}

export default function CsvSettingForm(props: CsvSettingProps) {
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm<CsvSetting>();
  const onSubmit: SubmitHandler<CsvSetting> = submitForm;
  const [csvSId, setCsvSId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.csvImport.csvSettingId) {
      setIsLoading(true);

      getCsvSetting(props.csvImport.csvSettingId as number)
        .then(data => {
          reset(data);
          setCsvSId(data.id);
          setIsLoading(false);
        })
        .catch(() => toast.error('An error occured.'));
    }
  }, [props.csvImport, reset]);

  function renderTextInput(name: any, label: string, type: string) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input
          className="form-control"
          type={type}
          required
          {...register(name)}
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
      </div>
    );
  }

  function submitForm(csvForm: CsvSetting) {
    const toastId = toast.loading('Saving...');

    if (!csvSId) {
      // Create
      csvForm.csvImportId = props.csvImport.id as number;

      createNewCsvSetting(csvForm)
        .then(data => {
          setCsvSId(data.id);

          // Send new id to parent
          const updatedCsvImport = props.csvImport;
          updatedCsvImport.csvSettingId = data.id;
          props.updateCsvImport(updatedCsvImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
    } else {
      // Update
      updateCsvSetting(csvForm)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating', type: 'error', isLoading: false }));
    }
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderTextInput('numberOfHeadersToSkip', 'Number of headers to skip', 'number')}
        {renderTextInput('numberOfFootersToSkip', 'Number of footers to skip', 'number')}
        {renderTextInput('encoding', 'Encoding', 'text')}
        <button type="submit" className="btn btn-primary">Save</button>
      </form>
    );
}
