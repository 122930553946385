import { sendRequest } from '@tasper/configurator-core';
import { Import } from './models/Import';

const BASE_URL = 'api/Import';

export const createImport = (importModel : Import) : Promise<Import> => sendRequest<Import>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(importModel),
});

export const getAllImports = (clientId: number) : Promise<Import[]> => sendRequest<Import[]>({
  method: 'GET',
  url: `${BASE_URL}/List/${clientId}`,
});

export const getImportById = (importId: number) : Promise<Import> => sendRequest<Import>({
  method: 'GET',
  url: `${BASE_URL}/${importId}`,
});

export const updateImport = (importModel: Import) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${importModel.id}`,
  content: JSON.stringify(importModel),
});

export const deleteImport = (importId: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${importId}`,
});
