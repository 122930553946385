import React from 'react';

export type ItemState = | 'unchanged' | 'dirty' | 'error' | 'saving';

interface TabElementProps {
  activeTab: string;
  label: string;
  onClick: (label: string) => void;
  /* itemState: ItemState */
}

export default function TabElement({ activeTab, label, onClick /* , itemState */ }: TabElementProps) {
  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }

  function handleTabClick() {
    onClick(label);
  }

  /*
    function ItemStateToditStatus(state: ItemState): EditStatus {
        switch (state) {
            case "dirty": return EditStatus.pending;
            case "error": return EditStatus.error;
            case "saving": return EditStatus.success;
            default: return EditStatus.none;
        }
    }
    */
  return (
    <li className={className} role="presentation" onClick={handleTabClick}>
      {label}
      {/* <EditStatusIcon status={ItemStateToditStatus(itemState)} /> */}
    </li>
  );
}
