import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

// import 'monaco-editor/esm/vs/basic-languages/monaco.contribution.js'; // all languages
import 'monaco-editor/esm/vs/basic-languages/csharp/csharp.contribution';
import 'monaco-editor/esm/vs/basic-languages/sql/sql.contribution';

import Monokai from 'monaco-themes/themes/Monokai.json';
import SolarizedDark from 'monaco-themes/themes/Solarized-dark.json';

import defaultLanguage from './defaultLanguage';
import configureLanuage from './configureLanuage';
import { Monaco, csTemplate, rules, calc } from './types';

interface IHiddenEditorService {
    getColorTheme(): any;
}

interface IHiddenEditorFields {
    _themeService: IHiddenEditorService;

}
export function configureEditor(editor: monaco.editor.IStandaloneCodeEditor) {
    let actionRef: monaco.IDisposable | null = null;
    function actionSwitch(mode: boolean) {
        if (actionRef) actionRef.dispose();

        actionRef = editor.addAction({
            id: 'switch-light-and-dark',
            label: `Switch Theme to ${mode ? 'Light' : 'Dark'}`,
            // eslint-disable-next-line no-bitwise
            keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM],
            contextMenuGroupId: '9_cutcopypaste',
            run: (_edit, _args) => {
                editor.updateOptions({
                    theme: mode ? 'vs-light-calc' : 'vs-dark-calc',
                });
                actionSwitch(!mode);
            },
        });
    }

    // eslint-disable-next-line no-underscore-dangle
    const currentTheme = (editor as unknown as IHiddenEditorFields)._themeService?.getColorTheme()?.themeName;
    actionSwitch(currentTheme === 'vs-dark-calc');
}

export function configureMonaco(m: Monaco) {
    m.editor.defineTheme('SolarizedDark', SolarizedDark as monaco.editor.IStandaloneThemeData);
    m.editor.defineTheme('Monokai', Monokai as monaco.editor.IStandaloneThemeData);

    // Set up my custom there (I may move this later)
    m.editor.defineTheme('vs-dark-calc', {
        base: 'vs-dark',
        inherit: true,
        rules: [
            { token: 'macro', foreground: 'EE5555', fontStyle: 'bold' },
            { token: 'macro.text', foreground: '5555EE', fontStyle: 'bold' },
            { token: 'mc.open', foreground: '88EE88', fontStyle: 'bold' },
            { token: 'mc.type', foreground: 'EE0000', fontStyle: 'italic' },
            { token: 'mc.split', foreground: '555555', fontStyle: 'normal' },
            { token: 'mc.name', foreground: '5555EE', fontStyle: 'bold' },
            { token: 'mc.close', foreground: '88EE88', fontStyle: 'bold' },
        ],
        colors: {},
    });
    m.editor.defineTheme('vs-light-calc', {
        base: 'vs',
        inherit: true,
        rules: [
            { token: 'macro', foreground: 'EE5555', fontStyle: 'bold' },
            { token: 'macro.text', foreground: '5555EE', fontStyle: 'bold' },
            { token: 'mc.open', foreground: '88EE88', fontStyle: 'bold' },
            { token: 'mc.type', foreground: 'EE0000', fontStyle: 'italic' },
            { token: 'mc.split', foreground: '555555', fontStyle: 'normal' },
            { token: 'mc.name', foreground: '5555EE', fontStyle: 'bold' },
            { token: 'mc.close', foreground: '88EE88', fontStyle: 'bold' },
        ],
        colors: {},
    });

    // editor.setTheme('Monokai');
    // m.editor.setTheme('vs-dark-calc');

    // defaultLanguage(m);
    configureLanuage(m, csTemplate);
    configureLanuage(m, rules);
    configureLanuage(m, calc);
}
