import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  ValueType, EditStatus, EditStatusIcon, ValueTypeDropdown, ValueTypeInput,
} from '@tasper/configurator-core';

import { createNewConstant, changeConstant, deleteConstant } from '../../../../api/ConstantService';
import { Constant } from '../types';

interface ConstantRowProps {
  constant: Constant;
  onSave: () => void;
  clientId: number;
}

export default function ConstantRow({ clientId, constant, onSave }: ConstantRowProps) {
  const [constantName, setConstantName] = useState(constant.name || '');
  const [type, setType] = useState<ValueType | undefined>(constant.type || undefined);
  const [value, setValue] = useState(constant.value || '');
  const [isNew, setIsNew] = useState(constant.isNew);
  const [editStatus, setEditStatus] = useState(EditStatus.none);
  const [id, setId] = useState(constant.id);
  const [deleted, setDeleted] = useState(false); // Hide component when user deleted

  function handleChangeConstantName(e: React.ChangeEvent<HTMLInputElement>) {
    setEditStatus(EditStatus.pending);
    setConstantName(e.currentTarget.value);
  }

  const handleChangeType = (newValue: ValueType | undefined) => {
    setEditStatus(EditStatus.pending);
    setType(newValue);

    if (newValue === ValueType.Boolean) setValue('false'); // Set default boolean value
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditStatus(EditStatus.pending);
    setValue(e.currentTarget.value);
  };

  const handleValueChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditStatus(EditStatus.pending);
    setValue(e.currentTarget.checked ? 'true' : 'false');
  };

  const handleSave = () => {
    if (constantName !== '' && type !== undefined && value !== '') {
      if (isNew) {
        onSave();

        createNewConstant({
          clientId, code: constantName, valueType: type, value,
        })
          .then(data => {
            setIsNew(false);
            setId(data.id);
            setIsNew(false);
            setEditStatus(EditStatus.success);
          })
          .catch(() => {
            toast.error(`An error occured while creating constant ${constantName}`);
            setEditStatus(EditStatus.error);
          });
      } else {
        changeConstant({
          id, code: constantName, valueType: type, value, clientId,
        })
          .then(() => {
            setEditStatus(EditStatus.success);
          })
          .catch(() => {
            setEditStatus(EditStatus.error);
          });
      }
    }
  };

  const handleDelete = () => {
    if (id !== undefined) {
      if (window.confirm('Are you sure you want to delete this constant?')) {
        deleteConstant(id)
          .then(() => {
            toast.success(`Constant ${constantName} is successfully deleted.`);
            setDeleted(true); // Hide component
          })
          .catch(() => {
            toast.error(`An error occured while deleting constant ${constantName}.`);
          });
      }
    }
  };

  return !deleted
    ? (
      <form>
        <div className="row">
          <div className="col-4">
            <input className="form-control" placeholder="Name" value={constantName} onChange={handleChangeConstantName} onBlur={handleSave} required />
          </div>
          <div className="col-3">
            <ValueTypeDropdown
              initialValue={type?.toString()}
              onChange={handleChangeType}
              onBlur={handleSave}
            />
          </div>
          <div className="col-3">
            <ValueTypeInput
              valueType={type}
              onBlur={handleSave}
              onChange={handleValueChange}
              onChangeCheckbox={handleValueChangeCheckbox}
              value={value}
              className="form-control"
            />
          </div>
          <div className="col-1">
            <EditStatusIcon status={editStatus} />
          </div>
          <div className="col-1">
            { /* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/click-events-have-key-events.md */
              id !== undefined && <i className="nav_icon bi bi-trash" onClick={handleDelete} />
            }
          </div>
        </div>
      </form>
    )
    : null;
}
