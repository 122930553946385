import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateImport } from '../../../api/ImportService';
import { Import } from '../../../api/models/Import';

interface ImportSettingsProps {
  importModel: Import;
  importChangedEvent: (importModel: Import) => void;
}

export default function ImportSettings(props: ImportSettingsProps) {
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm<Import>();

  useEffect(() => {
    reset(props.importModel);
  }, [props.importModel, reset]);

  const onSubmit: SubmitHandler<Import> = function submitForm(importModel: Import) {
    const toastId = toast.loading('Saving...');

    updateImport(importModel)
      .then(() => {
        toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        props.importChangedEvent(importModel);
      })
      .catch(() => toast.update(toastId, { render: 'An error occured while saving', type: 'error', isLoading: false }));
  };

  function renderTextInput(name: any, label: string) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input
          className="form-control"
          type="text"
          required
          {...register(name)}
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="Dit veld is verplicht."
          name={name}
        />
      </div>
    );
  }

  function renderCheckBox(name: any, label: string) {
    return (
      <div className="form-check form-switch">
        <input aria-labelledby="primair" className="form-check-input" type="checkbox" {...register(name)} />
        <label className="form-check-label" id="primair">
          {' '}
          {label}
        </label>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>Import settings</h3>
      {renderTextInput('standardDateFormat', 'Default date format')}
      {renderTextInput('standardBooleanFormat', 'Default boolean format')}
      {renderCheckBox('groeperen', 'Group')}
      <button className="btn btn-success">Save</button>
    </form>
  );
}
