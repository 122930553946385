import { sendRequest } from '@tasper/configurator-core';
import { ProductDefinitionModel } from './models/ProductDefinitionModel';

const BASE_URL = 'api/ProductDefinitionModel';

export const getProductDefinitionModelsByProductDefinitionId = (productDefinitionId: number) : Promise<ProductDefinitionModel[]> => {
  return sendRequest<ProductDefinitionModel[]>({
    method: 'GET',
    url: `${BASE_URL}/GetByProductDefinitionId/${productDefinitionId}`,
  });
};

export default undefined;
