import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { EditStatus, EditStatusIcon } from '@tasper/configurator-core';
import { updateProductProperty } from '../../../../api/ProductPropertyService';
import { ProductProperty } from '../../../../api/models/ProductProperty';

interface BaseRenderProps {
  options: any;
  currentValue?: string;
  productProperty: ProductProperty;
}

export default function RenderBaseSelectInput({ options: _options, productProperty, currentValue }: BaseRenderProps) {
  const [options, setOptions] = useState<any>();
  const [value, setValue] = useState({ label: currentValue, value: currentValue });
  const [editStatus, setEditStatus] = useState(EditStatus.none);

  useEffect(() => {
    setOptions(_options);
  }, [_options]);

  function handleChange(selectedItem: any) {
    setEditStatus(EditStatus.pending);
    setValue(selectedItem);
    const property = productProperty;
    property.value = selectedItem.value;
    updateProductProperty(property).then(() => {
      setEditStatus(EditStatus.success);
    });
  }

  return (
    <div className="row">
      <div className="col-5">

        <Select
          options={options}
          value={value}
          placeholder="Kies een lijst"
          onChange={option => handleChange(option)}
        />
      </div>
      <div className="col-2"><EditStatusIcon status={editStatus} /></div>
    </div>
  );
}
