import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { getClient } from '@tasper/configurator-customers';
import { Loader } from '@tasper/configurator-core';
import { ConstantPage, ItemPage } from '@tasper/configurator-customer-data';
import { BudgetRouter } from '@tasper/configurator-budgets';
import { MenuRouter } from '@tasper/configurator-menu';
import { ListRouter } from '@tasper/configurator-lists';
import { CalculatedValueOverview } from '@tasper/configurator-calculated-values';
import { ProductRouter } from '@tasper/configurator-products';
import { ImportRouter } from '@tasper/configurator-imports';
import { HorizontalMenu } from '../../Components/Layout';

const Configuration = () => {
  const { clientId = '' } = useParams();
  const [name, setName] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    getClient(clientId)
      .then(data => {
        setName(data.klantnaam);
        setIsNotFound(false);
      })
      .catch(() => {
        setIsNotFound(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId]);

  return (
    isLoading
      ? <Loader />
      : isNotFound
        ? (
          <div className="alert alert-danger" role="alert">
            <span>The requested customer doesn&#39;t exist.</span>
          </div>
        )
        : (
          <>
            <HorizontalMenu
              title={name}
              items={[
                { title: 'BAS items', route: 'items' },
                { title: 'Constants', route: 'constants' },
                { title: 'Imports', route: 'imports' },
                { title: 'Calculated Values', route: 'calculatedvalues' },
                { title: 'Budgets', route: 'budgets' },
                { title: 'Menu', route: 'menu' },
                { title: 'Lists', route: 'lists' },
                { title: 'Products', route: 'products' },
              ]}
            />
            <div className="r_content">
              <div className="container-fluid">
                <Routes>
                  <Route path="items" element={<ItemPage />} />
                  <Route path="constants" element={<ConstantPage />} />
                  <Route path="imports/*" element={<ImportRouter />} />
                  <Route path="budgets/*" element={<BudgetRouter />} />
                  <Route path="menu/*" element={<MenuRouter />} />
                  <Route path="lists/*" element={<ListRouter />} />
                  <Route path="calculatedValues/" element={<CalculatedValueOverview />} />
                  <Route path="products/*" element={<ProductRouter />} />
                </Routes>
              </div>
            </div>
          </>
        )
  );
};

export default Configuration;
