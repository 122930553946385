import { FormGroup } from '@tasper/configurator-core';
import React, { useState, useEffect } from 'react';
import { getProductPropertyByProductIdAndProductConfigDefinitionId, createProductProperty, deleteProductProperty } from '../../../api/ProductPropertyService';
import { ProductProperty } from '../../../api/models/ProductProperty';

interface RenderCheckboxProps {
  mandatory: boolean;
  entityId: number;
  code: string;
  productId: number;
}

// TODO merge with other RenderCheckBox
export default function RenderCheckBox({
  mandatory, entityId, code, productId,
}: RenderCheckboxProps) {
  const [checked, setChecked] = useState(mandatory);

  const [productProperty, setProductProperty] = useState<ProductProperty | undefined>();

  useEffect(() => {
    if (!mandatory) {
      getProductPropertyByProductIdAndProductConfigDefinitionId(productId, entityId).then(value => {
        if (value) {
          setProductProperty(value);
          setChecked(true);
        }
      });
    }
  }, [entityId, mandatory]);

  function handleChange(state: boolean) {
    if (state && !productProperty) {
      setChecked(state);
      createProductProperty({ productId, productConfigurationDefinitionPropertyId: entityId }).then(data => {
        setProductProperty(data);
      });
    }

    if (!state && productProperty?.id) {
      setChecked(state);
      deleteProductProperty(productProperty.id).then(() => {
        setProductProperty(undefined);
      });
    }
  }

  return (
    <FormGroup>
      <label>{code}</label>
      <input type="checkbox" disabled={mandatory} className="form-check-input" checked={checked} onChange={() => handleChange(!checked)} />
    </FormGroup>
  );
}
