import { ValueType, sendRequest } from '@tasper/configurator-core';
import { Item } from './models/Item';

const BASE_URL = 'api/item';

export const getAllItems = (clientId: number) : Promise<Item[]> => sendRequest<Item[]>({
  method: 'GET',
  url: `${BASE_URL}/List/${clientId}`,
});

export const getAllItemsByValueType = (clientId: number, type: ValueType) : Promise<Item[]> => sendRequest<Item[]>({
  method: 'GET',
  url: `${BASE_URL}/GetByValueType/${clientId}/${type}`,
});

export const createNewItem = (item: Item) : Promise<Item> => sendRequest<Item>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(item),
});

export const changeItem = (item : Item) : Promise<Item> => sendRequest<Item>({
  method: 'PUT',
  url: `${BASE_URL}/${item.id}`,
  content: JSON.stringify(item),
});

export const deleteItem = (id: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${id}`,
});
