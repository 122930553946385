/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import monaco from 'monaco-editor/esm/vs/editor/editor.api';
import Editor, { Monaco, useMonaco } from '@monaco-editor/react';
// import delay from "delay";
import { configureEditor, configureMonaco } from './internal/configure';

export interface IDimension {
    width: number;
    height: number;
}
export interface CodeEditor {
    layout(dimension?: IDimension): void;
}

export interface ManacoEditorProps {
    value?: string;
    onValueChanged: (text: string | undefined) => void;
    darkMode?: boolean;
    onBlur?: () => void;
    language?: string;
    className?: string
    editorRef?: React.MutableRefObject<CodeEditor>
    height?: string | number
    width?: string | number
}

export default function MonacoEditor({
    value,
    onValueChanged,
    onBlur,
    darkMode = false,
    language,
    className,
    editorRef: manacoEditorRef,
    width,
    height
}: ManacoEditorProps) {
    const editorRef = useRef(null as monaco.editor.IStandaloneCodeEditor | null);
    // const onBlurRef = useRef(onBlur);
    const monacoRef = useMonaco();

    useEffect(() => {
      // monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true);
      if (monacoRef) {
        configureMonaco(monacoRef);
      }
    }, [monacoRef]);
  
    useEffect(() => {
        const handleResize = () => {
            if (editorRef?.current) {
                editorRef.current.layout({ height: 0, width: 0 });
            }
        };
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [editorRef])

    const handleEditorWillMount = (m: Monaco) => {
        configureMonaco(m);
    };

    const handleOnMount = useCallback((editor: monaco.editor.IStandaloneCodeEditor, _monaco: Monaco) => {
        editorRef.current = editor;
        editor.onDidBlurEditorText(() => {
            if (onBlur) onBlur();
            // if (onBlurRef.current) {
            //     onBlurRef.current();
            // }
        });
        configureEditor(editor);
        if (manacoEditorRef) manacoEditorRef.current = editor;
    }, [onBlur]);

    return (
        <Editor
            className={className}
            beforeMount={handleEditorWillMount}
            onMount={handleOnMount}
            theme={darkMode ? 'vs-dark-calc' : 'vs-light-calc'}
            value={value}
            onChange={(newValue, ev) => onValueChanged && onValueChanged(newValue)}
            defaultLanguage={language}
            language={language}
            options={{
                minimap: { enabled: false },
                automaticLayout: true
            }}
            height={height}
            width={width}
        />
    );
}
