import { sendRequest } from '@tasper/configurator-core';
import { AfasConfiguration } from './models/AfasConfiguration';

const BASE_URL = 'api/AfasConfiguration';

export const createNewAfasConfiguration = (afasConfiguration: AfasConfiguration) : Promise<AfasConfiguration> => sendRequest<AfasConfiguration>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(afasConfiguration),
});

export const updateAfasConfiguration = (afasConfiguration: AfasConfiguration) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${afasConfiguration.id}`,
  content: JSON.stringify(afasConfiguration),
});

export const getAfasConfiguration = (afasConfigurationId: number) : Promise<AfasConfiguration> => sendRequest<AfasConfiguration>({
  method: 'GET',
  url: `${BASE_URL}/${afasConfigurationId}`,
});

export const getAfasConfigurationByImportId = (importId: number) : Promise<AfasConfiguration> => sendRequest<AfasConfiguration>({
  method: 'GET',
  url: `${BASE_URL}/GetByAfasImportId/${importId}`,
});
