import React, { useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ListType } from '../api/models/ListType';
import { ListForConstant } from '../api/models/ListForConstant';
import { ListItem } from '../api/models/ListItem';
import styles from './index.module.scss';
import { createListForConstant } from '../api/ListService';
import BudgetListTypeInput from './parts/BudgetListTypeInput';
import ProductListTypeInput from './parts/ProductListTypeInput';
import ItemListTypeInput from './parts/ItemListTypeInput';
import CalculatedValueListTypeInput from './parts/CalculatedValueListTypeInput';
import ConstantListTypeInput from './parts/ConstantListTypeInput';

export function ListPage() {
  const { clientId = '' } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm<ListForConstant>({
    defaultValues: {
      listItems: [{ code: '' }],
    },
  });

  const [selectedListType, setSelectedListType] = useState(ListType.Unkown);
  const [items, setItems] = useState<ListItem[]>([]);
  const [redirectBack, setRedirectBack] = useState(false);

  const history = useNavigate();

  const onSubmit: SubmitHandler<ListForConstant> = function submitForm(listForConstant: ListForConstant) {
    const item = listForConstant;
    item.clientId = Number(clientId);
    item.listItems = items;

    createListForConstant(listForConstant)
      .then(() => {
        toast.success('lijst is opgeslagen!');
        setRedirectBack(true);
      })
      .catch(() => {
        toast.error('menu is niet opgeslagen!');
      });
  };

  function handleSwitch(selected: ListType): JSX.Element | undefined {
    switch (selected) {
      case ListType.Budgets:
        return (<BudgetListTypeInput clientId={clientId} addToListItems={listItems => setItems(listItems)} />);
      case ListType.Products:
        return (<ProductListTypeInput clientId={clientId} addToListItems={listItems => setItems(listItems)} />);
      case ListType.BasItems:
        return (<ItemListTypeInput clientId={clientId} addToListItems={listItems => setItems(listItems)} />);
      case ListType.Constants:
        return (<ConstantListTypeInput clientId={clientId} addToListItems={listItems => setItems(listItems)} />);
      case ListType.CalculatedValues:
        return (<CalculatedValueListTypeInput clientId={clientId} addToListItems={listItems => setItems(listItems)} />);
      default:
        return undefined;
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setItems([]); // als je wisselt van type moet de lijst van items weer geleegd worden!

    switch (e.currentTarget.value) {
      case ListType.Budgets:
        setSelectedListType(ListType.Budgets);
        break;
      case ListType.BasItems:
        setSelectedListType(ListType.BasItems);
        break;
      case ListType.Products:
        setSelectedListType(ListType.Products);
        break;
      case ListType.Constants:
        setSelectedListType(ListType.Constants);
        break;
      case ListType.CalculatedValues:
        setSelectedListType(ListType.CalculatedValues);
        break;
      default:
        setSelectedListType(ListType.Unkown);
    }
  }

  return (
    redirectBack ? <Navigate to={`/configuratie/${clientId}/lists/`} />

      : (
        <div>
          <form className={styles.max_width} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label id="name-label">lijst naam</label>
              <input
                aria-labelledby="name-label"
                className="form-control"
                {...register('name', {
                  required: true,
                })}
              />
              {errors.name && <span>De naam van de lijst is een verplicht veld!</span>}
            </div>
            <div>
              <div className="row form-group">
                <div className="col-sm-6">
                  <label id="product-code-label">Type lijst</label>
                  <select
                    aria-labelledby="translation-label"
                    className="form-control"
                    defaultValue="test"
                    {...register('listType', { required: true })}
                    onChange={e => handleChange(e)}
                  >
                    <option value={ListType.BasItems}>Bas items</option>
                    <option value={ListType.Constants}>Constanten</option>
                    <option value={ListType.CalculatedValues}>Berekende waardes</option>
                    <option value={ListType.Budgets}>Budgetten</option>
                    <option value={ListType.Products}>producten</option>
                  </select>
                </div>
              </div>
            </div>

            {handleSwitch(selectedListType)}

            <div>
              <button type="button" className="btn btn-primary mb-2 float-end" onClick={() => history(-1)}>Ga Terug</button>
              <input className="btn btn-primary mb-2" type="submit" value="Opslaan" />
            </div>
          </form>
        </div>
      )
  );
}

export default ListPage;
