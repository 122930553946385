import React, { useEffect, useState } from 'react';
import { getAllItems } from '@tasper/configurator-customer-data';
import { Loader } from '@tasper/configurator-core';
import { ProductProperty } from '../../../../api/models/ProductProperty';
import RenderBaseSelectInput from './RenderBaseSelectInput';

interface RenderProps {
  productProperty: ProductProperty;
  clientId: string;
  value?: string;
}
export default function RenderBasItemInput({ clientId, value, productProperty }: RenderProps) {
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllItems(parseInt(clientId, 10)).then(result => {
      setOptions(result.map(item => ({
        label: item.code,
        value: item.code,
      })));
    })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId]);

  return (
    isLoading ? <Loader />
      : <RenderBaseSelectInput currentValue={value} productProperty={productProperty} options={options} />
  );
}
