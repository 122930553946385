import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { FormSelectBoxItem, ValueType, Loader } from '@tasper/configurator-core';
import {
  getAllConstantsByType, getAllItemsByValueType, Constant, Item,
} from '@tasper/configurator-customer-data';

import { createNewAfasConfiguration, getAfasConfiguration, updateAfasConfiguration } from '../../api/AfasConfigurationService';
import { IAfasImportPageComponent } from '../types';
import { AfasImport } from '../../api/models/AfasImport';
import { AfasConfiguration } from '../../api/models/AfasConfiguration';
import AfasImportType from '../../api/models/AfasImportType';

interface AfasConfigurationFormProps extends IAfasImportPageComponent {
  afasImport: AfasImport;
  clientId: number;
  isPrimairImport: boolean;
}

export interface AfasConfigurationFormModel {
  valuepathConstantId?: FormSelectBoxItem<number>;
  afasNamespaceConstantId?: FormSelectBoxItem<number>;
  personeelsnummerItemId?: FormSelectBoxItem<number>;
  identifierElementConstantId?: FormSelectBoxItem<number>;
}

export default function AfasConfigurationForm(props: AfasConfigurationFormProps) {
  const [afasCId, setAfasCId] = useState<number | undefined>();
  const [stringConstantsOptions, setStringConstantsOptions] = useState<any[]>([]);
  const [stringItemsOptions, setStringItemsOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    control, handleSubmit, reset, formState: { errors },
  } = useForm<AfasConfigurationFormModel>();

  useEffect(() => {
    function updateForm(afasConfiguration: AfasConfiguration, stringConstantsOptions: FormSelectBoxItem<number>[], stringItemsOptions: FormSelectBoxItem<number>[]) {
      reset({
        valuepathConstantId: stringConstantsOptions.find(constant => constant.value === afasConfiguration.valuepathConstantId),
        afasNamespaceConstantId: stringConstantsOptions.find(constant => constant.value === afasConfiguration.afasNamespaceConstantId),
        personeelsnummerItemId: stringItemsOptions.find(item => item.value === afasConfiguration.personeelsnummerItemId),
        identifierElementConstantId: stringConstantsOptions.find(constant => constant.value === afasConfiguration.identifierElementConstantId),
      });
    }

    setIsLoading(true);

    const promiseStringConstants = getAllConstantsByType(props.clientId, ValueType.String);
    const promiseStringItems = getAllItemsByValueType(props.clientId, ValueType.String);

    Promise.all([promiseStringConstants, promiseStringItems])
      .then(data => {
        const constantsData: Constant[] = data[0] as Constant[];
        const itemsData: Item[] = data[1] as Item[];

        const constantOptions = constantsData.map(constant => ({
          label: constant.code,
          value: constant.id,
        }));
        setStringConstantsOptions(constantOptions);

        const itemsOptions = itemsData.map(item => ({
          label: item.code,
          value: item.id,
        }));
        setStringItemsOptions(itemsOptions);

        if (props.afasImport.afasConfigurationId) {
          getAfasConfiguration(props.afasImport.afasConfigurationId as number)
            .then(data => {
              updateForm(data, constantOptions as FormSelectBoxItem<number>[], itemsOptions as FormSelectBoxItem<number>[]);
              setAfasCId(data.id);
              setIsLoading(false);
            })
            .catch(() => {
              toast.error('An error occured.');
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error('An error occured.');
      });
  }, [props.afasImport, props.clientId, reset]);

  function convertFormToModel(afasForm: AfasConfigurationFormModel): AfasConfiguration {
    const obj: AfasConfiguration = {
      afasImportId: props.afasImport.id as number,
      valuepathConstantId: afasForm.valuepathConstantId!.value,
      afasNamespaceConstantId: afasForm.afasNamespaceConstantId!.value,
      personeelsnummerItemId: afasForm.personeelsnummerItemId!.value,
    };

    if (afasForm.identifierElementConstantId) { obj.identifierElementConstantId = afasForm.identifierElementConstantId!.value; }

    if (afasCId !== undefined) { obj.id = afasCId; }

    return obj;
  }

  const onSubmit: SubmitHandler<AfasConfigurationFormModel> = function submitForm(afasConfigurationFormModel: AfasConfigurationFormModel) {
    const toastId = toast.loading('Saving...');
    const model = convertFormToModel(afasConfigurationFormModel);

    if (!model.id) {
      // Create
      model.afasImportId = props.afasImport.id as number;

      createNewAfasConfiguration(model)
        .then(data => {
          setAfasCId(data.id);

          // Send new id to parent
          const updatedAfasImport = props.afasImport;
          updatedAfasImport.afasConfigurationId = data.id;
          props.updateAfasImport(updatedAfasImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving.', type: 'error', isLoading: false }));
    } else {
      // Update
      updateAfasConfiguration(model)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating.', type: 'error', isLoading: false }));
    }
  };

  function selectRender(name: any, label: string, options: any[]) {
    return (
      <div className="form-group">
        <label>{label}</label>
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={
            ({ field }) => (
              <Select
                {...field}
                options={options}
                placeholder="Choose a constant"
              />
            )
          }
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
        <br />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectRender('valuepathConstantId', 'Value Path', stringConstantsOptions)}
        {selectRender('afasNamespaceConstantId', 'Afas Namespace', stringConstantsOptions)}
        {
          !props.isPrimairImport && props.afasImport.afasImportType === AfasImportType.MedewerkerGegevens
          && selectRender('identifierElementConstantId', 'Identifier Element', stringConstantsOptions)
        }
        {selectRender('personeelsnummerItemId', 'Staff number', stringItemsOptions)}
        <br />
        <button className="btn btn-success">Save</button>
      </form>
    );
}
