import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Loader } from '@tasper/configurator-core';
import { Budget } from '../api/models/Budget';
import { getBudgetsByClientId, deleteBudget } from '../api/BudgetService';
import styles from './index.module.scss';

export default function BudgetOverview() {
  const { clientId = '' } = useParams();
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getBudgetsByClientId(clientId).then(result => {
      setBudgets(result);
      setIsLoading(false);
    });
  }, [clientId]);

  function deleteBudgetEvent(budgetId: number) {
    if (window.confirm('Are you use you want to delete this budget??')) {
      deleteBudget(budgetId).then(() => {
        const newList = budgets.filter(budget => budget.id !== budgetId);
        setBudgets(newList);
      });
    }
  }

  return (
    <div>
      {isLoading
        ? <div><Loader /></div>
        : (
          <div className="row">
            <Link to={`/configuratie/${clientId}/budgets/create`}>Create Budget</Link>
            <div>
              <ol className="tree-structure">
                {
                budgets.map((budget, key) => {
                  return (
                    <li key={key}>
                      <Link to="/">
                        <span className="num bi bi-currency-euro">{budget.code}</span>
                        {
                          budget.budgetTranslations.map((translation, y) => {
                            return (
                              `${translation.text} `
                            );
                          })
                        }
                      </Link>
                      <button type="button" onClick={() => deleteBudgetEvent(budget.id)}>Delete</button>
                      <ol>
                        {
                          budget.childBudgets?.map((child, x) => (
                            <li key={x}>
                              <Link to="/">
                                <span className="num">{child.code}</span>
                                (
                                {child.budgetTranslations.map((translation, z) => {
                                  return (
                                    `${translation.text} `
                                  );
                                })}
                                )
                              </Link>
                              <button type="button" onClick={() => deleteBudgetEvent(child.id)}>Delete</button>
                            </li>
                          ))
                        }
                      </ol>
                      <div className={styles.normal}>
                        <Link to={`/configuratie/${clientId}/budgets/createChild/${budget.id}`}>Create Child Budget</Link>
                      </div>
                    </li>
                  );
                })
              }
              </ol>
            </div>

          </div>
        )}
    </div>
  );
}
