import React, { useEffect, useState } from 'react';
import { getBudgetsByClientId, Budget } from '@tasper/configurator-budgets';
import { FormSelectBoxItem, Loader } from '@tasper/configurator-core';
import { ListTypeInput } from './ListTypeInput';
import { BaseListTypeInputProps } from './BaseListTypeInputProps';

export default function BudgetListTypeInput(props: BaseListTypeInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [budgets, setBudgets] = useState<Budget[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getBudgetsByClientId(props.clientId).then(data => {
      setBudgets(data);
    });

    setIsLoading(false);
  }, [props.clientId]);

  function getData(): FormSelectBoxItem<string>[] {
    return budgets.map(budget => { return { label: budget.code, value: budget.code }; });
  }

  return (
    isLoading
      ? <Loader />
      : <ListTypeInput clientId={props.clientId} addToListItems={props.addToListItems} label="Budgetten toevoegen aan de lijst" selectBoxItems={getData()} buttonText="ander budget toevoegen" />
  );
}
