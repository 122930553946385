import React, { useEffect, useState } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { toastOptions } from '@tasper/configurator-core';
import { Customer } from '../api/models/Customer';
import { createClient, getClient, updateClient } from '../api/CustomerService';
import 'react-toastify/dist/ReactToastify.css';
import style from './index.module.scss';

export default function ClientPage() {
  const { id } = useParams();
  const [client, setClient] = useState({} as Customer);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm<Customer>({});

  useEffect(() => {
    if (id) {
      getClient(id).then(data => {
        setClient(data);
        setValue('klantnaam', data.klantnaam);
        setValue('projectnaam', data.projectnaam);
        setValue('hasBenefitmanager', data.hasBenefitmanager);
        setValue('hasBenefitshop', data.hasBenefitshop);
        setValue('hasCareLeave', data.hasCareLeave);
        setValue('hasCostDeclaration', data.hasCostDeclaration);
        setValue('hasGrossNetSimulation', data.hasGrossNetSimulation);
        setValue('hasIeb', data.hasIeb);
        setValue('hasMobilitySimulation', data.hasMobilitySimulation);
        setValue('hasPbs', data.hasPbs);
        setValue('hasPositiveTime', data.hasPositiveTime);
        setValue('hasTravelAndExpenses', data.hasTravelAndExpenses);
        setValue('hasWhereIwork', data.hasWhereIwork);
      });
    }
  }, [setValue, id]);

  const onSubmit: SubmitHandler<Customer> = function submitForm(clientObject: Customer) {
    const toastId = toast.loading('Saving...');

    if (id) {
      updateClient({
        ...clientObject,
        id: client.id,
        namespace: clientObject.projectnaam,
      })
        .then(() => toast.update(toastId, {
          ...toastOptions, render: 'Client updated', type: toast.TYPE.SUCCESS, isLoading: false,
        }))
        .catch(() => toast.update(toastId, {
          ...toastOptions, render: 'An error occured while saving.', type: toast.TYPE.ERROR, isLoading: false,
        }));
    } else {
      createClient({
        ...clientObject,
        namespace: clientObject.projectnaam,
      })
        .then(() => {
          toast.update(toastId, {
            ...toastOptions, render: 'Client created', type: toast.TYPE.SUCCESS, isLoading: false,
          });
          setShouldRedirect(true); // Redirect to overview
        })
        .catch(() => toast.update(toastId, {
          ...toastOptions, render: 'An error occured while saving.', type: toast.TYPE.ERROR, isLoading: false,
        }));
    }
  };

  // TODO issue #65 clients in een route const zetten

  return (
    shouldRedirect
      ? <Navigate to="/clients" />
      : (
        <div>
          <form className={style.max_width} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label id="klantnaam-label">Customer name</label>
              <input
                aria-labelledby="klantnaam-label"
                className="form-control"
                defaultValue="test"
                {...register('klantnaam', {
                  required: true,
                })}
              />
              {errors.klantnaam && <span>Customer name is required!</span>}
            </div>
            <div className="form-group">
              <label id="klantproject-label">Project name</label>
              <input aria-labelledby="klantproject-label" className="form-control" {...register('projectnaam', { required: true })} />
              {errors.projectnaam && <span>Project name is required!</span>}
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-benefitmanager-label" className="form-check-input" type="checkbox" {...register('hasBenefitmanager')} />
              <label id="has-benefitmanager-label" className="form-check-label">has a Benefit Manager module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-benefit-shop-label" className="form-check-input" type="checkbox" {...register('hasBenefitshop')} />
              <label id="has-benefit-shop-label" className="form-check-label">has a Benefit Shop module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-pbs-label" className="form-check-input" type="checkbox" {...register('hasPbs')} />
              <label id="has-pbs-label" className="form-check-label">has a PBS module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-where-i-work-label" className="form-check-input" type="checkbox" {...register('hasWhereIwork')} />
              <label className="form-check-label" id="has-where-i-work-label">has a Where I Work module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-care-leave-label" className="form-check-input" type="checkbox" {...register('hasCareLeave')} />
              <label className="form-check-label" id="has-care-leave-label">has a Careleave module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-mobility-simulation-label" className="form-check-input" type="checkbox" {...register('hasMobilitySimulation')} />
              <label className="form-check-label" id="has-mobility-simulation-label">has a Mobility Simulation module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-grossnet-simulation-label" className="form-check-input" type="checkbox" {...register('hasGrossNetSimulation')} />
              <label className="form-check-label" id="has-grossnet-simulation-label">has a Gross Net Simulation module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-travel-and-expenses-label" className="form-check-input" type="checkbox" {...register('hasTravelAndExpenses')} />
              <label className="form-check-label" id="has-travel-and-expenses-label">has a Travel Expenses module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-ieb-label" className="form-check-input" type="checkbox" {...register('hasIeb')} />
              <label className="form-check-label" id="has-ieb-label">has a IEB module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-positive-time-label" className="form-check-input" type="checkbox" {...register('hasPositiveTime')} />
              <label className="form-check-label" id="has-positive-time-label">has a Postive Time Module</label>
            </div>

            <div className="form-check form-switch">
              <input aria-labelledby="has-cost-declaration-label" className="form-check-input" type="checkbox" {...register('hasCostDeclaration')} />
              <label className="form-check-label" id="has-cost-declaration-label">has a Cost Declaration module</label>
            </div>
            <div>
              <Link to="/clients">
                <span className="btn btn-primary mb-2 float-end">Go Back</span>
              </Link>
              <input className="btn btn-primary mb-2" type="submit" value="Save" />
            </div>
          </form>

        </div>
      )
  );
}
