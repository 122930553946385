import React, { Fragment } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { FormSelectBoxItem } from '@tasper/configurator-core';
import { ListItem } from '../../api/models/ListItem';

export interface ListTypeInputProps {
  clientId: string;
  addToListItems: (items: ListItem[]) => void;
  selectBoxItems: FormSelectBoxItem<string | undefined>[];
  label: string;
  buttonText: string;
}

type FormData = {
  items: ListItem[];
};

export function ListTypeInput(props: ListTypeInputProps) {
  const { register, getValues, control } = useForm<FormData>({
    defaultValues: {
      items: [{ code: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  function addToListItems() {
    props.addToListItems(getValues().items);
  }

  return (
    <div>
      <div>
        <label id="code-label">{props.label}</label>
        {
          fields.map((field, index) => {
            return (
              <div>
                <div key={field.id} className="row form-group">
                  <div className="col-sm-6">

                    <select aria-labelledby="code-label" className="form-control" {...register(`items.${index}.code`, { required: true })} onChange={() => addToListItems()}>
                      {props.selectBoxItems.map((item, i) => {
                        return (
                          <Fragment key={i + 11}>
                            <option value={item.value}>{item.label}</option>
                          </Fragment>
                        );
                      })}

                    </select>

                  </div>
                  <div className="col-sm-6">
                    <span className="bi bi-trash" onClick={() => remove(index)} />
                  </div>
                </div>
              </div>
            );
          })
        }
        <button
          type="button"
          onClick={() => append({
            code: '',
          })}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
}
