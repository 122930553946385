import React, { useEffect, useState } from 'react';
import { Item } from '@tasper/configurator-customer-data';
import {
  ValueType, EditStatusIcon, ValueTypeInput, EditStatus,
} from '@tasper/configurator-core';

import { toast } from 'react-toastify';
import Select from 'react-select';
import { CsvMapping } from '../types';
import { createNewCsvMapping, deleteCsvMapping, updateCsvMapping } from '../../../api/CsvMappingService';

interface CSVImportRowProps {
  mapping: CsvMapping;
  onSave: () => void;
  clientId: number;
  csvImportId: number;
  itemsOptions: any[];
  items: Item[];
  defaultDateFormat?: string;
  defaultBooleanFormat?: string;
}

export default function CSVImportRow(props: CSVImportRowProps) {
  const [id, setId] = useState(props.mapping.id);
  const [columnNumber, setColumnNumber] = useState(props.mapping.columnNr);
  const [itemId, setItemId] = useState(props.mapping.itemId);
  const [defaultValue, setDefaultValue] = useState(props.mapping.defaultValue);
  const [valueType, setValueType] = useState<ValueType | undefined>(undefined); // Based on selected item, to determine type for format and defaultValue
  const [valueFormat, setValueFormat] = useState(props.mapping.valueFormat);
  const [optional, setOptional] = useState(props.mapping.optional);

  const [editStatus, setEditStatus] = useState(EditStatus.none);
  const [isNew, setIsNew] = useState(props.mapping.isNew);
  const [deleted, setDeleted] = useState(false); // Hide component when mapping deleted

  useEffect(() => {
    if (itemId !== undefined) changeValueType(itemId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChangeColumnNr(e: React.ChangeEvent<HTMLInputElement>) {
    setColumnNumber(parseInt(e.target.value, 10));
  }

  function handleChangeItemId(value: number) {
    setItemId(value);
    changeValueType(value);
  }

  function handleChangeValueFormat(e: React.ChangeEvent<HTMLInputElement>) {
    setValueFormat(e.target.value);
  }

  function handleChangeOptional(e: React.ChangeEvent<HTMLInputElement>) {
    setOptional(e.target.checked);
  }

  function handleChangeDefaultValue(e: React.ChangeEvent<HTMLInputElement>) {
    setDefaultValue(e.target.value);
  }

  function handleChangeDefaultValueCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setDefaultValue(e.target.checked ? 'true' : 'false');
  }

  function changeValueType(itemId: number) {
    const foundItem = props.items.find(item => item.id === itemId);
    setValueType(foundItem?.valueType);
  }

  function handleSave() {
    // Required fields check
    if (columnNumber !== undefined && itemId !== undefined) {
      if (isNew) {
        createNewCsvMapping({
          csvimportid: props.csvImportId,
          csvColumn: columnNumber,
          itemId,
          optional,
          defaultValue,
          valueFormat,
        })
          .then(data => {
            setEditStatus(EditStatus.success);
            setIsNew(false);
            setId(data.id);
            props.onSave();
          })
          .catch(() => setEditStatus(EditStatus.error));
      } else {
        updateCsvMapping({
          id,
          csvimportid: props.csvImportId,
          csvColumn: columnNumber,
          itemId,
          optional,
          defaultValue,
          valueFormat,
        })
          .then(() => {
            setEditStatus(EditStatus.success);
          })
          .catch(() => setEditStatus(EditStatus.error));
      }
    } else {
      setEditStatus(EditStatus.pending);
    }
  }

  function handleDelete() {
    if (id !== undefined) {
      if (window.confirm('Are you sure you want to delete this import row?')) {
        deleteCsvMapping(id)
          .then(() => {
            toast.success('Deleted');
            setDeleted(true);
          })
          .catch(() => {
            toast.error('An error occured while deleting');
          });
      }
    }
  }

  return !deleted
    ? (
      <form>
        <div className="row">
          <div className="col-1">
            <input type="number" step="1" min="0" className="form-control" onChange={handleChangeColumnNr} value={columnNumber} onBlur={handleSave} />
          </div>
          <div className="col-2">
            <Select
              options={props.itemsOptions}
              onChange={(option: any) => handleChangeItemId(option.value)}
              value={props.itemsOptions.find(item => item.value === itemId)}
              defaultValue={props.itemsOptions.find(item => item.value === itemId)}
              onBlur={handleSave}
            />
          </div>
          <div className="col-4">
            <div className="row">
              <div className="col-4">
                {valueType}
              </div>
              <div className="col-8">
                {
                  valueType === ValueType.DateTime
                  && (
                    <div className="row">
                      <div className="col-6">Format:</div>
                      <div className="col-6">
                        <input className="form-control" onChange={handleChangeValueFormat} value={valueFormat} placeholder={props.defaultDateFormat} onBlur={handleSave} />
                      </div>
                    </div>
                  )
                }
                {
                  valueType === ValueType.Boolean
                  && (
                    <div className="row">
                      <div className="col-6">True value:</div>
                      <div className="col-6">
                        <input className="form-control" onChange={handleChangeValueFormat} value={valueFormat} placeholder={props.defaultBooleanFormat} onBlur={handleSave} />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="col-2">
            <ValueTypeInput
              valueType={valueType}
              onBlur={handleSave}
              onChange={handleChangeDefaultValue}
              onChangeCheckbox={handleChangeDefaultValueCheckbox}
              value={defaultValue!}
              className="form-control"
              disabled={!optional}
            />
          </div>
          <div className="col-1">
            <label>
              <input type="checkbox" onChange={handleChangeOptional} checked={optional} onBlur={handleSave} />
              Optional
            </label>
          </div>
          <div className="col-1">
            <div className="row">
              <div className="col-6">
                <EditStatusIcon status={editStatus} />
              </div>
              <div className="col-6">
                {
                  id !== undefined && <i className="nav_icon bi bi-trash" onClick={handleDelete} />
                }
              </div>
            </div>
          </div>
        </div>
      </form>
    )
    : null;
}
