import React from 'react';
import { TabTableWithNewRow } from '@tasper/configurator-core';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from './types';
import ConstantRow from './parts/ConstantRow';
import { getAllConstants } from '../../../api/ConstantService';

interface ConstantTableProps {
  clientId: number;
}

export default function ConstantTable({ clientId }: ConstantTableProps) {
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const renderRow = (rowModel: Constant, addNewRowEvent: () => void) => {
    return <ConstantRow onSave={addNewRowEvent} constant={rowModel} clientId={clientId} />;
  };

  const getData = (): Promise<Constant[]> => {
    return new Promise((resolve, reject) => {
      getAllConstants(clientId)
        .then(data => {
          resolve(
            data.map(element => {
              return {
                id: element.id,
                name: element.code,
                type: element.valueType,
                value: element.value,
              };
            }),
          );
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-4">Constantcode</div>
        <div className="col-3">Type</div>
        <div className="col-4">Default value</div>
      </div>
      <TabTableWithNewRow<Constant>
        emptyRow={{ isNew: true }}
        renderRow={renderRow}
        getData={getData}
      />
    </>
  );
}
