import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Loader } from '@tasper/configurator-core';
import { Product } from '../api/models/Product';
import { ProductConfigurationDefinition } from '../api/models/ProductConfigurationDefinition';
import { getProductById } from '../api/ProductService';
import ProductTab from './parts/ProductTab';
import ProductConfigMethodsForm from './parts/ProductConfigMethodsForm';
import ProductConfigPropertiesForm from './parts/ProductConfigPropertiesForm';
import ProductDefinitionModelForm from './parts/ProductDefinitionModelForm';
import ProductDefinitionModelValueMapping from './parts/ProductDefinitionModelValueMapping';
import ProductMethodValueMapping from './parts/ProductMethodValueMapping';
import ProductPropertiesValueMapping from './parts/ProductPropertiesValueMapping';

enum ProductDefinitionTabs {
  properties = 'properties',
  propertyMapping = 'propertyMapping',
  methods = 'methods',
  methodMapping = 'methodMapping',
  models = 'models',
  modelMapping = 'modelMapping',
}

function tabParentView(currentTab: ProductDefinitionTabs, product: Product, clientId: string) {
  const productWithDefinitionId = (product.productDefinitionId !== null && product.productDefinitionId !== undefined);
  if (productWithDefinitionId) {
    switch (currentTab) {
      case ProductDefinitionTabs.models:
        return <ProductDefinitionModelForm productDefintionId={product.productDefinitionId} productId={product.id} />;

      case ProductDefinitionTabs.modelMapping:
        return <ProductDefinitionModelValueMapping productId={product.id} clientId={clientId} productCode={product.code} />;

      default:
        throw new Error(`Not a valid Tab ${currentTab}`);
    }
  }
  return <div>Geen pagina!</div>;
}

function tabView(
  currentTab: ProductDefinitionTabs,
  product: Product,
  clientId: string,
  productConfigurationDefinition: ProductConfigurationDefinition | undefined,
) {
  const hasProductConfigurationDefinition = productConfigurationDefinition !== undefined && productConfigurationDefinition !== null;
  if (hasProductConfigurationDefinition) {
    if (currentTab === ProductDefinitionTabs.properties) {
      return (
        <ProductConfigPropertiesForm
          productId={product.id}
          productConfigurationId={productConfigurationDefinition.id}
          properties={productConfigurationDefinition.productConfigDefinitionProperties}
        />
      );
    }
    const hasId = productConfigurationDefinition.id !== undefined;
    if (hasId) {
      switch (currentTab) {
        case ProductDefinitionTabs.models:
          return (
            <ProductDefinitionModelForm
              productDefintionId={productConfigurationDefinition.productDefinitionId}
              productId={product.id}
            />
          );

        case ProductDefinitionTabs.modelMapping:
          return (
            <ProductDefinitionModelValueMapping
              productId={product.id}
              clientId={clientId}
              productCode={product.code}
              parentCode={product?.parent?.code}
            />
          );

        case ProductDefinitionTabs.propertyMapping:
          return (
            <ProductPropertiesValueMapping
              productId={product.id}
              clientId={clientId}
              productCode={product.code}
            />
          );

        case ProductDefinitionTabs.methods:
          return (
            <ProductConfigMethodsForm
              productId={product.id}
              productConfigurationId={productConfigurationDefinition.id}
              methods={productConfigurationDefinition.productConfigDefinitionMethods}
            />
          );

        case ProductDefinitionTabs.methodMapping:
          return (<ProductMethodValueMapping productId={product.id} />);

        default:
          return <div>geen optie gevonden</div>;
      }
    }
  }
  return <div>geen optie gevonden</div>;
}

export function ProductConfiguration() {
  const { clientId = '', productId = '' } = useParams();
  const [product, setProduct] = useState<Product>();
  const [productConfigurationDefinition, setProductConfigurationDefinition] = useState<ProductConfigurationDefinition>();
  const [isLoading, setIsLoading] = useState(false);
  const [isParentProduct, SetIsParentProduct] = useState(false);
  const [currentTab, setCurrentTab] = useState(ProductDefinitionTabs.properties);

  useEffect(() => {
    setIsLoading(true);
    getProductById(parseInt(productId, 10)).then(data => {
      if (parseInt(clientId, 10) === data.clientId && data !== undefined) {
        setProduct(data);

        if ((data.parentId === undefined || data.parentId === null)
          && (data.productConfigDefinitionId === undefined || data.productConfigDefinitionId === null)) {
          setCurrentTab(ProductDefinitionTabs.models);
          SetIsParentProduct(true);
        }

        setProductConfigurationDefinition(data.productConfigDefinition);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [productId, clientId]);

  const tabs = [
    { always: false, tab: ProductDefinitionTabs.properties, title: 'Properties toevoegen' },
    { always: false, tab: ProductDefinitionTabs.propertyMapping, title: 'Properties mappen' },
    { always: false, tab: ProductDefinitionTabs.methods, title: 'Methods toevoegen' },
    { always: false, tab: ProductDefinitionTabs.methodMapping, title: 'Methods mappen' },
    { always: true, tab: ProductDefinitionTabs.models, title: 'Eigenschappen toevoegen' },
    { always: true, tab: ProductDefinitionTabs.modelMapping, title: 'Eigenschappen mappen' },
  ];

  return (
    isLoading
      ? <Loader />
      : (
        <>
          <h1>
            Product &nbsp;
            <small className="text-muted">{product?.code}</small>
          </h1>
          {
            !product
              ? <h5 className="text-inform">Product not found.</h5>
              : (
                <>
                  <ul className="nav nav-tabs">
                    {
                      tabs.filter(x => !isParentProduct || x.always)
                        .map((x, index) => <ProductTab key={index} activeTab={currentTab} onSelectTab={setCurrentTab} {...x} />)
                    }
                  </ul>
                  <br />
                  {
                    isParentProduct
                      ? tabParentView(currentTab, product, clientId)
                      : tabView(currentTab, product, clientId, productConfigurationDefinition)

                  }
                </>
              )
          }
        </>
      )
  );
}

export default ProductConfiguration;
