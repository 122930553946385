import { sendRequest } from '@tasper/configurator-core';
import { CsvMapping } from './models/CsvMapping';

const BASE_URL = 'api/CsvMapping';

export const getAllCsvMappingByImportId = (csvImportId: number) : Promise<CsvMapping[]> => sendRequest<CsvMapping[]>({
  method: 'GET',
  url: `${BASE_URL}/GetByCsvImportId/${csvImportId}`,
});

export const createNewCsvMapping = (mapping: CsvMapping) : Promise<CsvMapping> => sendRequest<CsvMapping>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(mapping),
});

export const updateCsvMapping = (mapping: CsvMapping) : Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${mapping.id}`,
  content: JSON.stringify(mapping),
});

export const deleteCsvMapping = (id: number) : Promise<void> => sendRequest<void>({
  method: 'DELETE',
  url: `${BASE_URL}/${id}`,
});
