import { Workflow } from '@tasper/configurator-calculated-values/api/models/Workflow';
import { ValueType } from "@tasper/configurator-core"

export enum ActionType {
    Selected = 'selected',
    SetCode = 'set-code',
    CopyFrom = 'copy-from',
    SetWorkflowScript = 'set-workflow-script',
}
export interface CopyFromAction {
    code: string
    rawWorkflowData: string
}
export interface SelectWorkflow extends Workflow {
    calculatedValueId: number
}

// export interface Action {
//     type: ActionType
//     payload?: string | CopyFromAction | SetCodeAction | (Workflow & { calculatedValueId: number })
// }

export type Action =
    | { type: ActionType.CopyFrom, payload: CopyFromAction }
    | { type: ActionType.SetCode, payload: string }
    | { type: ActionType.SetWorkflowScript, payload: string }
    | { type: ActionType.Selected, payload: SelectWorkflow }
    ;
export interface EditorState {
    calculatedValueId?: number
    model: Workflow
}
export function workflowReducer(state: EditorState, action: Action): EditorState {
    console.log({ action, state });
    switch (action.type) {
        case ActionType.Selected: {
            const calculatedValueId = (action.payload as any).calculatedValueId;
            return Object.assign({}, state,
                ...[
                    action.payload && { model: action.payload as Workflow },
                    calculatedValueId && { calculatedValueId }
                ].filter(Boolean));
        }

        case ActionType.CopyFrom: {
            const act = action.payload as CopyFromAction;
            return Object.assign({}, state, {
                model: {
                    ...state.model,
                    code: state.model?.code || act.code,
                    rawWorkflowData: act.rawWorkflowData
                } as Workflow
            });
        }

        case ActionType.SetCode:
            return Object.assign({}, state, {
                model: {
                    ...state.model,
                    code: action.payload
                } as Workflow
            });

        case ActionType.SetWorkflowScript:
            return Object.assign({}, state, {
                model: {
                    ...state.model,
                    rawWorkflowData: action.payload
                }
            });

        default:
            throw new Error(`unknown action of type [${(action as any).type}]`);
    }
}
export function setWorkflowScript(script: string): Action {
    return {
        type: ActionType.SetWorkflowScript,
        payload: script
    }
}
export function newWorkflow(calculatedValueId: number | undefined, clientId: number, code: string, type: string = ValueType.Decimal): Action {
    return {
        type: ActionType.Selected,
        payload: { calculatedValueId, clientId, code, returnValueType: type } as SelectWorkflow
    }
}
export function setWorkflow(workflow: SelectWorkflow): Action {
    return {
        type: ActionType.Selected,
        payload: workflow || {} as SelectWorkflow
    }
}
export function copyFrom(code: string, workflowScript: string): Action {
    return {
        type: ActionType.CopyFrom,
        payload: {
            code,
            rawWorkflowData: workflowScript,
        }
    };
}
export function setCode(code: string): Action {
    return {
        type: ActionType.SetCode,
        payload: code
    }
}

export default workflowReducer;