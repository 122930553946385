import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { Loader } from '@tasper/configurator-core';
import { AfasLoonComponentMapping } from '../../api/models/AfasLoonComponentMapping';
import { createNewAfasLoonComponentMapping, getAfasLoonComponentMapping, updateAfasLoonComponentMapping } from '../../api/AfasLoonComponentMappingService';
import { IAfasImportPageComponent } from '../types';
import { AfasImport } from '../../api/models/AfasImport';

interface ALCMappingProps extends IAfasImportPageComponent {
  afasImport: AfasImport;
}

export default function AfasLoonComponentMappingForm(props: ALCMappingProps) {
  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm<AfasLoonComponentMapping>();
  const onSubmit: SubmitHandler<AfasLoonComponentMapping> = submitForm;
  const [afasLCMId, setAfasLCMId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.afasImport.afasLoonComponentMappingId) {
      setIsLoading(true);

      getAfasLoonComponentMapping(props.afasImport.afasLoonComponentMappingId as number)
        .then(data => {
          reset(data);
          setAfasLCMId(data.id);
          setIsLoading(false);
        })
        .catch(() => toast.error('An error occured.'));
    }
  }, [props.afasImport, reset]);

  function submitForm(afasLCM: AfasLoonComponentMapping) {
    const toastId = toast.loading('Saving...');

    if (!afasLCMId) {
      // Create
      afasLCM.afasImportId = props.afasImport.id as number;

      createNewAfasLoonComponentMapping(afasLCM)
        .then(data => {
          setAfasLCMId(data.id);

          // Send new id to parent
          const updatedAfasImport = props.afasImport;
          updatedAfasImport.afasLoonComponentMappingId = data.id;
          props.updateAfasImport(updatedAfasImport);

          toast.update(toastId, { render: 'Saved', type: 'success', isLoading: false });
        })
        .catch(() => toast.update(toastId, { render: 'An error occured while saving.', type: 'error', isLoading: false }));
    } else {
      // Update
      updateAfasLoonComponentMapping(afasLCM)
        .then(() => toast.update(toastId, { render: 'Saved changes', type: 'success', isLoading: false }))
        .catch(() => toast.update(toastId, { render: 'An error occured while updating.', type: 'error', isLoading: false }));
    }
  }

  function renderTextInput(name: any, label: string) {
    return (
      <div className="form-group">
        <label>
          {label}
          {' '}
          element
        </label>
        <input
          className="form-control"
          type="text"
          required
          {...register(name)}
        />
        <ErrorMessage
          errors={errors}
          render={({ message }) => <span className="text-danger">{message}</span>}
          message="This field is required."
          name={name}
        />
      </div>
    );
  }

  return isLoading
    ? <Loader />
    : (
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderTextInput('boekJaarElementName', 'Financial year')}
        {renderTextInput('periodeElementName', 'Period')}
        {renderTextInput('personeelsnummerElementName', 'Staff number')}
        {renderTextInput('waardeElementName', 'Value')}
        <button className="btn btn-success">Save</button>
      </form>
    );
}
