import React from 'react'

// const ShowComponent: React.FC<PropsWithChildren<{ when: boolean }>> = ({ when, children }) => {
//   return !undefined === !when ? <>{children}</> : <></>;
// };

function ShowComponent({ when, fallback, children }: {
    when: boolean | undefined | null
    fallback?: JSX.Element
    children: React.ReactNode | (() => JSX.Element | JSX.Element[])
  }) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!when) {
      return (typeof children === "function")
        ? <>{children()}</>
        : <>{children}</>;
    }
    return fallback || null;
  }
  
  const Show = React.memo(ShowComponent, (prev, next) => prev.when === next.when);
  
  export default Show;