import React from 'react';

export enum EditStatus {
    none = 0,
    pending,
    success,
    error,
}

interface EditStatusIconProps {
    status: EditStatus;
}

export default function EditStatusIcon({ status }: EditStatusIconProps) {
    switch (status) {
        case EditStatus.pending:
            return <i className="nav_icon bi bi-pencil text-info" />;
        case EditStatus.success:
            return <i className="nav_icon bi bi-check text-success" />;
        case EditStatus.error:
            return <i className="nav_icon bi bi-x-circle-fill text-danger" />;
        default:
            return <i className="nav_icon bi" style={{ minWidth: '16px' }}>&nbsp;</i>;
    }
}
