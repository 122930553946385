import { FormGroup } from '@tasper/configurator-core';
import React, { useState, useEffect } from 'react';
import { getProductPropertyByProductIdAndProductConfigDefinitionMethodId, createProductMethod, deleteProductMethod } from '../../../api/ProductMethodService';
import { ProductMethod } from '../../../api/models/ProductMethod';

interface RenderCheckboxProps {
  mandatory: boolean;
  entityId: number;
  code: string;
  productId: number;
}

// TODO merge with other RenderCheckBox
export default function RenderCheckBox({
  code, entityId, mandatory, productId,
}: RenderCheckboxProps) {
  const [checked, setChecked] = useState(mandatory);

  const [productMethod, setProductMethod] = useState<ProductMethod | undefined>();

  useEffect(() => {
    if (!mandatory) {
      getProductPropertyByProductIdAndProductConfigDefinitionMethodId(productId, entityId).then(data => {
        if (data) {
          setProductMethod(data);
          setChecked(true);
        }
      });
    }
  }, [entityId, mandatory]);

  function handleChange(value: boolean) {
    if (value && !productMethod) {
      setChecked(value);
      createProductMethod({ productId, ProductConfigDefinitionMethodId: entityId }).then(data => {
        setProductMethod(data);
      });
    }

    if (!value && productMethod?.id) {
      setChecked(value);
      deleteProductMethod(productMethod.id).then(() => {
        setProductMethod(undefined);
      });
    }
  }

  return (
    <FormGroup>
      <label>{code}</label>
      <input type="checkbox" disabled={mandatory} className="form-check-input" checked={checked} onChange={() => handleChange(!checked)} />
    </FormGroup>
  );
}
