import React from 'react';

export default function FormGroup({ children }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div className="form-group form-switch">
      <div className="form-check form-switch">
        {children}
      </div>
      <br />
    </div>
  );
}
