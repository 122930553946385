import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuOverview from './MenuOverview';
import CreateAndEditMenu from './CreateAndEditMenu';
import MenuItemPage from './MenuItemPage';

const MenuRouter = () => {
  return (
    <Routes>
      <Route path="" element={<MenuOverview />} />
      <Route path="create" element={<CreateAndEditMenu />} />
      <Route path="createmenuitem/:categoryId" element={<MenuItemPage />} />
    </Routes>
  );
};

export default MenuRouter;
