import React, { useEffect } from 'react';

interface CheckBoxProps {
  name?: string;
  id?: string;
  label: string;
  value: boolean;
  onChange: (state: boolean) => void;
}

export default function CheckBox({
  label,
  onChange,
  value = false,
  id,
  name,
}: CheckBoxProps) {
  const controlId = id || CheckBox.nextId();
  const controlName = name || controlId;
  const [checked, setChecked] = React.useState(value);

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked]);

  return (
    <div className="form-check form-switch">
      <label className="form-check-label" htmlFor={controlId}>{label}</label>
      <input
        className="form-check-input"
        type="checkbox"
        name={controlName}
        id={controlId}
        checked={checked}
        onChange={() => { setChecked(!checked); }}
      />
    </div>
  );
}

const nextId = Symbol.for('nextId');
CheckBox[nextId] = 0;
CheckBox.nextId = function next() {
  CheckBox[nextId] += 1;
  return `check-ts${CheckBox[nextId]}`;
};
