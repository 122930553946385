import React from 'react';
import { useParams } from 'react-router-dom';
import ConstantTable from './parts/ConstantTable';

export function ConstantsPage() {
  const { clientId = '' } = useParams();

  return (
    <>
      <h1>Constants</h1>
      <ConstantTable clientId={parseInt(clientId)} />
    </>
  );
}

export default ConstantsPage;
