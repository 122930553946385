import React, { useEffect, useState } from 'react';
import { Loader, FormSelectBoxItem } from '@tasper/configurator-core';
import { getProductsByClientId, Product } from '@tasper/configurator-products';
import { ListTypeInput } from './ListTypeInput';
import { BaseListTypeInputProps } from './BaseListTypeInputProps';

export default function ProductListTypeInput({ clientId, addToListItems }: BaseListTypeInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getProductsByClientId(parseInt(clientId, 10)).then(data => {
      setProducts(data);
    });

    setIsLoading(false);
  }, [clientId]);

  function getData(): FormSelectBoxItem<string | undefined>[] {
    return products.map(product => { return { label: product.code, value: product.code }; });
  }

  return (
    isLoading ? <Loader /> : <ListTypeInput clientId={clientId} addToListItems={addToListItems} label="Producten toevoegen aan de lijst" selectBoxItems={getData()} buttonText="ander product toevoegen" />
  );
}
