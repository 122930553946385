import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductOverview from './ProductOverview';
import ProductConfiguration from './ProductConfiguration';
import CreateProduct from './CreateProduct';

const ProductRouter = () => {
  return (
    <Routes>
      <Route path="" element={<ProductOverview />} />
      <Route path=":productId/config" element={<ProductConfiguration />} />
      <Route path="create" element={<CreateProduct />} />
    </Routes>
  );
};

export default ProductRouter;
