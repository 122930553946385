/* eslint-disable no-nested-ternary */
import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router';
import { Product, getProductsByClientId } from '@tasper/configurator-products';
import { Loader } from '@tasper/configurator-core';
import { MenuCategory } from '../api/models/MenuCategory';
import styles from './index.module.scss';
import { createMenu } from '../api/MenuService';

export function MenuPage() {
  const { clientId = '' } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectBack, setRedirectBack] = useState(false);
  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm<MenuCategory>({
    defaultValues: {
      menuItems: [],
    },
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'menuItems',
  });

  const history = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getProductsByClientId(parseInt(clientId, 10)).then(data => {
      setProducts(data);
    });

    setIsLoading(false);
  }, [clientId]);

  const onSubmit: SubmitHandler<MenuCategory> = (menuCategory: MenuCategory) => {
    const menu = menuCategory;
    menu.clientId = Number(clientId);
    createMenu(menuCategory)
      .then(() => {
        toast.success('Saved');
        setRedirectBack(true);
      })
      .catch(() => {
        toast.error('An error occured while saving the menu.');
      });
  };

  return (
    <div>
      {isLoading
        ? <div><Loader /></div>
        : redirectBack
          ? <div><Navigate to={`/configuratie/${clientId}/menu/`} /></div>
          : (
            <div>

              <form className={styles.max_width} onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label id="code-label">Menu code</label>
                  <input
                    aria-labelledby="code-label"
                    className="form-control"
                    {...register('code', {
                      required: true,
                    })}
                  />
                  {errors.code && <span>Please fill in the menu code.</span>}
                </div>

                <div className="form-group">
                  <label id="name-nl-label">Dutch menu text</label>
                  <input
                    aria-labelledby="name-nl-label"
                    className="form-control"
                    {...register('nameNL', {
                      required: true,
                    })}
                  />
                  {errors.nameNL && <span>Please fill in the Dutch menu text.</span>}
                </div>
                <div className="form-group">
                  <label id="name-en-label">English menu text</label>
                  <input
                    aria-labelledby="name-en-label"
                    className="form-control"
                    {...register('nameEN', {
                      required: true,
                    })}
                  />
                  {errors.nameEN && <span>Please fill in the English menu text.</span>}
                </div>

                {
                  fields.map((field, index) => {
                    return (
                      <div>
                        <div key={field.id} className="row form-group">
                          <div className="col-sm-6">
                            <label id="product-code-label">Product</label>
                            <select aria-labelledby="translation-label" className="form-control" defaultValue="test" {...register(`menuItems.${index}.productId`, { required: true })}>
                              {products.map((product, productIdx) => {
                                return (
                                  <Fragment key={productIdx}>
                                    <option value={product.id}>{product.code}</option>
                                  </Fragment>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }

                <button
                  type="button"
                  onClick={() => append({})}
                >
                  Add new menu item
                </button>
                <div>
                  <button type="button" className="btn btn-primary mb-2 float-end" onClick={() => history(-1)}>Back</button>
                  <input className="btn btn-primary mb-2" type="submit" value="Save" />
                </div>
              </form>
            </div>
          )}
    </div>
  );
}

export default MenuPage;
