import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { AfasImportType } from '../EditImport/parts/Afas/api/models/AfasImportType';
import { createImport } from '../api/ImportService';
import { Import } from '../api/models/Import';
import ImportType from '../api/models/ImportType';

export default function CreateImportPage() {
  const [code, setCode] = useState('');
  const [importType, setImportType] = useState<ImportType | undefined>();
  const [afasImportType, setAfasImportType] = useState<AfasImportType | undefined>();
  const [primair, setPrimair] = useState(false);
  const [groeperen, setGroeperen] = useState(false);
  const [dateFormat, setDateFormat] = useState('');
  const [booleanFormat, setBooleanFormat] = useState('');
  const { clientId = '' } = useParams();
  const [createdId, setCreatedId] = useState<number | undefined>(); // When not undefined, redirect to id.

  function handleChangeCode(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value);
  }

  function handleChangeType(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value as keyof typeof ImportType;
    setImportType(ImportType[value]);
  }

  function handleChangeAfasType(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value as keyof typeof AfasImportType;
    setAfasImportType(AfasImportType[value]);
  }

  function handleChangePrimair(event: React.ChangeEvent<HTMLInputElement>) {
    setPrimair(event.target.checked);
  }

  function handleChangeGroeperen(event: React.ChangeEvent<HTMLInputElement>) {
    setGroeperen(event.target.checked);
  }

  function handleChangeDateFormat(event: React.ChangeEvent<HTMLInputElement>) {
    setDateFormat(event.target.value);
  }

  function handleChangeBooleanFormat(event: React.ChangeEvent<HTMLInputElement>) {
    setBooleanFormat(event.target.value);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log('submit handler');
    if (code !== undefined && importType !== undefined) {
      const importModel: Import = {
        code, importType, clientId: parseInt(clientId, 10), primair, groeperen, standardBooleanFormat: booleanFormat, standardDateFormat: dateFormat,
      };

      if (importType === ImportType.AFAS) {
        if (afasImportType !== undefined) {
          importModel.afasImport = {
            afasImportType,
          };
        } else {
          toast.error('Please choose an AFAS import type.');
          return;
        }
      }

      createImport(importModel)
        .then(data => {
          setCreatedId(data.id);
        })
        .catch(error => {
          if (error.response.data.length > 0 && error.response.data[0] === 'Import code already exists for this client.') {
            toast.error('This import code is already used for another import of this customer.');
          } else {
            toast.error('An error occured.');
          }
        });
    } else {
      toast.error('Please fill in the code and type.');
    }
  }
  return createdId !== undefined
    ? <Navigate to={`../${createdId}/config`} />
    : (
      <div className="container">
        <h1>New import</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Code</label>
            <input type="text" className="form-control" onChange={handleChangeCode} required />
          </div>
          <div className="form-group">
            <label>Type</label>
            <select className="form-control" onChange={handleChangeType} required>
              <option selected disabled>Choose a type</option>
              {
                Object.keys(ImportType).map((element, index) => {
                  return <option key={index} value={element}>{element}</option>;
                })
              }
            </select>
          </div>
          {
            importType === ImportType.AFAS
            && (
              <div className="form-group">
                <label>Type AFAS import</label>
                <select className="form-control" onChange={handleChangeAfasType} required>
                  <option selected disabled>Choose a type</option>
                  {
                    Object.keys(AfasImportType).map((element, index) => {
                      return <option key={index} value={element}>{element}</option>;
                    })
                  }
                </select>
              </div>
            )
          }
          <div className="form-check form-switch">
            <label>
              <input type="checkbox" onChange={handleChangePrimair} checked={primair} className="form-check-input" />
              Primairy
            </label>
          </div>
          <div className="form-check form-switch">
            <label>
              <input type="checkbox" onChange={handleChangeGroeperen} checked={groeperen} className="form-check-input" />
              Group
            </label>
          </div>
          <div className="form-group">
            <label>Default date format</label>
            <input type="text" onChange={handleChangeDateFormat} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Default boolean format</label>
            <input type="text" onChange={handleChangeBooleanFormat} className="form-control" required />
          </div>
          <br />
          <div className="form-group">
            <button type="submit" className="btn btn-success">Next step</button>
          </div>
        </form>
      </div>
    );
}
