import React from 'react';

function navLinkStyle(activeTab: boolean): string {
  return `nav-link${activeTab ? ' active' : ''}`;
}

export type ProductTabProps<T> = {
  tab: T;
  title: string;
  activeTab: T;
  onSelectTab: (tab: T) => void;
};

export default function ProductTab<T>({
  tab, activeTab, title, onSelectTab,
}: ProductTabProps<T>) {
  return (
    <li className="nav-item">
      <button type="button" className={navLinkStyle(activeTab === tab)} onClick={() => onSelectTab(tab)}>
        {title}
      </button>
    </li>
  );
}
