import { sendRequest } from '@tasper/configurator-core';
import { AfasLoonComponentMapping } from './models/AfasLoonComponentMapping';

const BASE_URL = 'api/AfasLoonComponentMapping';

// eslint-disable-next-line max-len
export const createNewAfasLoonComponentMapping = (afasLoonComponent: AfasLoonComponentMapping): Promise<AfasLoonComponentMapping> => sendRequest<AfasLoonComponentMapping>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(afasLoonComponent),
});

export const updateAfasLoonComponentMapping = (afasLoonComponent: AfasLoonComponentMapping): Promise<void> => sendRequest<void>({
  method: 'PUT',
  url: `${BASE_URL}/${afasLoonComponent.id}`,
  content: JSON.stringify(afasLoonComponent),
});

export const getAfasLoonComponentMapping = (afasLoonComponentId: number): Promise<AfasLoonComponentMapping> => sendRequest<AfasLoonComponentMapping>({
  method: 'GET',
  url: `${BASE_URL}/${afasLoonComponentId}`,
});
