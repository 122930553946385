import React, {
  PropsWithChildren, useEffect, useState, FC,
} from 'react';
import { useParams } from 'react-router';
import { Link, LinkProps } from 'react-router-dom';
import { Loader } from '@tasper/configurator-core';
import styles from '../../../../src/Tables.module.scss';
import { getProductsByClientId } from '../api/ProductService';
import { Product } from '../api/models/Product';

  type RowProps = PropsWithChildren<unknown>;
const Row: FC<RowProps> = ({ children }) => <div className={`${styles.row} row`}>{children}</div>;

  type ColumnProps = PropsWithChildren<{ columnWidth: number }>;
const HeaderColumn: FC<ColumnProps> = ({ columnWidth = 1, children }) => <div className={`col-${columnWidth} ${styles.table_head_col}`}>{children}</div>;
const Column: FC<ColumnProps> = ({ columnWidth = 1, children }) => <div className={`col-${columnWidth} ${styles.table_col}`}>{children}</div>;

  type ButtonLinkProps = PropsWithChildren<LinkProps>;
const ButtonLink: FC<ButtonLinkProps> = ({ children, ...rest }) => <Link {...rest} className="btn btn-sm btn-primary">{children}</Link>;

export function ProductOverviewPage() {
  const { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
	  if (clientId) {
      setIsLoading(true);
      getProductsByClientId(parseInt(clientId, 10)).then(data => {
		  setProducts(data);
      }).finally(() => {
		  setIsLoading(false);
      });
	  }
  }, [clientId]);

  function isNullOrUndefined(value: unknown) {
	  return value == null || value === undefined;
  }
  return isLoading
	  ? <Loader />
	  : (
  <>
    <Link className="btn btn-success" to={`/configuratie/${clientId}/products/create`}> Nieuw product</Link>
    <Row>
      <HeaderColumn columnWidth={3}>Code</HeaderColumn>
      <HeaderColumn columnWidth={3}>Type</HeaderColumn>
      <HeaderColumn columnWidth={3}>is een gecombineerd product?</HeaderColumn>
      <HeaderColumn columnWidth={1} />
    </Row>
    {
			products.map((product, i) => {
			  return (
  <Row key={i}>
    <Column columnWidth={3}>{product.code}</Column>
    <Column columnWidth={3}>{product.productConfigDefinition?.code}</Column>
    <Column columnWidth={3}>{isNullOrUndefined(product.parentId) && isNullOrUndefined(product.productConfigDefinitionId) ? 'Ja' : 'Nee'}</Column>
    <Column columnWidth={1}>
      <ButtonLink to={`${product.id}/config`}>Configuratie</ButtonLink>
    </Column>
  </Row>
			  );
			})
		  }
  </>
	  );
}

export default ProductOverviewPage;
