import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader, toastOptions } from '@tasper/configurator-core';
import { deleteImport, getAllImports } from '../api/ImportService';
import { Import } from '../api/models/Import';
import styles from '../../../../src/Tables.module.scss';

export default function ImportsPage() {
  const [imports, setImports] = useState<Import[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { clientId = '' } = useParams();

  useEffect(() => {
    setIsLoading(true);

    getAllImports(parseInt(clientId, 10))
      .then(data => {
        setImports(data);
        setIsLoading(false);
      });
  }, [clientId]);

  function handleDeleteImport(importId: number) {
    if (window.confirm('Are you sure you want to delete this import?')) {
      const toastId = toast.loading('Saving');

      deleteImport(importId)
        .then(() => {
          toast.update(toastId, {
            ...toastOptions, render: 'Deleted import successfully', type: toast.TYPE.SUCCESS, isLoading: false,
          });
          // Delete import from overview
          setImports(imports.filter(i => i.id !== importId));
        })
        .catch(() => toast.update(toastId, {
          ...toastOptions, render: 'An error occured while deleting.', type: toast.TYPE.ERROR, isLoading: false,
        }));
    }
  }

  return isLoading
    ? <Loader />
    : (
      <>
        <Link className="btn btn-success" to="create">New Import</Link>
        <div className={`${styles.row} row`}>
          <div className={`col-5 ${styles.table_head_col}`}>Code</div>
          <div className={`col-3 ${styles.table_head_col}`}>Type</div>
          <div className={`col-2 ${styles.table_head_col}`}>Primairy</div>
          <div className={`col-2 ${styles.table_head_col}`} />
        </div>
        {
          imports.map((imp, i) => {
            return (
              <div className={`${styles.row} row`} key={i}>
                <div className={`col-5 ${styles.table_col}`}>{imp.code}</div>
                <div className={`col-3 ${styles.table_col}`}>{imp.importType}</div>
                <div className={`col-2 ${styles.table_col}`}>{imp.primair && 'Yes'}</div>
                <div className={`col-2 ${styles.table_col}`}>
                  <Link to={`${imp.id}/config`}>
                    <button type="button" className="btn btn-sm btn-primary">Configuration</button>
                  </Link>
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDeleteImport(imp.id as number)}>Delete</button>
                </div>
              </div>
            );
          })
        }
      </>
    );
}
