import { sendRequest } from '@tasper/configurator-core';
import { Budget } from './models/Budget';

const BASE_URL = 'api/budget';

export const getBudgetsByClientId = (clientId: string): Promise<Budget[]> => sendRequest<Budget[]>({
  method: 'GET',
  url: `${BASE_URL}/list/${clientId}`,
});

export const deleteBudget = (budgetId: number) => sendRequest({
  method: 'DELETE',
  url: `${BASE_URL}/${budgetId}`,
});

export const createBudget = (budget: Budget): Promise<Budget> => sendRequest<Budget>({
  method: 'POST',
  url: BASE_URL,
  content: JSON.stringify(budget),
});
