import React, { useState } from 'react';
import { Import } from '@tasper/configurator-imports/api/models/Import';
import { Csvimport } from './api/models/Csvimport';
import CsvPrimarySettingForm from './parts/CsvPrimarySettingForm';
import CsvSettingForm from './parts/CsvSettingForm';
import CsvConnectionSettingsForm from './parts/CsvConnectionSettingsForm';
import CsvImportTable from './parts/CsvImportTable';

interface CSVImportProps {
  importModel: Import;
}

enum CSVImportTabs {
  Mapping,
  PrimarySettings,
  ConnectionSettings,
  Settings,
}

export default function CSVImport({ importModel }: CSVImportProps) {
  const [currentTab, setCurrentTab] = useState(CSVImportTabs.Settings);
  const [csvImport, setCsvImport] = useState(importModel.csvimport);

  function handleTabClick(tab: CSVImportTabs) {
    setCurrentTab(tab);
  }

  const childChanged = (model: Csvimport) => {
    setCsvImport(model);
  };

  function renderSubpage() {
    if (csvImport) {
      switch (currentTab) {
        case CSVImportTabs.Settings:
          return <CsvSettingForm csvImport={csvImport} updateCsvImport={childChanged} />;

        case CSVImportTabs.PrimarySettings:
          return <CsvPrimarySettingForm csvImport={csvImport} updateCsvImport={childChanged} />;

        case CSVImportTabs.ConnectionSettings:
          return <CsvConnectionSettingsForm csvImport={csvImport} updateCsvImport={childChanged} clientId={importModel.clientId} />;

        case CSVImportTabs.Mapping:
          return (
            <CsvImportTable
              clientId={importModel.clientId}
              csvImportId={csvImport.id as number}
              defaultBooleanFormat={importModel.standardBooleanFormat}
              defaultDateFormat={importModel.standardBooleanFormat}
            />
          );

        default:
          return null;
      }
    }

    return null;
  }

  function renderNavItem(tab: CSVImportTabs, label: string) {
    return (
      <li className="nav-item">
        <button
          type="button"
          className={`nav-link${currentTab === tab ? ' active' : ''}`}
          onClick={() => handleTabClick(tab)}
        >
          {label}
        </button>
      </li>
    );
  }

  return (
    <>
      <ul className="nav nav-tabs">
        {renderNavItem(CSVImportTabs.Settings, 'Settings')}
        {
          importModel.primair
          && renderNavItem(CSVImportTabs.PrimarySettings, 'Employee Mapping')
        }
        {renderNavItem(CSVImportTabs.ConnectionSettings, 'Connection')}
        {renderNavItem(CSVImportTabs.Mapping, 'Mapping')}
      </ul>
      <br />
      {
        renderSubpage()
      }
    </>
  );
}
